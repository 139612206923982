import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";
import { connect } from 'react-redux';

import FileReaderInput from "react-file-reader-input";
import { ReactReader } from "./modules";
import back_btn from '../../assets/images/back_btn.svg'

import Footer from '../../component/Footer'

import { getAllBooks,getSpecificBooks } from '../../store/actions/bookAction'

import { postBookMark, getAllBookMark, removeBookMark, postRatting } from '../../store/actions/bookMarkAction'


import {
  Container,
  ReaderContainer,
  Bar,
  LogoWrapper,
  Logo,
  GenericButton,
  CloseIcon,
  FontSizeButton,
  ButtonWrapper
} from "./Components";

const storage = global.localStorage || null;

const DEMO_URL =
  "https://mega.nz/folder/JZhS3abC#gXVYldqfSq_SKbAeU_ebFg/file/gZRzGILQ";
const DEMO_NAME = "Alice in wonderland";

class PreviewApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: false,
      location:
        storage && storage.getItem("epub-location")
          ? storage.getItem("epub-location")
          : 2,
      localFile: null,
      localName: null,
      largeText: 1,
      flow: 'paginated',
      highlighted: false,
      book: {},
      bookList: [],
      library: false,
      bookMark: [],
      isSignIn:true,
      type:'',
    };
    this.rendition = null;


  }
  componentWillMount() {
    let search = window.location.search;
        let params = new URLSearchParams(search);
        let book_name = params.get('book');
        let type = params.get('type');
        if (book_name !== '' && type !== '') {
          console.log(book_name)
          this.props.getSpecificBooks(book_name).then((res) => {
              console.log(res.content)
              if (res.status == true) {
                  this.setState({
                      book: res.content,
                      type: type,
                  })
  
              }
              else {
                  alert(res)
              }
          }).catch((err) => {
              console.log(err)
  
          })
      }

  }
  componentDidMount() {
    this.props.getAllBooks().then((res) => {
      console.log(res.content.filter(el => el.Book_ID != this.state.book.Book_ID))
      if (res.status == true) {
        this.setState({
          bookList: res.content.filter(el => el.Book_ID != this.state.book.Book_ID),
        })

      }
      else {
        alert(res)
      }
    }).catch((err) => {
      console.log(err)

    })
    this.props.getAllBookMark(this.state.book.Book_ID).then((res) => {
      if (res.status == true) {
        this.setState({
          bookMark: res.content,
        })

      }
      else {
        alert(res)
      }
    }).catch((err) => {
      console.log(err)

    })
  }

  createRatting = (ratting) => {
    if (!this.props.user?.User_ID){
      this.setState({
        isSignIn: false
      })
    }else{
      this.setState({isSignIn: false})
    this.props.postRatting({
      'Book_ID': this.state.book?.Book_ID,
      'Ratting': ratting,
  })
      .then((res) => {
         console.log(res)
         return
      })
      .catch((err) => {
          console.log(err)

      })
    }
  }
  toggleHighlight = () => {
    console.log(this.state.highlighted)
    this.setState({
      highlighted: !this.state.highlighted
    });
  };
  toggleFullscreen = () => {
    this.setState(
      {
        fullscreen: !this.state.fullscreen
      },
      () => {
        setTimeout(() => {
          const evt = document.createEvent("UIEvents");
          evt.initUIEvent("resize", true, false, global, 0);
        }, 1000);
      }
    );
  };

  onLocationChanged = location => {

    if (this.state.bookMark.includes(location)) {
      console.log("this page is bookmarked")
    }

    if (storage.getItem("epub-location") === location) {
      console.log("here")
    }

    this.setState(
      {
        location
      },
      () => {
        storage && storage.setItem("epub-location", location);
      }
    );
  };

  onToggleFontSize = () => {
    //const nextState = !this.state.largeText;

    this.setState((prevState) => (
      {
        largeText: (prevState.largeText + 1) % 3
      }),
      () => {
        console.log(this.state.largeText)
        this.rendition.themes.fontSize(this.state.largeText === 1 ? "100%" :this.state.largeText === 2 ? "140%" : "180%");
      }
    );
  };

  onToggleLayout = () => {
    //const flow = !this.state.flow;

    this.rendition.themes.register({ "light": { "body": { "color": "purple" } } });

  };


  onHihlight = (cfiRange, contents) => {
    this.rendition.annotations.highlight(cfiRange, {}, (e) => {
      console.log("highlight clicked", e.target);
    });
    contents.window.getSelection().removeAllRanges();

  }
  getRendition = rendition => {
    // Set inital font-size, and add a pointer to rendition for later updates
    const { largeText } = this.state;
    this.rendition = rendition;
    rendition.themes.fontSize(largeText ? "140%" : "100%");
    if (this.state.highlighted) {
      this.rendition.on('selected', this.onHihlight.bind(this));
      this.rendition.on("selected", (cfiRange, contents) => this.onHihlight(cfiRange, contents));

      console.log('Text : ', this.rendition)
      this.rendition.on("selected", function (cfiRange) {

        this.book.getRange(cfiRange).then(function (range) {
          var text;
          if (range) {
            text = range.toString();
            console.log(text)
          }
        })

      });
    }

  };
  onBookMark = ()=>{
  console.log(this.props.user)
  if(this.props.user?.User_ID){
    console.log(this.state.bookMark)
  if(this.state.bookMark.includes(this.state.location)) {
    // Remove 
    console.log("REmove Book Mark " )
    this.setState(prevState => ({
      bookMark: prevState.bookMark.filter(el => el.Location != this.state.location )
  }));
  this.props.removeBookMark({
    'Book_ID': this.state.book?.Book_ID,
    'Location': this.state.location,
    'Status': 'ACTIVE',
})
    .then((res) => {
       console.log(res)
       return
    })
    .catch((err) => {
        console.log(err)

    })
  }else {
    console.log("Add Book Mark " )
    this.state.bookMark.push(this.state.location)
    this.props.postBookMark({
      'Book_ID': this.state.book?.Book_ID,
      'Location': this.state.location,
      'Status': 'ACTIVE',
  })
      .then((res) => {
         console.log(res)
         return
      })
      .catch((err) => {
          console.log(err)

      })
  }
  console.log("OnBookMark Pressed", this.state.location)
}else{
  //this.props.history.push("/login")
}
  }

  updateBook = (book) => {
    this.props.getAllBooks().then((res) => {
      console.log(book)
      if (res.status == true) {
        this.setState({
          bookList: res.content.filter(el => el.Book_ID != book.Book_ID),
          book: book
        }
          // ,() => {
          //   console.log(book)
          //   this.setState({book:book})
          // }
        )

      }
      else {
        alert(res)
      }
    }).catch((err) => {
      console.log(err)

    })
  }
  handleChangeFile = (event, results) => {
    if (results.length > 0) {
      const [e, file] = results[0];
      if (file.type !== "application/epub+zip") {
        return alert("Unsupported type");
      }
      this.setState({
        localFile: e.target.result,
        localName: file.name,
        location: null
      });
    }
  };
  render() {
    const { fullscreen, location, localFile, localName, book, library } = this.state;
    return (
      <>
        <Container>
          <ReaderContainer fullscreen={true}>
            
            {/* <div>
              { this.state.bookMark.map((item,index)=>
              
              <h4 onClick={()=> this.rendition.display(item)}>
              {item } Qasim
              </h4>
              )

              }
            </div> */}
            <ReactReader
            createRatting={this.createRatting}
              onBookMark={ this.onBookMark}
              bookMark={this.state.bookMark}
              url={this.state.type === 'full' ? book.Url : book.Preview}
              title={book.Name}
              location={location}
              locationChanged={this.onLocationChanged}
              getRendition={this.getRendition}
              onToggleFontSize={this.onToggleFontSize}
              onToggleLayout={this.onToggleLayout}
              toggleHighlight={this.toggleHighlight}
              direction={book.Language === 'English' ? "ltr" : "rtl"}

              book={book}
              user={this.props.user}
              history={this.props.history}
              largeText={this.state.largeText}
              bookList={this.state.bookList}
              updateBook={this.updateBook}
            />
            
          </ReaderContainer>
         

        </Container>
      </>
    );
  }
}

const mapStatetoProps = ({ auth }) => ({
  user: auth.user
})
const mapDispatchToProps = ({
  getAllBooks,
  postBookMark,
  getAllBookMark,
  removeBookMark,
  postRatting,
  getSpecificBooks,
})
PreviewApp.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(PreviewApp);