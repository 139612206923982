import axios from 'axios';
import { GET_ERRORS, GET_ALL_BOOKS } from './type';



// export const getFilterCategory = () => dispatch => {
//   return axios
//       .post('/api/publicationcategory')
//       .then((res) => {
//           console.log(res)

//           return Promise.resolve(res.data)
//       }).catch((err) => {
//           console.log(err)
//           return Promise.reject(err)
//       })


// }

export const getFilterCategory = accountData => dispatch => {
  return axios
    .post('api/filter/book', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};

export const getAllCategory = () => dispatch => {
  return axios
    .get('/api/publicationcategory')
    .then((res) => {
      // console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const getAllParentCategory = () => dispatch => {
  return axios
    .get('/api/parentcategory')
    .then((res) => {
      // console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const getAllBooks = () => dispatch => {
  return axios
    .get('/api/book')
    .then((res) => {
      
     
      
      // To store books in state for further usage
      // Every time not usage to hit the API
      dispatch({
        type: GET_ALL_BOOKS,
        payload: res.data.content
      })
      // console.log('BOOKS', res.data.content)
      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
}

export const getAllNewBooks = () => dispatch => {
  return axios
    .get('/api/new/book')
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const getPopularBooks = () => dispatch => {
  return axios
    .get('/api/popular/book')
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const getAllAuthorBooks = (Name) => dispatch => {
  return axios
    .get('/api/author/book?Author_Name=' + Name)
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const filterAllBooks = (id) => dispatch => {
  return axios
    .get('/api/search/book?Category_ID=' + id)
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const searchBook = (Name) => dispatch => {
  return axios
    .get('/api/book/search?Name=' + Name)
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const getSpecificBooks = (Name) => dispatch => {
  return axios
    .get('/api/specific/book?Name=' + Name)
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}

export const getSpecificBookByID = (ID) => dispatch => {

  console.log(ID)
  // var qs = require('qs');
  // var data = {
  //   'Book_ID': 'e1bc0dd2-e424-46eb-9400-dbec5b4b422c'
  // };
  // '/api/sort/book?Name=' + Name + '&Sort=' + Sort
  return axios
    .get('/api/specific/book', {
      Book_ID: ID
    })
    .then((res) => {
      console.log(res)
      return Promise.resolve(res.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}


export const getCountryFromIp = () => dispatch => {
  return axios
    .get('https://extreme-ip-lookup.com/json/?key=9dqyzQ3hzoxJ1L1MICxH')
    .then((res) => {
      // console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}


export const sortAllBooks = (Name, Sort) => dispatch => {
  return axios
    .get('/api/sort/book?Name=' + Name + '&Sort=' + Sort)
    .then((res) => {
      console.log(res)

      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })


}


export const getBookCategory = () => dispatch => {
  return axios
    .get('api/publicationcategory')
    .then((res) => {
      console.log(res)
      return Promise.resolve(res.data)
    }).catch((err) => {
      console.log(err)
      return Promise.reject(err)
    })
}


export const postOrder = accountData => dispatch => {
  return axios
    .post('api/order', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};

export const postRegularSellAccount = accountData => dispatch => {
  return axios
    .post('api/regularSell', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};


export const getEasyPay = accountData => dispatch => {
  return axios
    .post('https://easypay.easypaisa.com.pk/easypay/Index.jsf', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};




