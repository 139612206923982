import React, { Component } from 'react';
import { connect } from 'react-redux';
import banner from '../../assets/images/Artboard.svg'
import Slider2 from '../../assets/images/Slider2.svg'
import Slider3 from '../../assets/images/Slider3.svg'
import Slider4 from '../../assets/images/Fyqjod.jpg'
import authorcard_img from '../../assets/images/authorcard_img.svg'
import bookstore_card from '../../assets/images/bookstore_card.svg'
import romantic_novel from '../../assets/images/romantic-novel.svg'
import feministWoman from '../../assets/images/01-feminism-pakistan-hylton.svg'
import pakistanWoman from '../../assets/images/pakistan_woman_shop_m.svg'
import northernArea from '../../assets/images/northernArea.svg'
import Placeholder from '../../assets/images/Placeholder.svg'
import randomItem from 'random-item';


import star from '../../assets/images/star.svg'
import bookicon from '../../assets/images/undraw_Books_l33t.svg'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
import { addToCart, removeFromCart } from '../../store/actions/cartAction';
import cart from '../../store/reducers/cart';


import { getAllNewBooks, getAllAuthorBooks, getAllBooks,getCountryFromIp, getPopularBooks } from '../../store/actions/bookAction'
import UserHeader from '../../component/UserHeader'
import { sendAmplitudeData } from '../../utils/amplitude';



let audio = new Audio("/")


class Dashboard extends Component {

   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         newBookList: [],
         isPakistan: true,
         popularBookList:[],
         isImageLoaded:false,
         currentCountry:'',


      };
   }

   // onMessage(e){
   //    let URL=getForeeCheckoutURL();
   //    if(e.origin !== URL) return ;
   //    console.log(e)
   // }

   // @HostListener('window:message', ['$event'])


   componentWillMount() {
      this.props.getCountryFromIp().then((res) => {
         console.log(res)
         if (res.country === "Pakistan") {
            console.log(res.country)
            this.setState({
               isPakistan: true,
               currentCountry: res.countryCode ,

            })

         }
         else {
            console.log(res.country)
            this.setState({
               isPakistan: false,
               currentCountry: res.countryCode ,

            })
            //alert(res)
         }
      }).catch((err) => {
         console.log(err)

      })

   }


   componentDidMount() {

      sendAmplitudeData("VisitedHomePage")
      this.props.getAllNewBooks().then((res) => {
         console.log(res.content)
         if (res.status == true) {
            this.setState({
               newBookList: res.content,
            }) 
            sendAmplitudeData("Books_Loaded", {"no_of_books":res.content?.length})
         }
         else {
            sendAmplitudeData("Books_Loaded_Error")
            alert(res)
         }
      }).catch((err) => {
         sendAmplitudeData("Books_Loaded_Error" ,err)
         console.log(err)

      })

      this.props.getAllBooks().then((res) => {
         let popularbooks = []
         console.log(res.content)
         popularbooks = randomItem.multiple(res.content, 4)
         if (res.status == true) {
            console.log('popularbook',popularbooks)

            this.setState({
               
               popularBookList:popularbooks

            })
            

         }
         else {
            alert(res)
         }
      }).catch((err) => {
         console.log(err)

      })

      // this.props.getAllBooks().then((res) => {
      //    console.log(res.content)
      //    if (res.status == true) {
      //       this.setState({
      //          popularBookList: res.content,
      //       })

      //    }
      //    else {
      //       alert(res)
      //    }
      // }).catch((err) => {
      //    console.log(err)

      // })
   }
   onClickMore = (pageTitle) => {
      this.props.history.push('/category', { pageTitle: pageTitle })

   }
   addToCart = (book) => {
      let item = this.props.cart.find(item => {
          return item.Book_ID === book.Book_ID
      })
      if (!item){ 
         sendAmplitudeData("Click_Add_to_Cart_home",{"book_name": book.Name, "book_id" :book.Book_ID })
         this.props.addToCart(book)
      }
      else {
          console.log(" Already ADDed in CART")
      }

  }

   onclickBook = (book) => {
      this.props.history.push('/aboutbook?book=' + book.Name, { book: book })
   }

   onclickAuthor = (book) => {
      this.props.getAllAuthorBooks(book.Author_Name).then((res) => {
         console.log(res.content)
         if (res.status == true) {
            // this.setState({
            //    newBookList: res.content,
            // })
            this.props.history.push('/category', { books: res.content, Author_Name: book.Author_Name })

         }
         else {
            alert(res)
         }
      }).catch((err) => {
         console.log(err)

      })

   }

   checkRestrictedCountry = (item) => {
      var nameArr = []
      if(!item){
          return false
      }
    nameArr = item.split(',')
   //  console.log('nammeArr :',nameArr)
    if(nameArr.includes(this.state.currentCountry) || nameArr.includes(' '+ this.state.currentCountry)){
     // console.log(nameArr)
        return true
    }else{
        return false
    }
  }

   renderBooks = (list) => {
      const n = 4;
      return list.map((item, i) =>
      <>
      {!this.checkRestrictedCountry(item.Restricted_Country) && 

         <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6  mt-3 mb-4 pb-4  text-center" >
            <div class="container">
               <img className="book_img img-w-100 img-h-82 img-br-22" 
               // src={item.Image}
               src={this.state.isImageLoaded?item.Image: Placeholder}
               onLoad={() => this.setState({isImageLoaded:true})}
                onClick={() => this.onclickBook(item)}></img> <br></br>
               {item?.isPurchased === item.Book_ID  ? (
                        <div class="middle">
                            <button className="imghoverbtn">Already Bought</button>
                        </div>
                    ):(
                        <div class="middle">
                        <button className="imghoverbtn" onClick={() => this.addToCart(item)}>Add Book</button>
                    </div>
                    )}
            
            </div>
            <div>
            <div class="tooltipq">

            <p className="poppins-medium cut-text  book-title mt-3" onClick={() => this.onclickBook(item)}>{item.Name} </p><br></br>
            <p class="tooltipqtext">{item.Name} </p>
</div>
         <p className="book-author poppins_light  " onClick={() => this.onclickAuthor(item)}>{"by " + item.Author_Name}</p><br></br>
            <label className="mr-2 ptext poppins_medium">Price :</label>

            <label className="book-price-text poppins_medium" >{this.state.isPakistan ? item.Price == 0 ? "Our Gift" : item.Price + " .00 RS" : item.Price_USD == 0 ? "Our Gift" : item.Price_USD + " .00 USD"}</label><br></br>

            <img src={star}></img>
            <label className="poppins_light ml-2 book_rating">{Math.floor(Math.random() * (5 * 10 - 4 * 10) + 4 * 10) / (1*10)}</label>
         </div>
         </div>
      }
      </>
      )
   }
   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location, user } = this.props
      const { isLoading, isPakistan } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }

      console.log('In a dashboard component')
      return (
         <div class="dashboard-cotainer">

            {/* header */}

            { user.Full_Name && <UserHeader
               history={this.props.history}
            > </UserHeader>}
            {      !user.Full_Name &&
               <Header
                  history={this.props.history}

               ></Header>
            }

            {/* banner */}
            <div className=" ">
               <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  ">
               

                  <Carousel autoPlay infiniteLoop>
                     <div>
                        <img className="img-w-100" src={banner}></img>
                     </div>
                     <div>
                        <img className="img-w-100" src={Slider2}></img>
                     </div>
                     <div>
                        <img className="img-w-100" src={Slider3}></img>
                     </div>
                     <div>
                        <img className="img-w-100" src={Slider4}></img>
                     </div>
                  </Carousel>

               </div>
               <div className="row">
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 pl-5 pr-5 mt-5 ">


                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">

                        <label className="poppins-medium book-heading">New E-Books on Little Book</label>

                        <div class="text-right">
                           <label class="poppins_light viewmore" onClick={() => this.onClickMore('New on Little ')}> View More
</label>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">
                        <div className="row">
                           {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6    text-center">
                              <img className="book_img img-w-100" src={book1}></img> <br></br>
                              <label className="poppins-medium book-title">Sadequain</label><br></br>
                              <label className="book-author poppins_light">by Saiyid Ali Naqvi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6  text-center">
                              <img className="book_img img-w-100" src={book2}></img> <br></br>
                              <label className="poppins-medium book-title">A Guest in the House</label><br></br>
                              <label className="book-author poppins_light">by Maniza Naqvi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6  text-center">
                              <img className="book_img img-w-100" src={book3}></img> <br></br>
                              <label className="poppins-medium book-title">100 Lafzon ki Kahani</label><br></br>
                              <label className="book-author poppins_light">by Mubashir Ali Zaidi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6  text-center">
                              <img className="book_img img-w-100" src={book4}></img> <br></br>
                              <label className="poppins-medium book-title">Muskh-e-Sukhan</label><br></br>
                              <label className="book-author poppins_light">by Saiyid Ali Naqvi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div> */}
                           {this.state.newBookList && this.renderBooks(this.state.newBookList)}

                        </div>

                     </div>

                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4">

                        <label className="poppins-medium book-heading">Popular E-Books</label>

                        <div class="text-right">
                           <label class="poppins_light viewmore" onClick={() => this.onClickMore('Popular ')}> View More
</label>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4">
                        <div className="row">
                           {/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={book5}></img> <br></br>
                              <label className="poppins-medium book-title">Mosam Bhar Jesay Log</label><br></br>
                              <label className="book-author poppins_light">by Dr Aslam Farukhi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={book6}></img> <br></br>
                              <label className="poppins-medium book-title">A Guest in the House</label><br></br>
                              <label className="book-author poppins_light">by Maniza Naqvi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={book3}></img> <br></br>
                              <label className="poppins-medium book-title">100 Lafzon ki Kahani</label><br></br>
                              <label className="book-author poppins_light">by Mubashir Ali Zaidi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={book4}></img> <br></br>
                              <label className="poppins-medium book-title">Muskh-e-Sukhan</label><br></br>
                              <label className="book-author poppins_light">by Saiyid Ali Naqvi</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div> */}
                           {this.state.newBookList && this.renderBooks(this.state.popularBookList)}
                           
                        </div>


                     </div>

                     {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4">

                        <label className="poppins-medium book-heading">Best Seller E-Books</label>

                        <div class="text-right">
                           <label class="poppins_light viewmore" onClick={() => this.onClickMore('Best Seller ')}> View More
</label>
                        </div>
                     </div>
                      */}
                     {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-5 mt-4">
                        <div className="row">
                         
                           {this.state.newBookList && this.renderBooks()}

                        </div>

                     </div> */}

                     {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4">

                        <label className="poppins-medium book-heading">Story Work Collective</label>

                        <div class="text-right">
                           <label class="poppins_light viewmore"> View More
</label>
                        </div>
                     </div>
                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-4 mb-5">
                        <div className="row">
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={feministWoman}></img> <br></br>
                              <label className="poppins-medium book-title">Rising Women</label><br></br>
                              <label className="book-author poppins_light">by Jiya Rajwani</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={pakistanWoman}></img> <br></br>
                              <label className="poppins-medium book-title">Hopes Neve Die</label><br></br>
                              <label className="book-author poppins_light">by Khatoon</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={karachiCity}></img> <br></br>
                              <label className="poppins-medium book-title">Busy City Karachi</label><br></br>
                              <label className="book-author poppins_light">by Jiya Rajwani</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>
                           <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                              <img className="book_img img-w-100" src={northernArea}></img> <br></br>
                              <label className="poppins-medium book-title">Northern Beauty</label><br></br>
                              <label className="book-author poppins_light">by Khatoon</label><br></br>
                              <img src={star}></img>
                              <label className="poppins_light ml-2 book_rating">4.2</label>
                           </div>

                        </div>

                     </div> */}




                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 pl-5 pr-5 mt-5">
                     <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 mt-4 text-center">
                           <div className="blog-card2" >
                              <img className="discount-img " src={bookicon}></img> <br></br>
                              <label className="poppins_semibold discount-img-heading1">Are you a Publisher?</label><br></br>
                              <label className="poppins_regular discount_img_text1 pl-4 pr-4">Little Book Company Increase your sales via our digital book platform.</label><br></br>
                              <a href="https://admin.littlebookcompany.net">

                                 <button className="blog_register_button1 poppins_semibold mt-4">Register Now</button>
                              </a>                           </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 mt-4 text-center">
                           <div className="blog-AuthorCard" >
                              <img className="discount-img " src={authorcard_img}></img> <br></br>
                              <label className="poppins_semibold discount-img-heading1">Are you an Author?</label><br></br>
                              <label className="poppins_regular discount_img_text1 pl-4 pr-4">Little Book Company Publishes your book helps you to connect with readers and earn.</label><br></br>
                              {/* <Link to="https://admin.littlebookcompany.net/login"> */}

                              <a href="https://admin.littlebookcompany.net">


                                 <button className="blog_register_author_button poppins_semibold mt-4">Register Now</button>
                              </a>                           </div>
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 mt-4 text-center">
                           <div className="blog-BookStore" >
                              <img className="discount-img " src={bookstore_card}></img> <br></br>
                              <label className="poppins_semibold discount-img-heading1">Are you a Bookstore?</label><br></br>
                              <label className="poppins_regular discount_img_text1 pl-4 pr-4">Little Book Company Increase your store locations, customers and sales.</label><br></br>
                              <a href="https://admin.littlebookcompany.net">

                                 <button className="blog_register_bookstore_button poppins_semibold mt-4">Register Now</button>
                              </a>
                           </div>
                        </div>

                        <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 mt-4 text-center">
                           <div className="blog-workCollective" >
                              <img className="discount-img " src={romantic_novel}></img> <br></br>
                              <label className="poppins_semibold discount-img-heading1">Stories Work Collective</label><br></br>
                              <label className="poppins_regular discount_img_text1 pl-4 pr-4">Little Book Company helps you to earn from your stories</label><br></br>
                              <a href="https://admin.littlebookcompany.net">

                                 <button className="blog_register_WorkCollective_button poppins_semibold mt-4">Register Now</button>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <Footer></Footer>
         </div>
      )
   }

}
const mapStatetoProps = ({ auth,cart }) => ({
   user: auth.user,
   cart:cart.cart

})
const mapDispatchToProps = ({
   getAllNewBooks,
   getAllAuthorBooks,
   getCountryFromIp,
   addToCart,
   removeFromCart,
   getPopularBooks,
   getAllBooks,

})
Dashboard.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard);

