import React, { Component } from 'react';
import { connect } from 'react-redux';
import hardcopy from '../../assets/images/publisher/hardcopy.svg'
import panel from '../../assets/images/publisher/panel.svg'
import contract from '../../assets/images/publisher/contract.svg'
import payment from '../../assets/images/publisher/payment.svg'
import easypaisa from '../../assets/images/publisher/easypaisa.svg'
import venmo from '../../assets/images/publisher/Venmo.svg'
import paypal from '../../assets/images/publisher/paypal.svg'
import visa from '../../assets/images/publisher/visa.svg'
import banktransfer from '../../assets/images/publisher/banktransfer.svg'
import banner from '../../assets/images/Artboard.svg'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import UserHeader from '../../component/UserHeader'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
class FindAStore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
        };
    }
    componentDidMount() {
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location,user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">

                {/* header */}

                { user.Full_Name && <UserHeader
                history={this.props.history}
                > </UserHeader> }
                 {      !user.Full_Name &&
                     <Header
                     history={this.props.history}
                     
                     ></Header>
                }


                {/* banner */}
                <div className=" ">

                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  ">
                        <img className="img-w-100" src={banner}></img>



                    </div>
                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">

                                    <p className="poppins_semibold aboutus_tag">Dear Story Book Store,</p>

                                    <p className="poppins_semibold  aboutus_heading">Welcome to The Little Book Company!</p>
                                    <p className="poppins_light aboutus_tag">This platform increases your sales by providing you the opportunity to reach customers world wide and earn.  </p>

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">
                                <a href="https://admin.littlebookcompany.net">

                                  <button className="PublisherRegisterBtn">Register Now</button>
                                  </a>
                                </div>
                            </div>
                       
                        </div>


                    </div>

                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6 pl-5 pr-5 mt-5 text-center">

                                <img class="pubicon" src={hardcopy}></img>
                                <p className="poppins_medium pubhead ">Bookshops can market
                                their products on our
 platform</p>

                                <p className="poppins_regular pubtext ">Booksellers are able to sell their books online on our platform and receive direct online payments. Booksellers on our platform have access to their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.  Payments are triggered on the basis of volume of sales and/or monthly.
 </p>

                            </div>

                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={contract}></img>
                                <p className="poppins_medium pubhead ">Agreement Policies</p>

                                <p className="poppins_regular pubtext ">Each Bookshop will sign an online agreement with The Little Book Company (here) before their books are marketed on this platform. The agreement enables the bookshop to monitor their sales. </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={payment}></img>
                                <p className="poppins_medium pubhead ">Payment Transfers: 
</p>

                                <p className="poppins_regular pubtext ">will be made after the customer has received the book from the bookshop. Book delivery is the responsibility of each bookshop.
  </p>

                            </div>

                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={panel}></img>
                                <p className="poppins_medium pubhead ">Dedicated Individual Bookshop’s
 Portal for Monitoring of 
Sales and Payments</p>

                                <p className="poppins_regular pubtext ">Each Bookshop will be able to monitor their book sales and payment transfers to them. Each Bookshop will have their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings. </p>

                            </div>
                           
                        </div>


                    </div>


                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">


                                    <p className="poppins_semibold  aboutus_heading">Payment Modes Available!</p>
                                    {/* <p className="poppins_light aboutus_tag">We provides vast verity of payment method use for internationally world wide for our customers.</p> */}


                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="mlmr150">
                        <div className="row">
                            <div class="imgwth">
                                <img src={easypaisa}></img>
                            </div>
                            {/* <div class="imgwth">
                                <img src={venmo}></img>
                            </div>
                            <div class="imgwth">
                                <img src={paypal}></img>
                            </div> */}
                            <div class="imgwth">
                                <img src={visa}></img>
                            </div>
                            <div class="imgwth">
                                <img src={banktransfer}></img>
                            </div>

                        </div>


                    </div>

                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
})
FindAStore.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(FindAStore);

