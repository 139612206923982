import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/images/Logo-01.svg'
import increases from '../../assets/images/publisher/increase.svg'
import ebook from '../../assets/images/publisher/ebook.svg'
import hardcopy from '../../assets/images/publisher/hardcopy.svg'
import salestrack from '../../assets/images/publisher/salestrack.svg'
import panel from '../../assets/images/publisher/panel.svg'
import contract from '../../assets/images/publisher/contract.svg'
import payment from '../../assets/images/publisher/payment.svg'
import customerreview from '../../assets/images/publisher/customerreview.svg'
import Slider3 from '../../assets/images/Slider4.svg'
import easypaisa from '../../assets/images/publisher/easypaisa.svg'
import venmo from '../../assets/images/publisher/Venmo.svg'
import paypal from '../../assets/images/publisher/paypal.svg'
import visa from '../../assets/images/publisher/visa.svg'
import banktransfer from '../../assets/images/publisher/banktransfer.svg'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import UserHeader from '../../component/UserHeader'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
class Publisher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
        };
    }
    componentDidMount() {
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }


                {/* banner */}
                <div className="  ">

                    <div className='col-md-12 pl-5 pr-5'>
                        <div className='row'>
                            <div className=" col-md-8  col-12 pl-5 pr-5 ">


                                <img className="img-w-100 slideImg" src={Slider3}></img>


                            </div>
                            <div className=" col-md-4  col-12 pl-5 pr-5  ">
                                <div className='authorCard storycardd'>
                                    <p className='poppins_light text1'>Are you a </p>
                                    <p className='poppins_semibold text2'>Story Writer?</p>
                                    <p className='poppins_extralight text3'>Little Book Company ebook platform increases your sales worldwide.</p>
                                    <a href="https://admin.littlebookcompany.net/register">

                                        <button className='btn'>Register as a Story Writer</button>
                                    </a>

                                    <p className='poppins_medium text3'>Already have an Account?
                                        <a href="https://admin.littlebookcompany.net">
                                            <label className='LoginLabel'> Login!</label>
                                        </a>
                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  ">


                    </div>
                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">

                                    <p className="poppins_semibold aboutus_tag">Dear Story Writers,</p>

                                    <p className="poppins_semibold  aboutus_heading">Welcome to The Little Book Company!</p>
                                    <p className="poppins_light aboutus_tag">We believe in self help and collectives.</p>

                                </div>
                            </div>

                        </div>


                    </div>




                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-12 pl-5 pr-5 mt-5 text-center">
                                <img class="pubicon" src={payment}></img>

                                <p className="poppins_medium pubhead ">Cash Transfers for Stories</p>

                                <p className="poppins_regular pubtext ">Earn money from your story. We will make cash transfers to anyone in need of earning income and livelihood support who is able to submit a story they have written or recorded on audio or video and can sell it on this platform. We will edit, market and post their stories at The Little Book Company website.  </p>

                            </div>

                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-12  mt-5 pl-5 pr-5 text-center">
                                <img class="pubicon" src={increases}></img>

                                <p className="poppins_medium pubhead ">Earn with your Story</p>

                                <p className="poppins_regular pubtext ">We will set a minimal price for our readers to purchase the story to read. Once the total earnings from the story reach Rs.1000 we will make an e-paisa transfer to the storyteller. Each storyteller will be able to monitor the sales and payment transfers to them as a result of their story </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-12  mt-5 pl-5 pr-5 text-center">
                                <img class="pubicon" src={contract}></img>

                                <p className="poppins_medium pubhead ">Agreement Policies
                                </p>

                                <p className="poppins_regular pubtext ">The author signs an online agreement with The Little Book Company (here) before books are published by us on this platform. The agreement enables the author to receive payments for their book sales through payment transfers from the Little Book Company to the Publisher.
                                </p>

                            </div>


                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-12  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={panel}></img>
                                <p className="poppins_medium pubhead ">Dedicated Individual Storytellers
                                    Portal for Monitoring of Sales and Payments</p>

                                <p className="poppins_regular pubtext ">Authors on our platform have access to their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.     </p>

                            </div>

                        </div>


                    </div>


                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">


                                    <p className="poppins_semibold  aboutus_heading">Payment Modes Available!</p>
                                    {/* <p className="poppins_light aboutus_tag">We provides vast verity of payment method use for internationally world wide for our customers.</p> */}


                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="mlmr150">
                        <div className="row">
                            <div class="imgwth">
                                <img src={easypaisa}></img>
                            </div>
                            {/* <div class="imgwth">
                                <img src={venmo}></img>
                            </div>
                            <div class="imgwth">
                                <img src={paypal}></img>
                            </div> */}
                            <div class="imgwth">
                                <img src={visa}></img>
                            </div>
                            <div class="imgwth">
                                <img src={banktransfer}></img>
                            </div>

                        </div>


                    </div>

                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
})
Publisher.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Publisher);

