import axios from 'axios';
import { GET_ERRORS } from './type';


export const getFilterCategory = accountData => dispatch => {
    return axios
      .post('api/library/filter/book', accountData)
      .then(res => {
        return Promise.resolve(res.data)
      })
      .catch(err => {
  
        if (err.response.data != null && err.response.data.validation) {
          console.log(err.response.data);
          err = err.response.data
        } else {
          err = { "msg": "Something went wrong" }
        }
        dispatch({
          type: GET_ERRORS,
          payload: err
        })
        return Promise.reject(err)
      });
  };

export const getAllBooks = () => dispatch => {
    return axios
        .get('/api/library/book')
        .then((res) => {
            console.log(res)

            return Promise.resolve(res.data)
        }).catch((err) => {
            console.log(err)
            return Promise.reject(err)
        })


}

export const filterAllBooks = (id) => dispatch => {
    return axios
        .get('/api/library/search?Category_ID='+id)
        .then((res) => {
            console.log(res)
  
            return Promise.resolve(res.data)
        }).catch((err) => {
            console.log(err)
            return Promise.reject(err)
        })
  
  
  }

  export const getAllAuthorBooks = (Name) => dispatch => {
    return axios
        .get('/api/library/author?Author_Name='+Name)
        .then((res) => {
            console.log(res)
  
            return Promise.resolve(res.data)
        }).catch((err) => {
            console.log(err)
            return Promise.reject(err)
        })
  
  
  }

  export const sortAllBooks = (Name,Sort) => dispatch => {
    return axios
        .get('/api/library/sort?Name='+Name+'&Sort='+Sort)
        .then((res) => {
            console.log(res)
  
            return Promise.resolve(res.data)
        }).catch((err) => {
            console.log(err)
            return Promise.reject(err)
        })
  
  
  }