import React, { Component } from 'react';
import { connect } from 'react-redux';
import Book from '../../assets/images/masomebahar.svg'
import star from '../../assets/images/star.svg'
import Header from '../../component/Header'
import { Link, withRouter } from 'react-router-dom';
import UserHeader from '../../component/UserHeader'

import Footer from '../../component/Footer'


class AboutBookUrdu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,

        };
    }
    componentDidMount() {
    }
    onClickPreview = () => {



        this.props.history.push("/preview_urdu")

    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location,user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">

                {/* header */}
                { user.Full_Name && <UserHeader
                history={this.props.history}
                > </UserHeader> }
                 {      !user.Full_Name &&
                     <Header
                     history={this.props.history}
                     
                     ></Header>
                }

                {/* about book */}
                <div className="  ">
                    <div className="mlmr25">

                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12  ">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                    <label className="upnav">
                                        The Little Book Company <i class="fa fa-angle-right ml-3 mr-3" aria-hidden="true"></i>
                                        Categories <i class="fa fa-angle-right ml-3 mr-3" aria-hidden="true"></i>
                                        Book<i class="fa fa-angle-right ml-3 mr-3" aria-hidden="true"></i>
                                        Novel <i class="fa fa-angle-right ml-3 mr-3" aria-hidden="true"></i>
                                        Mosam Bhar Jesay Log <i class="fa fa-angle-right ml-3 mr-3" aria-hidden="true"></i>
                                        Product ID 0123456123</label>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-5 ">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-5 col-5  ">
                                            <img className="book_imgg" src={Book}></img>
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-6 col-sm-7 col-7  ">
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                                <p className="poppins_regular aboutbook_title mt-3">Mosam Bhar Jesay Log</p><br></br>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                                <p className="poppins_light aboutbook_author">by Dr Aslam Farukhi</p><br></br>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                                <img src={star}></img> <label className="poppins_light aboutbook_rating">4.2</label>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  aboutbook_price_margin ">
                                                <p className="poppins_light aboutbook_price_text">Price: <del className="aboutbook_price_delte">200 PKR</del></p>

                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
                                                <p className="poppins_medium aboutbook_price_actual">150 PKR</p>
                                            </div>
                                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                                <button className="poppins_semibold aboutbook_preview" onClick={() => this.onClickPreview()}>Preview Book</button>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12  mt-5 ">

                                <div className="mlmr25">

                                    <div className="aboutbook_addcart">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 ">
                                            <div className="row ">
                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2  ">
                                                </div>
                                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10  ">
                                                    <p className="aboutbook_addcard_title poppins_semibold">Book:</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                            <div className="row ">
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3  ">
                                                </div>
                                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9  ">
                                                    <p className="aboutbook_addcard_titlee poppins_regular">Mosam Bhar Jesay Log</p>
                                                    <p className="aboutbook_addcard_authorname poppins_regular">by Dr Aslam Farukhi</p>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 ">
                                            <div className="row ">
                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2  ">
                                                </div>
                                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10  ">
                                                    <p className="aboutbook_addcard_title poppins_semibold">ISBN NUMBER:</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                            <div className="row ">
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3  ">
                                                </div>
                                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9  ">
                                                    <p className="aboutbook_addcard_titlee poppins_regular">91815151319613113131</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-3 ">
                                            <div className="row ">
                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2  ">
                                                </div>
                                                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10  ">
                                                    <p className="aboutbook_addcard_title poppins_semibold">Total Price</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                            <div className="row ">
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3  ">
                                                </div>
                                                <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9  ">
                                                    <p className="aboutbook_addcard_titlee poppins_regular">Rs. 150 PKR/-</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">
                                            <Link to="/checkout">

                                                <button className="aboutbook_addcard_btn poppins_semibold col-xl-12">Add to Cart</button>
                                            </Link>
                                        </div>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="mlmr25">
                        <div className="mt-5 mb-5">
                            <nav class="nav">
                                <div class="topnav  topnav-w50">

                                    <a class="nav-link  poppins_light about_book_tabs active w_aboutbook" href="#">About Book</a>
                                    <a class="nav-link poppins_light about_book_tabs w_aboutbook" href="#">Author Details</a>
                                    <a class="nav-link poppins_light about_book_tabs w_aboutbook" href="#">Terms & Conditions</a>
                                    <a class="nav-link poppins_light about_book_tabs w_aboutbook" href="#">Book QR Share</a>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-12   mt-5 ">
                            <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12  ">
                                    <div className="row">
                                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7  ">
                                            <p className="poppins_light about_book_text">
                                            Detail about Mosam Bhar Jesay Log
                                              
                                            </p>
                                        </div>
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5  ">
                                             </div>
                                    </div>

                                </div>

                                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12  mt-3 ">
                                    <div className="row">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5  ">
                                        
                                        </div>
                                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7  ">
                                          
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                  
                    </div>


                </div>



                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
})
AboutBookUrdu.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(AboutBookUrdu);

