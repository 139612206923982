import React, { Component } from 'react';
import { connect } from 'react-redux';

import cross from '../assets/images/x.svg'
import logo from '../assets/images/Logo-01.svg'
import youtube from '../assets/images/footer/youtube.png'
import facebook from '../assets/images/footer/fb.png'
import linkedin from '../assets/images/socialmedia/linkedin2.svg'
import instagram from '../assets/images/footer/insta.png'
import twitter from '../assets/images/socialmedia/twitter2.svg'
import Logo from '../assets/images/footer/logo.png'
import Check from '../assets/images/footer/check.png'


import { sendContactMail, subscribeEmail } from '../store/actions/authAction'
import { Link, withRouter } from 'react-router-dom';
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";




class Dashboard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         isLoadingMail: false,
         Name: "",
         Subject: "",
         Message: "",
         isContactUs: false,
         Email: "",
         isSubscribe: false,
         isAlreadySubscribe: false,
         isEmaiSend: false,


      };
   }
   componentDidMount() {
   }
   onChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
   }
   onSendMail = (e) => {
      this.setState({ isLoadingMail: true })
      e.preventDefault()
      this.props.sendContactMail({
         "Name": this.state.Name,
         "Subject": this.state.Subject,
         "Message": this.state.Message,
      }).then((res) => {
         this.setState({ isLoadingMail: false, verifiactionSend: true, isEmaiSend: true, isContactUs: true, Name: "", Subject: "", Message: "" })
         console.log(res)
         if (res.status) {
            // this.props.history.push('/category')
            return
         } else {
            return
         }
      }).catch((err) => {
         this.setState({ isLoadingMail: false })
      })
   }

   onSubscribe = (e) => {
      this.setState({ isLoading: true })
      e.preventDefault()
      this.props.subscribeEmail({
         "Email": this.state.Email,
      }).then((res) => {
         this.setState({ isLoading: false, isSubscribe: true, isAlreadySubscribe: false, })
         console.log(res)
         if (res.status) {
            // this.props.history.push('/category')
            return
         } else {
            return
         }
      }).catch((err) => {
         this.setState({ isLoading: false, isAlreadySubscribe: true, isSubscribe: false })
      })
   }

   render() {
      // const { t, i18n } = this.props
      const { t, i18n } = this.props
      const { isLoading, isContactUs, isSubscribe, isAlreadySubscribe ,isLoadingMail} = this.state;
      // if (isLoading) {
      //    return (
      //       <div className="loader-large"></div>
      //    )
      // }
      return (

         <div className="footer">
            <div className="col-xl-12   pt-5 pb-5">
               <div className="text-center">

                  {isLoading && <Loader
                     type="TailSpin"
                     color="#C86762"
                     height={100}
                     width={100}
                  />}
               </div>
               <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-8 horizontal_center  text-center">
                        <Link to="/">

                           <img className="img-w-100" src={Logo}></img>
                        </Link>
                     </div>
                     <div className="col-xl-9 col-lg-9 col-md-11 col-sm-12 col-12  mt-5">
                        <input className="col-xl-12 footer-input poppins_light" placeholder="subscribe for email" name="Email" onChange={this.onChange}></input>
                        <button className="poppins_bold footer_subscribe_btn" onClick={this.onSubscribe}>SUBSCRIBE</button>
                        {isSubscribe && <p className="">You have Succsessfully Subscribe !</p>}

                        {isAlreadySubscribe && <p className="">You Already Subscribe !</p>}


                     </div>
                     <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 mt-5">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="row">
                                 <div className="col-xl-2  col-lg-2 col-md-2 col-sm-2 col-2 "></div>
                                 <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12  ">

                                    <p className="poppins_bold footer-item-heading">Information</p>
                                    <Link to="/support">

                                       <p className="poppins_light footer-item-heading footer-item">Ebook</p>
                                    </Link>
                                    <Link to="/support">

                                       <p className="poppins_light footer-item-heading footer-item">Author and Publisher Contract</p>
                                    </Link>
                                    <Link to="/support">

                                       <p className="poppins_light footer-item-heading footer-item">FAQs</p>
                                    </Link>
                                    <p className="poppins_light footer-item-heading footer-item">Download iOS App</p>
                                    <p className="poppins_light footer-item-heading footer-item">Download Android App</p>



                                 </div>
                              </div>
                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
                              <div className="row">
                                 <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 "></div>
                                 <div className="col-xl-10  col-lg-10 col-md-10 col-sm-10 col-10 ">
                                    <p className="poppins_bold footer-item-heading ">Company</p>
                                    <Link to="/termsandconditons">

                                       <p className="poppins_light footer-item-heading footer-item">Terms of Service</p>
                                    </Link>
                                    <Link to="/privacypolicy">

                                       <p className="poppins_light footer-item-heading footer-item">Privacy Policy</p>
                                    </Link>
                                    <Link to="/support">

                                       <p className="poppins_light footer-item-heading footer-item">Support</p>
                                    </Link>
                                 </div>
                              </div>
                           </div>

                        </div>
                        
                     </div>




                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="poppins_bold footer_cotactus_text">CONTACT US</p>
                        <p className="poppins_extralight footer-item-heading mb-0 ">For the customer support and feedback</p>
                        <p className="poppins_extralight  footer-item-heading">contact us via contact form 24/7.</p>


                     </div>

                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                        <input className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 footer_contact_input poppins_light" placeholder="Email" name="Name" onChange={this.onChange} value = {this.state.Name}></input>
                        <input className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5 footer_contact_input poppins_light" placeholder="Subject" name="Subject" onChange={this.onChange} value = {this.state.Subject}></input>
                        <textarea className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5 footer_msg_input poppins_light" placeholder="Message" type="text" cols="40" rows="5" name="Message" onChange={this.onChange} value = {this.state.Message}></textarea>
                     </div>
                     {/* {isContactUs && <p className="footer_Contact_response">We have recieved your response, contact you about your response soon !</p>} */}
                     <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 ">
                        <div className="row">
                           <div className="col-9 vertical_center">
                           {isContactUs &&
                              <div className="row ">
                                 <div className="col-1">
                                 <img className="mr-4" src={Check}/>

                                 </div>
                                 <div className="col-md-9">
                                 <label className="footer_Contact_response ml-0">Congratulations we have received your 
complaint, Our support team will contact
you within 24 hour. </label>
                                    </div>
                         
                              </div>
                          }

                           </div>
                           <div className="col-md-3 text-right">
                       
                           <button className="poppins_semibold footer_send_btn" onClick={this.onSendMail}>
                           {isLoadingMail && <i class="fa fa-refresh fa-spin"></i> }
                              {isLoadingMail?'':'Send'}</button>

                           </div>
                        </div>

                     </div>
                  </div>
                  
                  <div className="col-md-4 text-center">
                           <div className=" mt-3 pt-3">

                              <a href="https://www.youtube.com/channel/UCdmCPoAQjUX6G1QFhs9ZzNQ">
                                 <img className="pl-3 pr-3 socialmediaicon" src={youtube}></img>
                              </a>
                              <a href="https://www.instagram.com/littlebookcompanypakistan/">

                                 <img className="pl-3 pr-3 socialmediaicon" src={instagram}></img>
                              </a>

                              <a href="https://web.facebook.com/LittlebookcompanyPakistan/">

                                 <img className="pl-3 pr-3 socialmediaicon" src={facebook}></img>
                              </a>
                              <br></br>
                           </div>
                        </div>

               </div>




            </div>
            <div className="footer_bottom">

               <p className="m-0 poppins_light">
                  2020 The Little Book Company  All rights reserved.                  </p>

            </div>
         </div>

      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({
   sendContactMail,
   subscribeEmail
})
Dashboard.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard);

