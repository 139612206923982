import React, { Component } from 'react';
import { connect } from 'react-redux';
import bookstore_card from '../../assets/images/bookstore_card.svg'
import bookicon from '../../assets/images/undraw_Books_l33t.svg'
import Header from '../../component/Header'
import authorcard_img from '../../assets/images/authorcard_img.svg'
import romantic_novel from '../../assets/images/romantic-novel.svg'
import { loginUser, sendOtp } from '../../store/actions/authAction'
import Noty from 'noty';
import { ClipLoader } from 'react-spinners'
import UserHeader from '../../component/UserHeader'

import { Link, withRouter } from 'react-router-dom';
import Footer from '../../component/Footer'

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},

            serverError: {},
            isLoading: false,
            email: "",
            password: "",
        };
    }
    componentDidMount() {
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    onSendCode = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true })
        this.props.sendOtp({
            // "To_Number": this.state.phone_num,
            "user_email": this.state.email,

        }).then((res) => {
            this.setState({ isLoading: false, verifiactionSend: true, isEmaiSend: true })
            // console.log(res)
            if (res.status) {
                this.props.history.push('/Changepassword', { email: this.state.email })
                return
            } else {
                return
            }
        }).catch((err) => {
            this.setState({ isLoading: false })
        })
    }

    renderServerError() {
        if (this.state.serverError != null && this.state.serverError.length > 0) {
            return (

                <div className=" servererror1 form-group alert alert-danger" role="alert" >
                    <strong className="pr-2 ohsnaptext">Oh snap!  {"  "}</strong>
                    <label className="servererrorText">
                        {this.state.serverError[0].msg}
                    </label>
                </div>
            )
        }
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n } = this.props
        const { isLoading } = this.state;
        const { errors } = this.state

        return (
            <div class="dashboard-cotainer">
                {/* header */}
                <Header
                    history={this.props.history}
                ></Header>
                {/* about book */}
                <div className=" login-container  mt-5">

                    <div className="row">

                        <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12 col-12   mb-5">
                            <div className="logincard ">

                                <p className="poppins_bold sign_heading primary-color mb-0">Forget Password</p>
                                <p className="poppins_extra_light sign_text primary-color mb-0">If you forget your password you can reset it here </p>
                                <div className="sing-horizontal-row col-xl-8"></div>
                                {this.renderServerError()}



                                <p className="  sign-form-text poppins_regular mt-3" >Email</p>
                                <input className="sign-input col-xl-12" name="email" onChange={this.onChange}></input>
                                {errors.email && <div className="invaliderror1">{errors.email}</div>}



                                <div className="text-center mt-4 ">
                                    {
                                        isLoading && <button
                                            className='sign_verification_btn poppins_bold  col-xl-4 mt-4'
                                        >
                                            <ClipLoader color='white' />
                                        </button>
                                    }
                                    {
                                        !isLoading && <button
                                            className="sign_verification_btn poppins_bold  col-xl-4 mt-4"
                                            onClick={this.onSendCode}
                                            disabled={this.state.email == "" ? true : false}>Send</button>

                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12   mb-5">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  mb-5 text-center">
                                    <div className="register-card1 publshercard" >
                                        <img className="sign-discount-img mt-4" src={bookicon}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Are you a Publisher?</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                                        <a href="https://admin.littlebookcompany.net" target='_blank'>
                                            <button className="sign_register_button poppins_semibold publshercardbtn mt-3">Register Now</button>
                                        </a>
                                    </div>
                                    <div className="register-card1 bookstorecard" >
                                        <img className="sign-discount-img mt-4" src={bookstore_card}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Are you a Bookstore?</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your store location, customer and sales</label><br></br>
                                        <a href="https://admin.littlebookcompany.net" target='_blank'>
                                            <button className="sign_register_button poppins_semibold bookstorecardbtn mt-3">Register Now</button>
                                        </a>
                                    </div>

                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  mb-5 text-center">
                                    <div className="register-card1 authorcardd" >
                                        <img className="sign-discount-img mt-4" src={authorcard_img}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Are you an Author?</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                                        <a href="https://admin.littlebookcompany.net" target='_blank'>
                                            <button className="sign_register_button poppins_semibold authorcarddbtn mt-3">Register Now</button>
                                        </a>
                                    </div>
                                    <div className="register-card1 storycardd" >
                                        <img className="sign-discount-img mt-4" src={romantic_novel}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Story Work Collective</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your store location, customer and sales</label><br></br>
                                        <a href="https://admin.littlebookcompany.net" target='_blank'>
                                            <button className="sign_register_button poppins_semibold storycarddbtn mt-3">Register Now</button>
                                        </a>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    loginUser,
    sendOtp,
})
ForgetPassword.propTypes = {

};
export default connect(mapStatetoProps, mapDispatchToProps)(ForgetPassword);

