import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import dropdown_icon from '../../assets/images/dropdown_icon.svg'
import star from '../../assets/images/star.svg'
import liblogoo from '../../assets/images/liblogoo.png'

import NoFile from '../../assets/images/NoFile.png'

import Header from '../../component/Header'
import UserHeader from '../../component/UserHeader'
import UHeader from '../../component/UHeader';
import Loader from "react-loader-spinner";


import Footer from '../../component/Footer'
import { getAllBooks, filterAllBooks, getAllAuthorBooks, sortAllBooks, getCountryFromIp, getAllCategory, getAllParentCategory, getFilterCategory } from '../../store/actions/bookAction'

import { Link, withRouter } from 'react-router-dom';
import { addToCart, removeFromCart } from '../../store/actions/cartAction';
import cart from '../../store/reducers/cart';

import { sendAmplitudeData } from '../../utils/amplitude';
import Placeholder from '../../assets/images/Placeholder.svg'
import banner from '../../assets/images/Artboard.svg'
import Slider2 from '../../assets/images/Slider2.svg'
import Slider3 from '../../assets/images/Slider3.svg'
import Slider4 from '../../assets/images/Fyqjod.jpg'
import metaImage from '../../assets/images/barrier.png'
import Bought from '../../assets/images/Modal/Bought.svg'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader



import GoCheckOutTop from '../../utils/GoCheckOutTop';
import axios from 'axios';
import getExchangeRate from '../../utils/getExchangeRate';

import HeadTags from '../../component/HeadTags';

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            bookList: [],
            sortByAuthorName: false,
            pageTitle: "",
            isPakistan: true,
            categoryList: [],
            parentCategoryList: [],
            selectedCheckboxes: [],
            filterList: [],
            checked: true,
            allCategory: false,
            isImageLoaded: false,
            currentCountry: '',
            showLogin: false,
            data: []
        };
    }

    componentWillMount() {

        // this.setState({cartCounting : this.props.cartCount})

        // console.log(this.state.cartCounting);
        // console.log('Category Component Mounted');

        this.props.getCountryFromIp().then((res) => {
            if (res.country === "Pakistan") {
                this.setState({
                    isPakistan: true,
                    currentCountry: res.countryCode,
                })
            }
            else {
                this.setState({
                    isPakistan: false,
                    currentCountry: res.countryCode,
                })
            }
        }).catch((err) => {
            console.log(err)

        })

        this.setState({ isLoading: true })
        if (this.props.books.books.length > 0) {
            this.setState({
                bookList: this.props.books.books,
                isLoading: false
            })
        } else {

            this.props.getAllBooks().then((res) => {

                if (res.status == true) {
                    this.setState({
                        bookList: res.content,
                        isLoading: false
                    })

                }
                else {
                    alert(res)
                }
            }).catch((err) => {
                console.log(err)

            })
        }
    }


    _handleKeyDownFilter = (e) => {
        console.log("here", [e.target.value])
        if (e.target.value == 2) {
            this.onPressSortByName('Name', 'ASC')
        } else if (e.target.value == 3) {
            this.onPressSortByName('Name', 'DESC')
        } else if (e.target.value == 0) {
            this.props.getAllBooks().then((res) => {
                // console.log(res.content)
                if (res.status == true) {
                    this.setState({
                        bookList: res.content,
                        isLoading: false

                    })

                }
                else {
                    alert(res)
                }
            }).catch((err) => {
                console.log(err)

            })
        }
    }


    addToCart = (book) => {
        let item = this.props.cart.find(item => {
            return item.Book_ID === book.Book_ID
        })
        if (!item)
            this.props.addToCart(book)
        else {
            console.log(" Already ADDed in CART")
        }

    }

    componentDidMount() {
        sendAmplitudeData("VisitedExploreBookPage")
        this.props.getAllCategory().then((res) => {
            // console.log(res.content)
            if (res.status == true) {
                this.setState({
                    categoryList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getAllParentCategory().then((res) => {
            // console.log(res.content)
            if (res.status == true) {
                this.setState({
                    parentCategoryList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })


        console.log('STATE BOOKS', this.props.books.books.length)
    }

    onPressSortByName = (colName, sort) => {

        this.props.sortAllBooks(colName, sort).then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                    sortByAuthorName: !this.state.sortByAuthorName
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    onclickBook = (book) => {
        let replaced = book.Name.replace(/[ &\/\\#,+()$~%.'":*?<>{}’]/g, '');
        replaced = replaced.replace(/\s/g, ''); // Removes all whitespace characters
        console.log(replaced)
        this.props.history.push(`/${replaced}`, { book: book })
        // this.props.history.push('/aboutbook?book=' + book.Name, { book: book })
    }

    onClickLibrary = () => {
        // if(this.props.user === {}){
        console.log(this.props.user)
        this.props.history.push("/library",)
        // }else{
        //     console.log(this.props.user)
        //     this.props.history.push("/login")
        // }
    }

    onClickFilter = (id) => {
        this.props.filterAllBooks(id).then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }

    filterByAuthor = (Name) => {
        this.props.getAllAuthorBooks(Name).then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                    pageTitle: Name
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }
    onClickAllCategory = () => {
        // if (!this.state.allCategory) {
        //     this.setState({
        //         selectedCheckboxes: [],
        //         filterList: [],
        //         checked: false,
        //         allCategory: !this.state.allCategory
        //     })
        // } else {
        //     this.setState({
        //         checked: true,
        //         allCategory: !this.state.allCategory
        //     })
        // }
        this.props.getAllBooks().then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                    selectedCheckboxes: [],
                    filterList: [],
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }




    onChangeCheckbox = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes
        const filterList = this.state.filterList
        console.log(this.state.bookList)
        const exists = this.state.bookList.some(v => (v.Category_ID === id));
        console.log(this.state.bookList.some(v => (v.Category_ID === id)))
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);

            filterList.splice(findIdx, 1);

        } else {
            selectedCheckboxes.push(id);
            if (exists) {
                console.log("inexist")
                filterList.push(this.state.bookList.filter(v => (v.Category_ID.includes(id)))[this.state.filterList?.length]);
            }
        }


        this.setState({
            selectedCheckboxes: selectedCheckboxes,
            filterList: filterList
        }, () => {
            console.log(this.state.selectedCheckboxes)
            this.props.getFilterCategory({
                list: this.state.selectedCheckboxes
            }).then((response) => {
                console.log(response)
                if (response.status) {
                    this.setState({
                        bookList: response.content
                    })
                    return
                }
                else {
                    return
                }
            }).catch((err) => {
                console.log(err)

            })
            //}

        });
    };

    renderCategories = (List) => {

        return List.map((item, i) =>
            <div className=" col-md-12 ">
                <div className="signin_container mt-2">
                    <div class="form-group mt-2 mb-2">
                        <input className="mt_4px" type="checkbox" id={item.Category_Name} onChange={() => this.onChangeCheckbox(item.Category_ID)} />
                        <label className="nav_category_checkbox_label poppins_medium mt_4px" for={item.Category_Name}>{item.Category_Name}</label>

                    </div>
                </div>


            </div>
        )
    }

    checkRestrictedCountry = (item) => {
        var nameArr = []
        if (!item) {
            return false
        }
        nameArr = item.split(',')
        if (nameArr.includes(this.state.currentCountry) || nameArr.includes(' ' + this.state.currentCountry)) {
            return true
        } else {
            return false
        }
    }

    alreadyBought = () => {
        Swal.fire({
            customClass: 'sweet-alert',
            title: `<h1>Already Bought</h1>`,
            html: `<p>You have already bought this ebook go back to library and start reading</p>`,
            // html : true,
            iconHtml: `<img src=${Bought} width='70px' />`,
            // position: 'top-end',
            showCloseButton: true,
            confirmButtonText: "Start Reading",
            buttonsStyling: false,
            customClass: {
                container: 'container-class',
                htmlContainer: 'my-htmlText',
                html: 'my-htmlText',
                popup: 'popup-class',
                icon: 'no-border',
                title: 'my-title',
                confirmButton: 'my-confirm-button-class',
                closeButton: 'your-close-button-class',
            }
        })
            .then(() => {
                this.props.history.push('/library')
            })
    }


    showLoginModal = () => {
        this.setState({
            showLogin: true,
        })
    }

    loginFirst = () => {
        let { user } = this.props;
        if (user.Full_Name) {
            this.props.history.push('/library')
        } else {
            Swal.fire({
                customClass: 'sweet-alert',
                title: `<h1>Login First</h1>`,
                html: `<p>In order to redeem this book, first register/login</p>`,
                // html : true,
                iconHtml: `<img src=${Bought} width='70px' />`,
                // position: 'top-end',
                showCloseButton: true,
                confirmButtonText: "Register/Login",
                buttonsStyling: false,
                customClass: {
                    container: 'container-class',
                    htmlContainer: 'my-htmlText',
                    html: 'my-htmlText',
                    popup: 'popup-class',
                    icon: 'no-border',
                    title: 'my-title',
                    confirmButton: 'my-confirm-button-class',
                    closeButton: 'your-close-button-class',
                }
            })
                .then(() => {
                    this.showLoginModal()
                })
        }
    }

    renderBooks = () => {

        if (!this.state.isLoading && this.state.bookList?.length < 1) {

            return <div className="nofile">
                <div className="text-center">
                    <img src={NoFile} />
                    <p className="poppins_bold SorryTextM mb-0">NO E-Book Found</p>
                    <p className="poppins_bold SorryTextS mb-0 " >Tweak some filters and check other categories to find more E-books</p>
                </div>

            </div>
        }
        return this.state.bookList.map((item, i) =>
            <>
                {!this.checkRestrictedCountry(item.Restricted_Country) &&
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  pt-3 pb-5  text-center" >
                        <div className='bookCard'>
                            <img className="book_img img-w-100  img-br-22"
                                src={this.state.isImageLoaded ? item.Image : Placeholder}
                                onLoad={() => this.setState({ isImageLoaded: true })}
                                onClick={() => this.onclickBook(item)}></img> <br></br>
                            <br></br>
                            <div class="tooltipq">
                                <p className="poppins-medium book-title cut-text mb-0" onClick={() => this.onclickBook(item)}>{item.Name}</p>
                                <br />
                                <p class="tooltipqtext">{item.Name}</p>
                            </div>
                            <p className="book-author  cut-text poppins_light" onClick={() => this.filterByAuthor(item.Author_Name)}>{"by " + item.Author_Name}</p>
                            <label className="mr-2 book-price-text poppins_bold">Price :</label>
                            <label className="book-price-text poppins_bold" onClick={() => this.filterByAuthor(item.Author_Name)}>{this.state.isPakistan ? item.Price == 0 ? "Our Gift" : item.Price + " .00 RS" : item.Price_USD == 0 ? "Our Gift" : item.Price_USD + " .00 USD"}</label><br></br>
                            <p>{this.state.isPakistan ? 'Pakistan' : 'US'}</p>
                            <img src={star}></img>
                            <label className="poppins_light ml-2 book_rating">{Math.floor(Math.random() * (5 * 10 - 4 * 10) + 4 * 10) / (1 * 10)}</label><br></br>
                            {

                                !this.props.user.Full_Name ? (
                                    <button className="AddBookBtn" onClick={() => this.addToCart(item)}>Add to Cart</button>
                                ) : (
                                    item?.isPurchased === item.Book_ID ? (
                                        <button className="AlreadyBoughtBtn" onClick={() => this.alreadyBought()}>Already Bought</button>
                                    )
                                        :
                                        item.Price == 0 && item.Price_USD == 0 ? (
                                            <button className="AlreadyBoughtBtn" onClick={() => this.loginFirst()}>Redeem Now</button>
                                        )
                                            : <button className="AddBookBtn" onClick={() => this.addToCart(item)}>Add to Cart</button>
                                )

                            }
                        </div>
                    </div>
                }
            </>
        )

    }

    onSearch = (value) => {
        console.log(value)
        this.setState({
            bookList: value.books,
            pageTitle: value.SearchBy,
        })
    }
    render() {
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        
        return (
            <div class="dashboard-cotainer">
                {user.Full_Name && <UserHeader
                    history={this.props.history}
                    onSearch={this.onSearch}
                > </UserHeader>}

                {!user.Full_Name &&
                    <Header
                        history={this.props.history}
                        onSearch={this.onSearch}
                    ></Header>
                }

                <GoCheckOutTop
                    count={this.props.cartCount}
                />

                {/* banner */}
                <div className="  " ref={this.state.scrollRef}>
                    <HeadTags
                        title='The Little Book Company'
                        description='Platform for authors, readers and writers'
                        imageURL='https://www.linkpicture.com/q/Artboard-1300.png'
                        imageAlt='Little book image'
                    />
                    <div className='col-md-12 pl-5 pr-5'>
                        <div className='row'>
                            <div className=" col-lg-8  col-12 pl-5 pr-5 ">


                                <Carousel autoPlay infiniteLoop>
                                    <div>
                                        <img className="img-w-100 slideImg" src={banner}></img>
                                    </div>
                                    <div>
                                        <img className="img-w-100 slideImg" src={Slider2}></img>
                                    </div>
                                    <div>
                                        <img className="img-w-100 slideImg" src={Slider3}></img>
                                    </div>
                                    <div>
                                        <img className="img-w-100 slideImg" src={Slider4}></img>
                                    </div>
                                </Carousel>

                            </div>
                            <div className=" col-lg-4  col-12 pl-5 pr-5  ">
                                <div className='authorCard'>

                                    <p className='poppins_light text1'>Are you an </p>
                                    <p className='poppins_semibold text2'>Author or Publisher?</p>
                                    <p className='poppins_extralight text3'>Little Book Company ebook platform increases your sales worldwide.</p>
                                    <a href="https://admin.littlebookcompany.net/register">

                                        <button className='btn'>Register as a Seller</button>
                                    </a>
                                    <p className='poppins_medium text3'>Already have an Account?
                                        <a href="https://admin.littlebookcompany.net">
                                            <label className='LoginLabel'> Login!</label>
                                        </a>
                                    </p>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div className="mlmr25">

                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 pb-5 ">

                                <div class="New-nav-category" >
                                    <div className='col-md-12'>
                                        {/* <p className='SerachFilterHeading poppins_medium'>Search Filter</p>
                                        <p className='SerachFilterText poppins_light'>Search by your preference</p> */}

                                    </div>
                                    <div className="col-md-12">
                                        <p className="New-nav-category_Heading">Categories</p>
                                    </div>

                                    <div className=" col-md-12 ">
                                        <div className="signin_container mt-4">
                                            <div class="form-group mt-2 mb-2" onClick={() => this.onClickAllCategory()}>
                                                <input className="mt_4px" type="checkbox" id="Categories" />
                                                <label className="nav_category_checkbox_label poppins_medium mt_4px" for="Categories">All Categories</label>

                                            </div>
                                        </div>


                                    </div>
                                    {this.state.parentCategoryList && this.renderCategories(this.state.parentCategoryList)}



                                    <div className="col-md-12 mt-4">
                                        <p className="New-nav-category_Heading">Type</p>
                                    </div>

                                    {this.state.categoryList && this.renderCategories(this.state.categoryList)}

                                    <div className="col-md-12 mt-4">
                                        <p className="New-nav-category_Heading">Sort By</p>
                                    </div>

                                    <div className=" col-md-12 ">
                                        <div className="signin_container mt-4">
                                            <div class="form-group mt-2 mb-2">
                                                <input className="mt_4px" type="checkbox" id="Author" />
                                                <label className="nav_category_checkbox_label poppins_medium mt_4px" for="Author">Author</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" col-md-12 ">
                                        <div className="signin_container mt-2">
                                            <div class="form-group mt-2 mb-2">
                                                <input className="mt_4px" type="checkbox" id="Publisher" />
                                                <label className="nav_category_checkbox_label poppins_medium mt_4px" for="Publisher">Publisher</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12  pb-5  ">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">
                                    {this.state.bookList.length >= 1 ? (
                                        <div className="row">
                                            {this.renderBooks()}
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <Loader
                                                type="TailSpin"
                                                color="#BB423B"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div >
        )
    }

}
const mapStatetoProps = ({ auth, cart, books }) => ({
    user: auth.user,
    cart: cart.cart,
    cartCount: cart.cart.length,
    books: books
})
const mapDispatchToProps = ({
    getAllBooks,
    filterAllBooks,
    sortAllBooks,
    getAllAuthorBooks,
    getCountryFromIp,
    addToCart,
    removeFromCart,
    getAllCategory,
    getAllParentCategory,
    getFilterCategory,
})
Category.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Category);

