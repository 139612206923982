import React, { Component } from 'react';
import { connect } from 'react-redux';

// import book5 from '../..assets/images/books/book5.svg'
import dropdown_icon from '../../assets/images/dropdown_icon.svg'
import star from '../../assets/images/star.svg'
import liblogoo from '../../assets/images/liblogoo.png'

import NoFile from '../../assets/images/NoFile.png'

import Header from '../../component/Header'
import UserHeader from '../../component/UserHeader'
import Loader from "react-loader-spinner";

import Footer from '../../component/Footer'
import { getAllBooks, filterAllBooks, getAllAuthorBooks, sortAllBooks, getCountryFromIp, getAllCategory, getAllParentCategory, getFilterCategory } from '../../store/actions/bookAction'

import { Link, withRouter } from 'react-router-dom';
import { addToCart, removeFromCart } from '../../store/actions/cartAction';
import cart from '../../store/reducers/cart';
import { sendAmplitudeData } from '../../utils/amplitude';
import Placeholder from '../../assets/images/Placeholder.svg'

class OurPublication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            bookList: [],
            sortByAuthorName: false,
            pageTitle: "",
            isPakistan: true,
            categoryList: [],
            parentCategoryList: [],
            selectedCheckboxes: [],
            filterList: [],
            checked: true,
            allCategory: false,
            isImageLoaded: false,
            currentCountry:'',


        };
    }

    componentWillMount() {
        this.props.getCountryFromIp().then((res) => {
            console.log(res)
            if (res.country === "Pakistan") {
                //console.log(res.country)
                this.setState({
                    isPakistan: true,
                    currentCountry: res.countryCode ,
                })

            }
            else {
                //console.log(res.country)
                this.setState({
                    isPakistan: false,
                    currentCountry: res.countryCode ,
                })
                //alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
        if (this.props != null && this.props.location.state != null && this.props.location.state.books) {
            this.setState({ isLoading: true })
            if (this.props != null && this.props.location.state != null && this.props.location.state.Author_Name) {
                console.log(this.props.location.state.Author_Name)
                this.setState({
                    bookList: this.props.location.state.books,
                    pageTitle: this.props.location.state.Author_Name,
                    isLoading: false,

                })
            }

            if (this.props != null && this.props.location.state != null && this.props.location.state.SearchBy) {
                console.log(this.props.location.state.SearchBy)
                this.setState({
                    bookList: this.props.location.state.books,
                    pageTitle: this.props.location.state.SearchBy,
                    isLoading: false,

                })
            }




        } else {
            if (this.props != null && this.props.location.state != null && this.props.location.state.pageTitle) {
                console.log(this.props.location.state.pageTitle)
                this.setState({ isLoading: true })
                this.props.getAllBooks().then((res) => {
                    console.log(res.content)
                    if (res.status == true) {
                        this.setState({
                            bookList: res.content,
                            pageTitle: this.props.location.state.pageTitle,
                            isLoading: false

                        })

                    }
                    else {
                        alert(res)
                    }
                }).catch((err) => {
                    console.log(err)

                })
            } else {
                this.setState({ isLoading: true })
                this.props.getAllBooks().then((res) => {
                    console.log(res.content)
                   
                    if (res.status == true) {
                        this.setState({
                            bookList: res.content,
                            isLoading: false
                        })

                    }
                    else {
                        alert(res)
                    }
                }).catch((err) => {
                    console.log(err)

                })
            }
        }

    }

    _handleKeyDownFilter = (e) => {
        console.log("here", [e.target.value])
        if (e.target.value == 2) {
            this.onPressSortByName('Name', 'ASC')
        } else if (e.target.value == 3) {
            this.onPressSortByName('Name', 'DESC')
        } else if (e.target.value == 0) {
            this.props.getAllBooks().then((res) => {
                console.log(res.content)
                if (res.status == true) {
                    this.setState({
                        bookList: res.content,
                        isLoading: false

                    })

                }
                else {
                    alert(res)
                }
            }).catch((err) => {
                console.log(err)

            })
        }
    }

    addToCart = (book) => {
        let item = this.props.cart.find(item => {
            return item.Book_ID === book.Book_ID
        })
        if (!item)
            this.props.addToCart(book)
        else {
            console.log(" Already ADDed in CART")
        }

    }

    componentDidMount() {
        sendAmplitudeData("VisitedExploreBookPage")

        console.log(this.props.user)
        // var req = new XMLHttpRequest();
        // req.open("GET", document.location, false);
        // req.send(null);
        // var headers = req.getAllResponseHeaders().toLowerCase();
        // headers = headers.split(/\n|\r|\r\n/g).reduce(function (a, b) {
        //     if (b.length) {
        //         var [key, value] = b.split(": ");
        //         a[key] = value;
        //     }
        //     return a;
        // }, {});
        // console.log("Header")
        // console.log(headers);
        // this.setState({
        //     headers
        // });
        this.props.getAllCategory().then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    categoryList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getAllParentCategory().then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    parentCategoryList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })



    }

    // onPressSort = (colName) => {
    //     var Sort = 'ASC'
    //     this.props.sortAllBooks(colName, Sort).then((res) => {
    //         console.log(res.content)
    //         if (res.status == true) {
    //             this.setState({
    //                 bookList: res.content,
    //             })

    //         }
    //         else {
    //             alert(res)
    //         }
    //     }).catch((err) => {
    //         console.log(err)

    //     })

    // }

    onPressSortByName = (colName, sort) => {

        this.props.sortAllBooks(colName, sort).then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                    sortByAuthorName: !this.state.sortByAuthorName
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    onclickBook = (book) => {
        // if 


        this.props.history.push('/aboutbook?book=' + book.Name, { book: book })
    }

    onClickLibrary = () => {
        // if(this.props.user === {}){
        console.log(this.props.user)
        this.props.history.push("/library",)
        // }else{
        //     console.log(this.props.user)
        //     this.props.history.push("/login")
        // }
    }

    onClickFilter = (id) => {
        this.props.filterAllBooks(id).then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }

    filterByAuthor = (Name) => {
        this.props.getAllAuthorBooks(Name).then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                    pageTitle: Name
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }
    onClickAllCategory = () => {
        // if (!this.state.allCategory) {
        //     this.setState({
        //         selectedCheckboxes: [],
        //         filterList: [],
        //         checked: false,
        //         allCategory: !this.state.allCategory
        //     })
        // } else {
        //     this.setState({
        //         checked: true,
        //         allCategory: !this.state.allCategory
        //     })
        // }
        this.props.getAllBooks().then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content,
                    selectedCheckboxes: [],
                    filterList: [],
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }




    onChangeCheckbox = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes
        const filterList = this.state.filterList
        console.log(this.state.bookList)
        const exists = this.state.bookList.some(v => (v.Category_ID === id));
        console.log(this.state.bookList.some(v => (v.Category_ID === id)))
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);

            filterList.splice(findIdx, 1);

        } else {
            selectedCheckboxes.push(id);
            if (exists) {
                console.log("inexist")
                filterList.push(this.state.bookList.filter(v => (v.Category_ID.includes(id)))[this.state.filterList?.length]);
            }
        }


        this.setState({
            selectedCheckboxes: selectedCheckboxes,
            filterList: filterList
        }, () => {
            console.log(this.state.selectedCheckboxes)
            //    if(  this.state.selectedCheckboxes.length < 1)
            //    {
            //         // call all book list method here
            //         this.props.getAllBooks().then((res) => {
            //             if (res.status == true) {
            //                 this.setState({
            //                     bookList: res.content,
            //                     selectedCheckboxes: [],
            //                     filterList: [],
            //                 })

            //             }
            //             else {
            //                 alert(res)
            //             }
            //         }).catch((err) => {
            //             console.log(err)

            //         })
            //    }else { 
            this.props.getFilterCategory({
                list: this.state.selectedCheckboxes
            }).then((response) => {
                console.log(response)
                if (response.status) {
                    this.setState({
                        bookList: response.content
                    })
                    return
                }
                else {
                    return
                }
            }).catch((err) => {
                console.log(err)

            })
            //}

        });
    };

    renderCategories = (List) => {

        return List.map((item, i) =>
            <div className=" col-md-12 ">
                <div className="signin_container mt-2">
                    <div class="form-group mt-2 mb-2">
                        <input className="mt_4px" type="checkbox" id={item.Category_Name} onChange={() => this.onChangeCheckbox(item.Category_ID)} />
                        <label className="nav_category_checkbox_label poppins_medium mt_4px" for={item.Category_Name}>{item.Category_Name}</label>

                    </div>
                </div>


            </div>
        )
    }

    checkRestrictedCountry = (item) => {
        var nameArr = []
        if(!item){
            return false
        }
      nameArr = item.split(',')
      console.log('nammeArr :',nameArr)
      if(nameArr.includes(this.state.currentCountry) || nameArr.includes(' '+ this.state.currentCountry)){
        console.log(nameArr)
          return true
      }else{
          return false
      }
    }

    renderBooks = () => {
       
        if (!this.state.isLoading && this.state.bookList?.length < 1) {

            return <div className="nofile">
                <div className="text-center">
                    <img src={NoFile} />
                    <p className="poppins_bold SorryTextM mb-0">NO E-Book Found</p>
                    <p className="poppins_bold SorryTextS mb-0 " >Tweak some filters and check other categories to find more E-books</p>
                </div>

            </div>
        }
        return this.state.bookList.map((item, i) =>
        <>
        
            {!this.checkRestrictedCountry(item.Restricted_Country) && item.Publisher_Name === 'The Little Book Company' &&

            
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12  pt-3 pb-5  text-center" >
     <div className='bookCard'>
                            <img className="book_img img-w-100 img-h-82 img-br-22"
                                src={this.state.isImageLoaded ? item.Image : Placeholder}
                                onLoad={() => this.setState({ isImageLoaded: true })}
                                onClick={() => this.onclickBook(item)}></img> <br></br>
                            <div class="tooltipq">

                                <label className="poppins-medium book-title cut-text " onClick={() => this.onclickBook(item)}>{item.Name}</label><br></br>
                                <span class="tooltipqtext">{item.Name} </span>
                            </div>
                            <label className="book-author cut-text poppins_light" onClick={() => this.filterByAuthor(item.Author_Name)}>{"by " + item.Author_Name}</label><br></br>
                            <label className="mr-2 book-price-text poppins_bold">Price :</label>
                        <label className="book-price-text poppins_bold" onClick={() => this.filterByAuthor(item.Author_Name)}>{this.state.isPakistan ? item.Price == 0 ? "Our Gift" : item.Price + " .00 RS" : item.Price_USD == 0 ? "Our Gift" : item.Price_USD + " .00 USD"}</label><br></br>
                        <img src={star}></img>
                        <label className="poppins_light ml-2 book_rating">{Math.floor(Math.random() * (5 * 10 - 4 * 10) + 4 * 10) / (1 * 10)}</label><br></br>
                        {item?.isPurchased === item.Book_ID ? (
                                    <button className="AlreadyBoughtBtn">Already Bought</button>
                            ) : (
                                    <button className="AddBookBtn" onClick={() => this.addToCart(item)}>Add to Cart</button>
                            )}

                        </div>            </div>
            }
            </>
        )

    }

    onSearch = (value) => {
        console.log(value)
        this.setState({
            bookList: value.books,
            pageTitle: value.SearchBy,
        })
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        // if (isLoading) {
        //     return (
        //         <div className="loader-large"></div>
        //     )
        // }
        return (
            <div class="dashboard-cotainer">

                {/* header */}
                { user.Full_Name && <UserHeader
                    history={this.props.history}
                    onSearch={this.onSearch}
                > </UserHeader>}
                {      !user.Full_Name &&
                    <Header
                        history={this.props.history}
                        onSearch={this.onSearch}
                    ></Header>
                }




                {/* banner */}
                <div className="  mt-5">
                    <div className="mlmr25">

                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 pb-5 ">
                                <div className="text-center">
                               
                                </div>
                            
                                <div class="New-nav-category" >
                                    <div className="col-md-12">
                                        <p className="New-nav-category_Heading">categories</p>
                                    </div>

                                    <div className=" col-md-12 ">
                                        <div className="signin_container mt-4">
                                            <div class="form-group mt-2 mb-2" onClick={() => this.onClickAllCategory()}>
                                                <input className="mt_4px" type="checkbox" id="Categories" />
                                                <label className="nav_category_checkbox_label poppins_medium mt_4px" for="Categories">All Categories</label>

                                            </div>
                                        </div>


                                    </div>
                                    {this.state.parentCategoryList && this.renderCategories(this.state.parentCategoryList)}
          



                                    <div className="col-md-12 mt-4">
                                        <p className="New-nav-category_Heading">Filtered by</p>
                                    </div>

                                    {this.state.categoryList && this.renderCategories(this.state.categoryList)}


                                 


                                    <div className="col-md-12 mt-4">
                                        <p className="New-nav-category_Heading">Sort By</p>
                                    </div>

                                    <div className=" col-md-12 ">
                                        <div className="signin_container mt-4">
                                            <div class="form-group mt-2 mb-2">
                                                <input className="mt_4px" type="checkbox" id="Author" />
                                                <label className="nav_category_checkbox_label poppins_medium mt_4px" for="Author">Author</label>

                                            </div>
                                        </div>


                                    </div>
                                    <div className=" col-md-12 ">
                                        <div className="signin_container mt-2">
                                            <div class="form-group mt-2 mb-2">
                                                <input className="mt_4px" type="checkbox" id="Publisher" />
                                                <label className="nav_category_checkbox_label poppins_medium mt_4px" for="Publisher">Publisher</label>

                                            </div>
                                        </div>


                                    </div>



                                </div>


                            </div>

                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12  pb-5  ">

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">
                                    <div className="row">
                                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12   ">
                                            {/* {this.state.pageTitle && (
                                                <label className="poppins_light book_heading_category">{this.state.pageTitle + 'Books'} </label>

                                            )} */}
                                            <label className="poppins_light book_heading_category">Our Publications </label>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12   ">


                                            <div className="text-right">
                                                {/* <button className="latest-book-btn" data-toggle="collapse" data-target="#latestbook">New Arrivals <img className="ml-3" src={dropdown_icon}></img></button>
                                                <div class="collapse  colapse_lib " id="latestbook">

<p className="poppins_light" onClick={() => this.onClickLibrary()}> My Libraray</p>
</div> */}
                                                <select className="latest-book-btn" onClick={this._handleKeyDownFilter}>
                                                    <option value={0}>New Arrivals</option>

                                                    <option value={1}>by Rating</option>
                                                    {/* <option>by Best Selling</option> */}
                                                    <option value={2} >by A - Z</option>
                                                    <option value={3} >by Z - A</option>
                                                    {/* <option onClick={() => this.onPressSortByName('Name', 'ASC')}>by A - Z</option>
                                                    <option onClick={() => this.onPressSortByName('Name', 'DESC')}>by Z - A</option> */}


                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">
                                    <div className="text-center">
                                        {isLoading && <Loader
                                            type="TailSpin"
                                            color="#BB423B"
                                            height={100}
                                            width={100}
                                        />}
                                    </div>
                                    <div className="row">
                                        {/* {this.state.bookList && this.state.bookList.map((item, i) =>
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-4 col-6    text-center">
                                                <Link to="/aboutbook">
                                                    <img className="book_img img-w-100" src={book1}></img> <br></br>
                                                </Link>
                                                <label className="poppins-medium book-title">{item.Name}</label><br></br>
                                                <label className="book-author poppins_light">by Saiyid Ali Naqvi</label><br></br>
                                                <img src={star}></img>
                                                <label className="poppins_light ml-2 book_rating">4.2</label>
                                            </div>
                                        )} */}

                                        {this.state.bookList && this.renderBooks()}
                                        {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-4 col-6  text-center">
                                    <Link to="/aboutbookurdu">

                                        <img className="book_img img-w-100" src={bookurdu}></img> <br></br>
                                        </Link>
                                        <label className="poppins-medium book-title">Mosam Bhar Jesay Log</label><br></br>
                                        <label className="book-author poppins_light">by Dr Aslam Farukhi</label><br></br>
                                        <img src={star}></img>
                                        <label className="poppins_light ml-2 book_rating">4.2</label>
                                    </div> */}


                                    </div>

                                </div>




                            </div>

                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cart: cart.cart
})
const mapDispatchToProps = ({
    getAllBooks,
    filterAllBooks,
    sortAllBooks,
    getAllAuthorBooks,
    getCountryFromIp,
    addToCart,
    removeFromCart,
    getAllCategory,
    getAllParentCategory,
    getFilterCategory,
})
OurPublication.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(OurPublication);

