import React, { Component } from 'react';
import { connect } from 'react-redux';
import bookurdu from '../../assets/images/book5.svg'
import book1 from '../../assets/images/books/book1.svg'
// import book5 from '../..assets/images/books/book5.svg'
import dropdown_icon from '../../assets/images/dropdown_icon.svg'
import star from '../../assets/images/star.svg'
import Header from '../../component/Header'
import UserHeader from '../../component/UserHeader'
import NoFile from '../../assets/images/NoFile.png'

import Loader from "react-loader-spinner";
import Placeholder from '../../assets/images/Placeholder.svg'


import Footer from '../../component/Footer'
import { getCountryFromIp, getAllCategory, getAllParentCategory, } from '../../store/actions/bookAction'
import { getAllBooks, filterAllBooks, getAllAuthorBooks, sortAllBooks, getFilterCategory } from '../../store/actions/libraryAction'

import { Link, withRouter } from 'react-router-dom';

class Libarary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            bookList: [],
            sortByAuthorName: false,
            pageTitle: "",
            categoryList: [],
            parentCategoryList: [],
            selectedCheckboxes: [],
            filterList: [],
            checked: true,
            allCategory: false,
            isImageLoaded: false,


        };
    }

    componentWillMount() {
        if (this.props != null && this.props.location.state != null && this.props.location.state.books) {
            this.setState({ isLoading: true })

            if (this.props != null && this.props.location.state != null && this.props.location.state.Author_Name) {
                console.log(this.props.location.state.Author_Name)
                this.setState({
                    bookList: this.props.location.state.books,
                    pageTitle: this.props.location.state.Author_Name,
                    isLoading: false,

                })
            }

            if (this.props != null && this.props.location.state != null && this.props.location.state.SearchBy) {
                console.log(this.props.location.state.SearchBy)
                this.setState({
                    bookList: this.props.location.state.books,
                    pageTitle: this.props.location.state.SearchBy,
                    isLoading: false,

                })
            }




        } else {
            if (this.props != null && this.props.location.state != null && this.props.location.state.pageTitle) {
                console.log(this.props.location.state.pageTitle)
                this.setState({ isLoading: true })

                this.props.getAllBooks().then((res) => {
                    console.log(res.content)
                    if (res.status == true) {
                        this.setState({
                            bookList: res.content?.library_has_books,
                            //pageTitle: this.props.location.state.pageTitle,
                            isLoading: false

                        })

                    }
                    else {
                        alert(res)
                    }
                }).catch((err) => {
                    console.log(err)

                })
            } else {
                this.setState({ isLoading: true })

                this.props.getAllBooks().then((res) => {
                    console.log(res.content[0]?.library_has_books)
                    if (res.status == true) {
                        this.setState({
                            bookList: res.content[0]?.library_has_books,
                            isLoading: false

                        })

                        setAmplitudeUserProperties({ no_of_books_purchased: res.content[0]?.library_has_books?.length || 0 })
                    }
                    else {
                        alert(res)
                    }
                }).catch((err) => {
                    console.log(err)

                })
            }
        }

    }

    onClickPreview = (book) => {



        this.props.history.push("/preview", { book: book })

    }

    componentDidMount() {
        console.log(this.props.user)

        if (!this.props.user?.User_ID)
            this.props.history.push("/login")

        this.props.getAllCategory().then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    categoryList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

        this.props.getAllParentCategory().then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    parentCategoryList: res.content,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    // onPressSort = (colName) => {
    //     var Sort = 'ASC'
    //     this.props.sortAllBooks(colName, Sort).then((res) => {
    //         console.log(res.content)
    //         if (res.status == true) {
    //             this.setState({
    //                 bookList: res.content,
    //             })

    //         }
    //         else {
    //             alert(res)
    //         }
    //     }).catch((err) => {
    //         console.log(err)

    //     })

    // }

    onPressSortByName = (colName, sort) => {

        this.props.sortAllBooks(colName, sort).then((res) => {
            console.log(res.content)
            if (res.status == true) {
                this.setState({
                    bookList: res.content[0]?.library_has_books,
                    sortByAuthorName: !this.state.sortByAuthorName
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }

    onclickBook = (book) => {
        this.props.history.push('/preview', { libbook: book })
    }

    onClickFilter = (id) => {
        this.props.filterAllBooks(id).then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content[0]?.library_has_books,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }

    filterByAuthor = (Name) => {
        this.props.getAllAuthorBooks(Name).then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content[0]?.library_has_books,
                    pageTitle: Name
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }
    onClickAllCategory = () => {
        this.props.getAllBooks().then((res) => {
            if (res.status == true) {
                this.setState({
                    bookList: res.content[0]?.library_has_books,
                })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
    }
    onClickLibrary = () => {
        // if(this.props.user === {}){
        console.log(this.props.user)
        this.props.history.push("/category",)
        // }else{
        //     console.log(this.props.user)
        //     this.props.history.push("/login")
        // }
    }
    onChangeCheckbox = id => {
        const selectedCheckboxes = this.state.selectedCheckboxes
        const filterList = this.state.filterList
        console.log(this.state.bookList)
        const exists = this.state.bookList.some(v => (v.Category_ID === id));
        console.log(this.state.bookList.some(v => (v.Category_ID === id)))
        // Find index
        const findIdx = selectedCheckboxes.indexOf(id);

        // Index > -1 means that the item exists and that the checkbox is checked
        // and in that case we want to remove it from the array and uncheck it
        if (findIdx > -1) {
            selectedCheckboxes.splice(findIdx, 1);
            filterList.splice(findIdx, 1);
        } else {
            selectedCheckboxes.push(id);
            if (exists) {
                console.log("inexist")
                filterList.push(this.state.bookList.filter(v => (v.Category_ID.includes(id)))[this.state.filterList?.length]);
            }
        }

        this.setState({
            selectedCheckboxes: selectedCheckboxes,
            filterList: filterList
        }, () => {
            console.log(this.state.selectedCheckboxes)
            this.props.getFilterCategory({
                list: this.state.selectedCheckboxes
            }).then((response) => {
                console.log(response)
                if (response.status) {
                    if (response.content[0]?.library_has_books?.length > 0) {
                        this.setState({
                            bookList: response.content[0]?.library_has_books
                        })
                    } else {
                        this.setState({
                            bookList: []
                        })
                    }
                    return



                }
                else {
                    return
                }
            }).catch((err) => {
                console.log(err)

            })

        });
    };

    renderCategories = (List) => {

        return List.map((item, i) =>
            <div className=" col-md-12 ">
                <div className="signin_container mt-2">
                    <div class="form-group mt-2 mb-2">
                        <input className="mt_4px" type="checkbox" id={item.Category_Name} onChange={() => this.onChangeCheckbox(item.Category_ID)} />
                        <label className="nav_category_checkbox_label poppins_medium mt_4px" for={item.Category_Name}>{item.Category_Name}</label>

                    </div>
                </div>


            </div>
        )
    }

    renderBooks = () => {
        if (!this.state.isLoading && this.state.bookList?.length < 1) {

            return <div className="nofile">
                <div className="text-center">
                    <img src={NoFile} />
                    <p className="poppins_bold SorryTextM mb-0">NO E-Book Found</p>
                    <p className="poppins_bold SorryTextS mb-0 " >Tweak some filters and check other categories to find more E-books</p>
                </div>

            </div>
        }
        return this.state.bookList.map((item, i) =>

            <div className="col-xl-3 col-lg-3 col-md-6  col-12  pt-3 pb-5 mt-3 mb-b  text-center" >
                <div className='bookCard'>

                    <img className="book_img img-w-100 img-br-22"
                        //  src={item.book?.Image}
                        src={this.state.isImageLoaded ? item.book?.Image : Placeholder}
                        onLoad={() => this.setState({ isImageLoaded: true })}
                        onClick={() => this.onclickBook(item.book)}></img> <br></br>
                    <div class="tooltipq">

                        <p className="poppins-medium book-title cut-text mt-3" >{item.book?.Name}</p>

                        <span class="tooltipqtext">{item.book?.Name}</span>
                    </div>

                    <p className="book-author poppins_light cut-text" >{"by " + item.book?.Author_Name}</p>

                    <div className="text-center">

                        <button className="AlreadyBoughtBtn" onClick={() => this.onclickBook(item.book)}>Read Now</button>
                    </div>
                </div>
            </div>
        )
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        // if (isLoading) {
        //     return (
        //         <div className="loader-large"></div>
        //     )
        // }
        return (
            <div class="dashboard-cotainer">

                {/* header */}
                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }




                {/* banner */}
                <div className="  mt-5">
                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  pb-5 mb-5 ">

                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">
                                    <div className="row">
                                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12   ">


                                            <label className="poppins_light book_heading_category">{this.props.user?.Full_Name + ' Bookshelf'}</label>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12   ">

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12    mt-4">
                                    <div className="text-center">
                                        {isLoading && <Loader
                                            type="TailSpin"
                                            color="#BB423B"
                                            height={100}
                                            width={100}
                                        />}
                                    </div>
                                    <div className="row">

                                        {this.state.bookList && this.renderBooks()}



                                    </div>

                                </div>




                            </div>

                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    getAllBooks,
    filterAllBooks,
    sortAllBooks,
    getAllAuthorBooks,
    getCountryFromIp,
    getAllCategory,
    getAllParentCategory,
    getFilterCategory,
})
Libarary.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Libarary);

