import React, { Component } from 'react';
import { connect } from 'react-redux';
import barrier from '../../assets/images/barrier.png'

import Header from '../../component/Header'
import Footer from '../../component/Footer'
import UserHeader from '../../component/UserHeader'
import { Modal } from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';


class Printondemand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            showModal: false,

        };
    }
    handleClose = () => {
        this.setState({ showModal: false })
     }
    componentDidMount() {
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }


                {/* banner */}
                <div className="pt-3 pb-5 mb-5 ">
                 


                    <div className="text-center mt-3">
                        <img src={barrier} />
                        <p className="poppins_bold SorryText mt-5 mb-0">Sorry</p>
                        <p className="poppins_bold SorryTextM mb-0">This Page is Under development</p>
                        <p className="poppins_bold SorryTextS mb-0 " >Will be available soon</p>
                    </div>



                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
})
Printondemand.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Printondemand);

