import React, { Component } from 'react';
import { connect } from 'react-redux';
import increases from '../../assets/images/publisher/increase.svg'
import ebook from '../../assets/images/publisher/ebook.svg'
import hardcopy from '../../assets/images/publisher/hardcopy.svg'
import salestrack from '../../assets/images/publisher/salestrack.svg'
import panel from '../../assets/images/publisher/panel.svg'
import contract from '../../assets/images/publisher/contract.svg'
import payment from '../../assets/images/publisher/payment.svg'
import copyright from '../../assets/images/publisher/copyright.svg'
import Slider2 from '../../assets/images/Slider2.svg'
import easypaisa from '../../assets/images/publisher/easypaisa.svg'
import venmo from '../../assets/images/publisher/Venmo.svg'
import paypal from '../../assets/images/publisher/paypal.svg'
import visa from '../../assets/images/publisher/visa.svg'
import banktransfer from '../../assets/images/publisher/banktransfer.svg'
import Footer from '../../component/Footer'
import Header from '../../component/Header'
import UserHeader from '../../component/UserHeader'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
class Author extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
        };
    }
    componentDidMount() {
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location,user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">
                {/* header */}
                { user.Full_Name && <UserHeader
                history={this.props.history}
                > </UserHeader> }
                 {      !user.Full_Name &&
                     <Header
                     history={this.props.history}
                     
                     ></Header>
                }
                {/* banner */}
                <div className="  ">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  ">
                        <img className="img-w-100" src={Slider2}></img>
                    </div>
                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">

                                    <p className="poppins_semibold aboutus_tag">Dear Author,</p>

                                    <p className="poppins_semibold  aboutus_heading">We match writers to their readers. </p>
                                    <p className="poppins_light aboutus_tag">The Little Book Company Publishing House provides authors the opportunity to be able to publish their work after we have reviewed and accepted for publication on our platform.   </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">
                                <a href="https://admin.littlebookcompany.net">

                                  <button className="PublisherRegisterBtn">Register as author</button>
                                  <button className="PublisherRegisterBtn">Login as author</button>

                                  </a>
                                </div>
                            </div>
                       
                        </div>


                    </div>

                   
                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6 pl-5 pr-5 mt-5 text-center">
                                <img class="pubicon" src={increases}></img>
                                <p className="poppins_medium pubhead ">Increase your Sales</p>
                                <p className="poppins_regular pubtext ">This platform increases your sales by providing you the opportunity to reach customers world wide.  </p>
                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">
                                <img class="pubicon" src={ebook}></img>
                                <p className="poppins_medium pubhead ">E-Books Transformation</p>
                                <p className="poppins_regular pubtext ">We provide a platform for publishers to showcase their books and sell them as e-books. The Little Book Company Transforms your published books into E-books for this platform. </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={hardcopy}></img>
                                <p className="poppins_medium pubhead ">Paperback and Hardcopy Versions
</p>

                                <p className="poppins_regular pubtext ">While your books are available on this platform as E-books, customers can also order the hardcopy version (paperback). The delivery of publishers’ paperback and hardback copies is the responsibility of each Publisher.
  </p>

                            </div>

                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={salestrack}></img>
                                <p className="poppins_medium pubhead ">Sales Tracking and Offers</p>

                                <p className="poppins_regular pubtext ">All sales and payments are made on this platform. Publishers are able to sell their books online on our platform and receive direct online payments. </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={panel}></img>
                                <p className="poppins_medium pubhead ">Dedicated Individual Publisher
                                Portal for Monitoring of Sales
and Payments</p>

                                <p className="poppins_regular pubtext ">Publishers on our platform have access to their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.    </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={contract}></img>
                                <p className="poppins_medium pubhead ">Agreement Policies</p>

                                <p className="poppins_regular pubtext ">Each publisher signs an online agreement with The Little Book Company (here) before books are placed on this platform. The agreement enables the Publisher to receive payments for their book sales through payment transfers from the Little Book Company to the Publisher </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={payment}></img>
                                <p className="poppins_medium pubhead ">Easy Payment Transfer</p>

                                <p className="poppins_regular pubtext ">Payment transfers from the Little Book Company to the Publisher for paperbacks and hardcopies will be made after delivery of the book (s) has been received by the customer.  </p>

                            </div>
                            <div className="col-xl-3  col-lg-3 col-md-3 col-sm-6 col-6  mt-5 pl-5 pr-5 text-center">

                                <img class="pubicon" src={copyright}></img>
                                <p className="poppins_medium pubhead ">Copyright</p>

                                <p className="poppins_regular pubtext ">The authors shall retain 100% copyright of their works. </p>

                            </div>
                        </div>


                    </div>


                    <div className="mlmr25">
                        <div className="row">
                            <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center  ">


                                    <p className="poppins_semibold  aboutus_heading">Payment Modes Available!</p>
                                    {/* <p className="poppins_light aboutus_tag">We provides vast verity of payment method use for internationally world wide for our customers.</p> */}


                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="mlmr150">
                        <div className="row">
                            <div class="imgwth">
                                <img src={easypaisa}></img>
                            </div>
                            {/* <div class="imgwth">
                                <img src={venmo}></img>
                            </div>
                            <div class="imgwth">
                                <img src={paypal}></img>
                            </div> */}
                            <div class="imgwth">
                                <img src={visa}></img>
                            </div>
                            <div class="imgwth">
                                <img src={banktransfer}></img>
                            </div>

                        </div>


                    </div>

                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
})
Author.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Author);

