const epubViewStyles = {
    viewHolder: {
      position: "relative",
      height: "100%",
      width: "100%",
      overflowX:"hidden"
    },
    view: {
      height: "95%",
      overflowX:"hidden",
      width: "calc(100% - 40px)",
      marginLeft: "40px",


    }
    
  };
  
  export default epubViewStyles