import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Link, useHistory } from 'react-router-dom'
import { TailSpin } from 'react-loading-icons'
import Loader from "react-loader-spinner"
import { Button, Modal } from 'react-bootstrap'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements'
import axios from 'axios'

import VisaCardsImage from '../../assets/images/Checkout/visaCards.png'
import secureStripe from '../../assets/images/Checkout/secure_stripe.svg'
import CongratulationImage from '../../assets/images/Modal/congratulations.svg';
import wentWrongIcon from '../../assets/images/Modal/wentWrongIcon.svg'

import '../../assets/styles/stripeForm.css';

const CheckoutForm = ({ stripe, Amount, Amount_without_stripe, User, Description, Books, CartIsEmpty, postOrder, orderConfirmationMailToUser, orderConfirmationMailToAuthor, updateOrder, putBookInLibrary, getCountryFromIp }) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState('');
    const [cartNoInput, setCardNoInput] = useState({});
    const [ExpiryInput, setExpiryInput] = useState({});
    const [CVVInput, setCVVInput] = useState({});
    const [busy, setBusy] = useState(false);

    const [order, setOrder] = useState({});
    const [isPakistan, setIsPakistan] = useState(true)
    const [currency, setCurrency] = useState('')
    const [currentExchangeRate, setCurrentExchangeRate] = useState(0)


    const TransactionError = () => {
        // setErrorModal(!errorModal)
        Swal.fire({
            customClass: 'sweet-alert',
            title: `<h1>Payment Failed</h1>`,
            html: `<p>Oh sorry due to some issue we are unable to process your payment. Try again.</p>`,
            // html : true,
            iconHtml: `<img src=${wentWrongIcon} width='70px' />`,
            // position: 'top-end',
            showCloseButton: true,
            confirmButtonText: "Try Again",
            buttonsStyling: false,
            customClass: {
                container: 'container-class',
                htmlContainer: 'my-htmlText',
                html: 'my-htmlText',
                popup: 'popup-class',
                icon: 'no-border',
                title: 'my-title',
                confirmButton: 'my-confirm-button-class',
                closeButton: 'your-close-button-class',
            }
        })
            .then(() => {
                history.push('/checkout')
            })
    }

    const TransactionSuccess = () => {
        Swal.fire({
            customClass: 'sweet-alert',
            title: `<h1>Congratulations!</h1>`,
            html: `<p>You have successfully bought an ebook, go back to your library & start reading.</p>`,
            // html : true,
            iconHtml: `<img src=${CongratulationImage} width='70px' />`,
            // position: 'top-end',
            showCloseButton: true,
            confirmButtonText: "Go back to library",
            buttonsStyling: false,
            customClass: {
                container: 'container-class',
                htmlContainer: 'my-htmlText',
                html: 'my-htmlText',
                popup: 'popup-class',
                icon: 'no-border',
                title: 'my-title',
                confirmButton: 'my-confirm-button-class',
                closeButton: 'your-close-button-class',
            }
        })
            .then(() => {
                history.push('/library')
            })
    }
    useEffect(() => {

        
        // Api call to get current dollar exchange rate
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://api.getgeoapi.com/v2/currency/convert?api_key=7fff062f53b27b6373ded160e89f0d3726c11d57&from=USD&to=PKR&amount=1&format=json", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                let response = JSON.parse(result)
                console.log(response)
                setCurrentExchangeRate(response.rates.PKR.rate)   
            })
            .catch(error => {
                console.log('error', error)
                setCurrentExchangeRate(null)
            });
        //Function calling API to get copuntry 
        getCountryFromIp()
            .then(res => {
                console.log(res)
                if (res.country !== 'Pakistan') {
                    setIsPakistan(false)
                    setCurrency('USD')
                } else {
                    setIsPakistan(true)
                    setCurrency('PKR')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    console.log(currency)
    useEffect(()=>{
        console.log("Exchange Rate" , currentExchangeRate)
        console.log("BOOK AMOUNT" , Amount)
        console.log("BOOK AMOUNT" , Amount_without_stripe)
    },[currentExchangeRate])

    
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Pressed');
        const { token } = await stripe.createToken();
        console.log('TOEK',token)
        setBusy(!busy);
        // console.log()

        const update = (data) => {
            // if (!this.state.order?.Order_ID)
            // return
            updateOrder({

                'Order_ID': order?.Order_ID,
                'Payment_Method': 'Stripe',
                'Payment_Status': data?.Payment_Status === 'Cleared' ? 'Cleared' : 'Failed',
                'Reference_No': null,
            })
                .then((res) => {
                    console.log(res)
                    // this.setState({ order: res.Order })
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        
        const createOrder = (Order_ID) => {
            var data = {
                'Order_ID': Order_ID,
                'Payment_Method': 'Stripe',
                'Payment_Status': 'Pending',
                'Amount': Amount_without_stripe,
                'books': Books,
                'currency': isPakistan ? 'PKR' : 'USD',
                'dollarRate' : isPakistan ? null : currentExchangeRate
                // 'dollarRate' : null
            }
            var data1 = {
                'Order_ID': Order_ID,
                'Payment_Method': 'Stripe',
                'Payment_Status': 'Cleared',
                'Amount': Amount_without_stripe,
                'books': Books,
                'dollarRate' : isPakistan ? null : currentExchangeRate
            }

            postOrder(data)
                .then((res) => {
                    console.log(res)
                    setOrder({ ...order, content: res.content })
                    // console.log(order)
                    orderConfirmationMailToUser({
                        'Order_ID': Order_ID,
                        //'Book_ID': this.state.book?.Book_ID,
                        'Payment_Method': 'Stripe',
                        'email': User?.Email,
                        'name': User?.Full_Name,
                        'books': Books,
                    })
                        .then((res) => {
                            console.log(res)
                            setOrder({ ...order, content: res.content })

                        })
                        .catch((err) => {
                            console.log(err)
                        })

                    orderConfirmationMailToAuthor({
                        'Order_ID': Order_ID,
                        //'Book_ID': this.state.book?.Book_ID,
                        'Payment_Method': 'Stripe',
                        'books': Books,
                    })
                        .then((res) => {
                            console.log(res)
                            setOrder({ ...order, content: res.content })

                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    // update(data1)
                    updateOrder({
                        'Order_ID': Order_ID,
                        'Payment_Method': 'Stripe',
                        'Payment_Status': 'Cleared',
                        'Reference_No': null,
                        'currency': isPakistan ? "PKR" : "USD",
                    })
                        .then((res) => {
                            console.log(res)
                            // this.setState({ order: res.Order })
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                    putBookInLibrary({
                        'books': Books
                    })
                        .then(response =>
                            console.log(response))
                        .catch(error =>
                            console.log(error)
                        );
                })
                .catch(error =>
                    console.log(error)
                )
        }

        const order = await axios.post('https://api.littlebookcompany.net/v1/api/stripe/charge', {
            currency: currency,
            amount: Amount,
            source: token.id,
            receipt_email: User.Email,
            description: Description,
            books: Books
        })
            .then(response => {
                console.log(response.data.message)
                setBusy(false);
                if (response.data.message == 'charge posted successfully') {
                    // var Order_ID = Math.floor(Math.random() * 900000) + 100000;
                    var Order_ID = 'xxx-xxx-xxx-xxx'.replace(/[x]/g, (c) => {
                        let dataTimeNow = new Date().getTime()
                        var r = (dataTimeNow + Math.random() * 16) % 16 | 0;
                        dataTimeNow = Math.floor(dataTimeNow / 16)
                        return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16)
                    })
                    createOrder(Order_ID);
                    TransactionSuccess()
                } else if (response.data.message !== 'charge posted successfully') {
                    console.log('invalid data');
                    TransactionError();
                }
            })
            .catch(error => {
                console.log(error.response.data);
                TransactionError();
            })

    }

    const goBackToLibrary = () => {
        history.push('/library', {})
        window.location.reload(false);
    }

    const closeErrorModal = () => {
        setErrorModal(!errorModal);
        window.location.reload(false);
    }

    console.log(User)
    return (
        <>
            {
                <Modal show={showModal} onHide={!showModal} className='myModal'>
                    <Modal.Header className='modalHeader' closeButton>
                    </Modal.Header>
                    <Modal.Body className='ModalImageHolder'>
                        <img src={CongratulationImage} width='50%' className='modalImage' />
                    </Modal.Body>
                    <Modal.Footer className='modalFooter'>
                        <Button className='modalButton' onClick={goBackToLibrary}>
                            Go back to library
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                <Modal show={errorModal} onHide={closeErrorModal} className='myModal'>
                    <Modal.Header className='modalHeader' closeButton>
                    </Modal.Header>
                    <Modal.Body className='ModalErrorHolder'>
                        <h1 style={{ textAlign: 'center', color: '#B5322A', fontWeight: '900' }}>Aww Snap...</h1>
                        <h4 style={{ textAlign: 'center', color: '#B5322A', fontWeight: '600' }}>Something went wrong</h4>
                    </Modal.Body>
                    <Modal.Footer className='modalFooter'>
                        <Button className='modalButton' onClick={closeErrorModal}>
                            Go back to Cart
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
            <div className="col-md-8 col-lg-8 col-sm-12 mt-5">
                <div className="text-center">
                    {busy && <Loader
                        type="TailSpin"
                        color="#BB423B"
                        height={100}
                        width={100}
                    />}
                </div>
                {
                    !busy &&

                    <form onSubmit={handleSubmit}>
                        <div className='row pb-4'>
                            <div className='col-md-8 col-lg-8 col-sm-12 inputBox'>
                                <CardNumberElement className='stripe_input' placeholder='' onFocus={() => setCardNoInput({ color: 'black', top: '-15px', left: '30px', backgroundColor: 'white' })} />
                                <span className='text' style={cartNoInput}>Card number</span>
                            </div>
                            <div className='col-md-4 col-lg-4 col-sm-12 mt-4 inputBox'>
                                <img src={VisaCardsImage} width='100%' />
                            </div>
                        </div>

                        <div className='row pb-4'>
                            <div className='col-lg-4 col-md-4 col-sm-12 mt-4 inputBox'>
                                <CardExpiryElement className='stripe_input' placeholder='' onFocus={() => setExpiryInput({ color: 'black', top: '-15px', left: '30px', backgroundColor: 'white' })} />
                                <span className='text' style={ExpiryInput}>Expiry Date</span>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-12 mt-4 inputBox'>
                                <CardCVCElement className='stripe_input' placeholder='' onFocus={() => setCVVInput({ color: 'black', top: '-15px', left: '30px', backgroundColor: 'white' })} />
                                <span className='text' style={CVVInput}>CVV</span>
                            </div>
                        </div>

                        <div className='row pt-4'>
                            <div className='col-md-8 col-lg-8 col-sm-12'>
                                <button type="submit" className="order-button">
                                    Confirm & Pay {busy && <TailSpin width={15} height={'1em'} />}
                                </button>
                            </div>
                        </div>
                        <div className='row pt-4 mt-5'>
                            <div className='col-md-8 col-lg-8 col-sm-12'>
                                <img src={secureStripe} width='100%' />
                            </div>
                        </div>
                    </form>
                }
            </div>
        </>
    )
}

export default injectStripe(CheckoutForm)