import React, { useState, useRef, useEffect } from 'react'


function PayWithPayPal(props) {
    const { total,Order_ID,user , cart } = props
    const [paidFor, setPaidFor] = useState(false)
    const [error, setError] = useState(null)
    const paypalRef = useRef()

    useEffect(() => {
        window.paypal.Buttons({
            createOrder: (data, actions) => {
                return actions.order.create({
                    intent: 'CAPTURE',
                    purchase_units: [{
                        description: 'Book',
                        amount: {
                            currency_code: 'USD',
                            value: total
                        }
                    }]
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();
                console.log("Cart------------------------",  localStorage.getItem('Book_ID'))
                setPaidFor(true);
                    

                    props.updateOrder({
                        'Payment_Method': 'PayPal',
                        'Payment_Status': 'Cleared',
                        'Order_ID': Order_ID,
                        'Reference_No': order.id,
                    }).then((res) => {
                        console.log( "HERE updateOrder then",props.cart)
                        props.orderConfirmationMailToUser({

                            'Order_ID': Order_ID,
                            'Payment_Method': 'PayPal',
                            'email': user?.Email,
                            'name': user?.Full_Name,
                            'books': JSON.parse(localStorage.getItem('Book_ID')),
                            //'books': cart
                        })
                            .then((res) => {
                                console.log(res)
                               
            
                            })
                            .catch((err) => {
                                console.log(err)
            
                            })
                        props.orderConfirmationMailToAuthor({
            
                            'Order_ID': Order_ID,
                            'Payment_Method': 'PayPal',
                            'books': JSON.parse(localStorage.getItem('Book_ID')),
                            //'books': cart
                        
                        })
                            .then((res) => {
                                console.log(res)
                               
            
                            })
                            .catch((err) => {
                                console.log(err)
            
                            })
                        props.putBookInLibrary({
                            'books':  JSON.parse(localStorage.getItem('Book_ID')),
                        }).then((res) => {
                            console.log( "HERE putBookInLibrary then")
                            console.log(res)
                            if (res.status) {
                            console.log(res)
                            props.history.push("/library")
                            //this.setState({ isLoading: false })
                            return
                        }
                        })
                            .catch((err) => {
                                console.log( "HERE putBookInLibrary err")
                                console.log(err)
    
                            })
                       
                    }).catch((err) => {
                        console.log( "HERE  updateOrder ERR")
                        console.log(err)
    
                    })
            },
            onError: err => {
                setError(err)
                console.error(err)
                this.props.updateOrder({
                    'Payment_Method': 'PayPal',
                    'Payment_Status': 'Failed',
                    'Order_ID': Order_ID,
                }).then((res) => {
                    console.log(res)
                    if (res.status) {
                        
                        props.history.push("/checkout")
                        console.log(res)

                        return

                    }
                    else {
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })
            }
        }).render(paypalRef.current)
    })
    if (paidFor) {
        return (
            <div>
                Thanks For Making Payment
            </div>
        )
    }

    if (error) {
        return (
            <div>
                Error
            </div>
        )
    }
    return (
        <div ref={paypalRef}></div>
    )
}


export default PayWithPayPal