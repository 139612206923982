import React, { Component } from 'react';
import { connect } from 'react-redux';
import barrier from '../../assets/images/barrier.png'

import Header from '../../component/Header'
import Footer from '../../component/Footer'
import UserHeader from '../../component/UserHeader'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
        };
    }
    componentDidMount() {
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }


                {/* banner */}
                <div className="mt-5 mb-5 pt-5 pb-5 ">
                    <div className="pl-5 pr-5 PrivacyPolicyContainer">
                        {/* <img src={barrier}/>
                <p className="poppins_bold SorryText mt-5 mb-0">Sorry</p>
                <p className="poppins_bold SorryTextM mb-0">This Page is Under development</p>
                <p className="poppins_bold SorryTextS mb-0 " >Will be available soon</p> */}
                        <p className="poppins_bold heading">Privacy and Confidentiality </p>
                        <p className="text poppins_regular">Welcome to The Little Book Company e-book digital platform. We are available on our website, and as an Android (on Google Playstation) and iOS App (on the Apple Store). <br></br>


                            We respect your privacy and want to protect your personal information. To learn more, please read this Privacy Policy. <br></br>


                            This Privacy Policy explains how we collect, use and (under certain conditions) disclose your personal information. This Privacy Policy also explains the steps we have taken to secure your personal information. Finally, this Privacy Policy explains your options regarding the collection, use and disclosure of your personal information. By visiting the Site directly or through another site, you accept the practices described in this Policy.<br></br>


                            Data protection is a matter of trust and your privacy is important to us. We shall therefore only use your name and other information which relates to you in the manner set out in this Privacy Policy. We will only collect information where it is necessary for us to do so and we will only collect information if it is relevant to our dealings with you.<br></br>


                            We will only keep your information for as long as we are either required to by law or as is relevant for the purposes for which it was collected.<br></br>


                            You can visit the Site and browse without having to provide personal details. You can also read our free books without having to provide any personal details. <br></br>


                            During your visit to the Site without registering you remain anonymous and at no time can we identify you unless you have an account on the Site and log on with your user name and password</p><br></br>

                        <p className="poppins_bold text">1. Data that we collect </p>
                        <p className="poppins_regular text">We have to request your location by country only. The information on customer country location is used only by The Little Book Company  solely for the purpose of honoring prior Copy Right agreements of some of our authors and publishers. Our request to our customers to specify their country location is due to prior Copyright Agreements which restricts where The Little Book Company can sell. <br></br>


                            Some authors and publishers on The Little Book Company site have prior copyright Agreements with other publishers on the regions where these publishers can sell their books. In order to honor these Agreements we request verification of the country location of our customers and readers so that we can block our sales for a certain book in those regional markets which the prior Agreements for that book specify as being under the Copyright Agreement and where The Little Book Company does not have permission to sell books.  <br></br>


                            We collect, store and process your data for processing your purchase on the Site and any possible later claims, and to provide you with our services. We may collect personal information including, but not limited to, your title, name, gender, date of birth, email address, mobile number, payment details, payment card details or bank account details.<br></br>


                            We will use the information you provide to enable us to process your orders and to provide you with the services and information offered through our website and Apps and which you request. Further, we will use the information you provide to administer your account with us; verify and carry out financial transactions in relation to payments you make; audit the downloading of data from our website; improve the layout and/or content of the pages of our website and customize them for users; identify visitors on our website.<br></br>


                            Subject to obtaining your consent we may contact you by email with details of other products and services. If you prefer not to receive any marketing communications from us, you can opt out at any time.<br></br>


                            We may in the future have print on demand capability. Which means that we would need to pass your name and address on to a third party in order to make delivery of the product to you (for example to our courier or supplier). We do not collect your address but in the future if we do have print on demand then we will need to have a delivery address from you which we will need to share with the courier service. Therefore you must only submit to us the Site information which is accurate and not misleading and you must keep it up to date and inform us of changes.<br></br>


                            If we have print on demand in the future then at that time your actual book order details may be stored with us but for security reasons cannot be retrieved directly by us. However, you may access this information by logging into your account on the Site. Here you will be able to view the details of your orders that have been completed, those which are open and those which are shortly to be dispatched and administer your address details, bank details ( for refund purposes) and any newsletter to which you may have subscribed. It is Your responsibility solely to treat your personal access data confidentially and not make it available to unauthorized third parties. We cannot assume any liability for misuse of passwords unless this misuse is our fault. <br></br>


                            Other uses of your Personal Information <br></br>


                            We may use your personal information for opinion and market research. Your details are anonymous and will only be used for statistical purposes. You can choose to opt out of this at any time. Any answers to surveys or opinion polls we may ask you to complete will not be forwarded on to third parties. Disclosing your email address is only necessary if you would like to take part in competitions. We save the answers to our surveys separately from your email address.


                            We may also send you other information about us, the Site, our other websites, our products, sales promotions, our newsletters, anything relating to other companies in our group or our business partners. If you would prefer not to receive any of this additional information as detailed in this paragraph (or any part of it) please click the 'unsubscribe' link in any email that we send to you. Within 7 working days (days which are neither (i) a Sunday, nor (ii) a public holiday anywhere in Pakistan) of receipt of your instruction we will cease to send you information as requested. If your instruction is unclear we will contact you for clarification.




                            Third Parties and Links

                            We may pass your details to fintech companies in our group. We may also pass your details to our authors and publishers. And if we have print on demand then we may use third parties to assist us with delivering products to you, to help us to collect payments from you, to analyze data and to provide us with marketing or customer service assistance.


                            We may exchange information with third parties for the purposes of fraud protection and credit risk reduction. We may transfer our databases containing your personal information if we sell our business or part of it. Other than as set out in this Privacy Policy, we shall NOT sell or disclose your personal data to third parties without obtaining your prior consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law.


                            The Site may contain advertising of third parties and links to other sites or frames of other sites. Please be aware that we are not responsible for the privacy practices or content of those third parties or other sites, nor for any third party to whom we transfer your data in accordance with our Privacy Policy.</p>
                        <p className="poppins_bold text">2. Cookies</p>
                        <p className="poppins_regular text">The acceptance of cookies is not a requirement for visiting the Site. However we would like to point out that the use of the 'basket' functionality on the Site and ordering is only possible with the activation of cookies. Cookies are tiny text files which identify your computer to our server as a unique user when you visit certain pages on the Site and they are stored by your Internet browser on your computer's hard drive. Cookies can be used to recognize your Internet Protocol address, saving you time while you are on, or want to enter, the Site. We only use cookies for your convenience in using the Site (for example to remember who you are when you want to amend your shopping cart without having to re-enter your email address) and not for obtaining or using any other information about you (for example targeted advertising). Your browser can be set to not accept cookies, but this would restrict your use of the Site. Please accept our assurance that our use of cookies does not contain any personal or private details and are free from viruses. If you want to find out more information about cookies, go to https://www.allaboutcookies.org or to find out about removing them from your browser, go to https://www.allaboutcookies.org/manage-cookies/index.html.<br></br>
                            This website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.<br></br> </p>
                        <p className="poppins_bold text">3. Security</p>
                        <p className="poppins_regular text">We have in place appropriate technical and security measures to prevent unauthorized or unlawful access to or accidental loss of or destruction or damage to your information. When we collect data through the Site, we collect your personal details on a secure server. We use firewalls on our servers. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you. You are responsible for protecting against unauthorized access to your password and to your computer. </p>
                        <p className="poppins_bold text">4. Your rights</p>
                        <p className="poppins_regular text">4. If you are concerned about your data you have the right to request access to the personal data which we may hold or process about you. You have the right to require us to correct any inaccuracies in your data free of charge. At any stage you also have the right to ask us to stop using your personal data for direct marketing purposes.</p>

                    </div>



                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
})
PrivacyPolicy.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(PrivacyPolicy);

