import React, { Component } from 'react';
import { connect } from 'react-redux';


import EasyPaisaLogo from '../../assets/images/easypaisalogo.svg'
import UserHeader from '../../component/UserHeader'
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import moment from 'moment';

import Header from '../../component/Header'
import Footer from '../../component/Footer'
import {orderConfirmationMailToUser,orderConfirmationMailToAuthor, postOrder, updateOrder, getEasyPayMbl, easyPayViaCard, putBookInLibrary } from '../../store/actions/orderAction'

let search = window.location.search;
let params = new URLSearchParams(search);
let orderRefNumber = params.get('orderRefNumber');
let paymentMethod = params.get('paymentMethod');
let status = params.get('status');
let transactionRefNumber = params.get('transactionRefNumber');




class ConfirmPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            book: {},
            Order_ID: "",
            account_no: "",
            Order: {},
            isOrderCreated: 0,
            easyPayError: "",

            isEasyPay: true

        };
    }
    componentDidMount() {
        console.log(orderRefNumber, paymentMethod, status)
        const Book_ID =JSON.parse ( localStorage.getItem('Book_ID'))
        const Order_ID = JSON.parse(localStorage.getItem('Order_ID'))

        console.log(Book_ID)
        if (status == "0000") {
            this.setState({
                isOrderCreated: 1,
            })
            this.props.updateOrder({
                //'Book_ID': Book_ID,
                'Payment_Method': 'Easy_paisa'+paymentMethod,
                'Payment_Status': 'Cleared',
                'Order_ID': Order_ID,
               // 'Reference_No': res.transactionId,
            }).then((res) => {

                this.props.orderConfirmationMailToUser({

                    'Order_ID': Order_ID,
                    //'Book_ID': this.state.book?.Book_ID,
                    'Payment_Method': 'EasyPaisa',
                    'email': this.props.user?.Email,
                    'name': this.props.user?.Full_Name,
                    'books': Book_ID,
                })
                    .then((res) => {
                        console.log(res)
                        this.setState({ order: res.Order })

                    })
                    .catch((err) => {
                        console.log(err)

                    })
                this.props.orderConfirmationMailToAuthor({

                    'Order_ID': orderRefNumber,
                    //'Book_ID': this.state.book?.Book_ID,
                    'Payment_Method': 'EasyPaisa',
                    'books': Book_ID,                        
                })
                    .then((res) => {
                        console.log(res)
                        this.setState({ order: res.Order })

                    })
                    .catch((err) => {
                        console.log(err)

                    })

                this.props.putBookInLibrary({
                    'books': Book_ID,
                }).then((res) => {
                    if (res.status) {
                        console.log(res)
                        this.setState({ isLoading: false })
                        //this.props.history.push("/library")
                        return
    
                    }
                    else {
                        return
                    }
                })
                    .catch((err) => {
                        console.log(err)

                    })
                console.log(res)
               
            }).catch((err) => {
                console.log(err)

            })
        }else{
            this.setState({
                isOrderCreated: 2,
                //easyPayError: res.responseDesc
            })
            this.props.updateOrder({
                //'Book_ID': Book_ID,
                'Payment_Method': 'Easy_paisa',
                'Payment_Status': 'Failed',
                'Order_ID': orderRefNumber,
                'Reference_No': transactionRefNumber,
            }).then((res) => {
                console.log(res)
                if (res.status) {
                    this.setState({ isLoading: false })

                    console.log(res)

                    return

                }
                else {
                    return
                }
            }).catch((err) => {
                console.log(err)

            })
        }

        // if (!this.props.user?.User_ID)
        //     this.props.history.push("/login")
    }

    componentWillMount() {
        if (this.props != null && this.props.location.state != null && this.props.location.state.book) {


            //console.log(this.props.location.state.book)
            this.setState({
                book: this.props.location.state.book

            })
        }

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }

    onClickPayment = () => {
        console.log('here')
        this.setState({ isLoading: true })
        var Order_ID = Math.floor(Math.random() * 900000) + 100000

        console.log(response)
        if (response.status) {
            this.setState({
                Order: response.content[0],
            })
            if (res.responseCode == "0000") {
                this.setState({
                    isOrderCreated: 1,

                })

                this.props.updateOrder({
                    'Book_ID': this.state.book?.Book_ID,
                    'Payment_Method': 'Easy_paisa',
                    'Payment_Status': 'Cleared',
                    'Order_ID': response.content[0]?.Order?.Order_ID,
                    'Reference_No': res.transactionId,
                }).then((res) => {

                   

                    this.props.putBookInLibrary({
                        'Book_ID': this.state.book?.Book_ID,
                    }).then((res) => {

                    })
                        .catch((err) => {
                            console.log(err)

                        })
                    console.log(res)
                    if (res.status) {
                        console.log(res)
                        this.setState({ isLoading: false })
                        //this.props.history.push("/library")
                        return

                    }
                    else {
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })

                return

            }
            else {
                this.setState({
                    isOrderCreated: 2,
                    easyPayError: res.responseDesc
                })
                this.props.updateOrder({
                    'Book_ID': this.state.book?.Book_ID,
                    'Payment_Method': 'Easy_paisa',
                    'Payment_Status': 'Failed',
                    'Order_ID': response.content[0]?.Order?.Order_ID,
                }).then((res) => {
                    console.log(res)
                    if (res.status) {
                        this.setState({ isLoading: false })

                        console.log(res)

                        return

                    }
                    else {
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })

                return


            }


            return

        }
        else {
            return
        }

    }
    onCardPay = () => {
        const formdata = new FormData()
        formdata.append("storeId", "73507")
        formdata.append("amount", "1")
        formdata.append("postBackURL", "http://localhost:3000/confirmpayment")
        formdata.append("orderRefNum", "1")
        formdata.append("merchantHashedReq", "73507")
        formdata.append("paymentMethod", "CC_PAYMENT_METHOD")




        console.log(formdata)
        this.props.easyPayViaCard(formdata)
    }

    renderOrderRows = () => {

        return this.props?.cart.map((item, i) =>
            <tr>
                <td><p className="primary-color poppins_regular">{this.props?.location?.state?.Order_ID}</p></td>
                <td> <img className="bookTitle " src={item.Image} />
                </td>
                <td><p className="primary-color poppins_regular">{item.Name}</p></td>
                <td><p className="primary-color poppins_regular">{'by '+ item.Author_Name}</p></td>


                <td><button className="tablebtn"  onClick={() => this.onclickBook(item)}>  Read</button></td>
            </tr>
        )
    }

    render() {
        const { t, i18n, location, user } = this.props
        const { isLoading, book } = this.state;


        return (
            <div class="dashboard-cotainer">

                {/* header */}

                { user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {      !user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }
                {/* checkout */}

                <div className="col-12 text-center mb-5 pb-5 mt-5 pt-5 ">
                        <p className="poppins_bold  heading primary-color mb-0">Thank you for purchasing!</p>
                        <p className="poppins_extralight mb-0 text">Little Book Company thanks to you form purchasing book frim our platform</p>
                    </div>
                    <div className="col-11 center mt-5 mb-5 pb-5 pt-5">
                        <div className="table-responsive mt-4 checkout_container">
                            <table className="table table-hover thead-primary tableWidth">
                                <thead>
                                    <tr className="pt-5 mt-5">
                                        <th scope="col table_header poppins_medium">Order ID  </th>

                                        <th scope="col table_header poppins_medium">Book Title  </th>
                                        <th scope="col table_header poppins_medium">Book Name </th>
                                        <th scope="col table_header poppins_medium">Author Name  </th>
                                        <th scope="col table_header poppins_medium"> </th>

                                    </tr>
                                </thead>
                                <tbody>




                                {this.renderOrderRows()}





                                </tbody>

                            </table>

                        </div>
                    </div>
                <Footer></Footer>
            </div>
        )


    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    postOrder,
    updateOrder,
    getEasyPayMbl,
    easyPayViaCard,
    putBookInLibrary,
    orderConfirmationMailToAuthor,
    orderConfirmationMailToUser,
})
ConfirmPayment.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(ConfirmPayment);

