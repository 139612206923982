import { combineReducers } from 'redux';


import { reducer as formReducer } from 'redux-form'
import auth from './auth';
import cart from './cart'
import books from './books';

export default combineReducers({
    auth: auth,
    form: formReducer,
    cart: cart,
    books: books
})
