import React from 'react'
import logo from '../assets/images/header/logo.svg'

function UHeader() {
    return (
        <nav className="navbar navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="" width="192px" height="84px" className="d-inline-block align-text-top" />
                </a>
            </div>
        </nav>
    )
}

export default UHeader