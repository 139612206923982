import { GET_ALL_BOOKS } from '../actions/type';


const initialState = {
    books: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_BOOKS:
            // console.log('PAYLOAD', action.payload)
            return {
                ...state,
                books: action.payload
            }
        default:
            return state;
    }
}

