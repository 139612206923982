import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import Book from '../../assets/images/books/sadequainbook.svg'
import star from '../../assets/images/star.svg'
import paypalImg from '../../assets/images/paypal.svg'
import ForeeImg from '../../assets/images/Foree.png'
import JazzCashLogo from '../../assets/images/JaazCashLogo.png'
import PaypalLogo from '../../assets/images/Checkout/paypal.png'
import EasyPaisaLogo from '../../assets/images/Checkout/easypaisa.png'
import HBLLogo from '../../assets/images/Checkout/HBL.png'
import DropdownIcon from '../../assets/images/Checkout/dropdown.png'


import easypaisaImg from '../../assets/images/publisher/easypaisa.svg'

import table_bookimg from '../../assets/images/table_bookimg.svg'
import UserHeader from '../../component/UserHeader'

import HBlLogo from '../../assets/images/HBlLogo.png'



import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { getEasyPay, getCountryFromIp } from '../../store/actions/bookAction'
import { initiateCheckout, getForeeCheckoutURL } from 'foree-checkout';
import PayWithPayPal from './PayWithPayPal'
import { postOrder, updateOrder, putBookInLibrary } from '../../store/actions/orderAction'
import { removeFromCart } from '../../store/actions/cartAction';
import moment from 'moment';
// import Select from 'react-select';


var sum = 0
class OrderStatus extends Component {



    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,


        };
    }
    componentDidMount() {

    }


    onclickBook = (book) => {
        this.props.history.push('/preview', { libbook: book })
    }

    renderOrderRows = () => {

        return this.props?.cart.map((item, i) =>
            <tr>
                <td><p className="primary-color poppins_regular">{this.props?.location?.state?.Order_ID}</p></td>
                <td> <img className="bookTitle " src={item.Image} />
                </td>
                <td><p className="primary-color poppins_regular">{item.Name}</p></td>
                <td><p className="primary-color poppins_regular">{'by '+ item.Author_Name}</p></td>


                <td><button className="tablebtn"  onClick={() => this.onclickBook(item)}>  Read</button></td>
            </tr>
        )
    }


    render() {
        console.log("CART :", this.props.cart)
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading, } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }

        return (
            <>
                <div className="OrderStatusContainer">
                    {user.Full_Name && <UserHeader
                        history={this.props.history}
                    > </UserHeader>}
                    {!user.Full_Name &&
                        <Header
                            history={this.props.history}

                        ></Header>
                    }
                    <div className="col-12 text-center mb-5 pb-5 mt-5 pt-5 ">
                        <p className="poppins_bold  heading primary-color mb-0">Thank you for purchasing!</p>
                        <p className="poppins_extralight mb-0 text">Little Book Company thanks to you form purchasing book frim our platform</p>
                    </div>
                    <div className="col-11 center mt-5 mb-5 pb-5 pt-5">
                        <div className="table-responsive mt-4 checkout_container">
                            <table className="table table-hover thead-primary tableWidth">
                                <thead>
                                    <tr className="pt-5 mt-5">
                                        <th scope="col table_header poppins_medium">Order ID  </th>

                                        <th scope="col table_header poppins_medium">Book Title  </th>
                                        <th scope="col table_header poppins_medium">Book Name </th>
                                        <th scope="col table_header poppins_medium">Author Name  </th>
                                        <th scope="col table_header poppins_medium"> </th>

                                    </tr>
                                </thead>
                                <tbody>




                                {this.renderOrderRows()}





                                </tbody>

                            </table>

                        </div>
                    </div>

                    <Footer></Footer>


                </div>
            </>
        )
    }
}
const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cart: cart.cart
})
const mapDispatchToProps = ({
    getEasyPay,
    postOrder,
    updateOrder,
    putBookInLibrary,
    getCountryFromIp,
    removeFromCart
})
OrderStatus.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(OrderStatus);

