import React, { useEffect, useState } from 'react';
import {
    Route,
    Link
} from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllBooks } from '../../store/actions/bookAction'

// Import ting About Book Page
import AboutBook from '../Book/AboutBook';


function BookRoutes({books}) {

    return (
        <>
            {
                books.map((item,index)=>{
                    let replaced = item.Name.replace(/[ &\/\\#,+()$~%.'":*?<>{}’]/g, '');
                    replaced = replaced.replace(/\s/g, ''); // Removes all whitespace characters
                    // console.log(replaced)
                    return <Route
                        exact
                        path={`/${replaced}`}
                        component={AboutBook}
                    />
                })
            }
        </>
    )
}

export default BookRoutes