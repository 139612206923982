import axios from 'axios';
import { GET_ERRORS } from './type';


export const getCurrentDollarRate = (data) => dispatch => {
  return axios.get("https://api.getgeoapi.com/v2/currency/convert?",{data})
    .then(res=>{
      console.log(res.data)
      return Promise.resolve(res.data)
    })
    .catch(err=>{
      console.log(err)
      return Promise.reject(err)
    })
}

export const putBookInLibrary = accountData => dispatch => {
  return axios
    .put('api/library/book', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};

export const orderConfirmationMailToUser = accountData => dispatch => {
  return axios
    .post('api/ordermail', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};

export const orderConfirmationMailToAuthor = accountData => dispatch => {
  return axios
    .post('api/ordermailtoauthor', accountData)
    .then(res => {
      return Promise.resolve(res.data)
    })
    .catch(err => {

      if (err.response.data != null && err.response.data.validation) {
        console.log(err.response.data);
        err = err.response.data
      } else {
        err = { "msg": "Something went wrong" }
      }
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
      return Promise.reject(err)
    });
};

export const postOrder = accountData => dispatch => {
    return axios
      .post('api/order', accountData)
      .then(res => {
        return Promise.resolve(res.data)
      })
      .catch(err => {
  
        if (err.response.data != null && err.response.data.validation) {
          console.log(err.response.data);
          err = err.response.data
        } else {
          err = { "msg": "Something went wrong" }
        }
        dispatch({
          type: GET_ERRORS,
          payload: err
        })
        return Promise.reject(err)
      });
  };

  export const easyPayViaCard = accountData => dispatch => {
    return axios
      .post('https://easypay.easypaisa.com.pk/easypay/Index.jsf', {"dasds":"adsa"} ,{
        headers: {
            'content-type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
        }
    })
      .then(res => {
        console.log(res)
        
      })
      .catch(err => {
  
   console.log(err)
        
      });
  };

  export const updateOrder = accountData => dispatch => {
    return axios
      .put('api/order', accountData)
      .then(res => {
        console.log(res)
        return Promise.resolve(res.data)
      })
      .catch(err => {
        console.log(err)
        if (err.response.data != null && err.response.data.validation) {
          console.log(err.response.data);
          err = err.response.data
        } else {
          err = { "msg": "Something went wrong" }
        }
        dispatch({
          type: GET_ERRORS,
          payload: err
        })
        return Promise.reject(err)
      });
  };

  export const getEasyPayMbl = accountData => dispatch => {
    return axios
      .post('https://easypay.easypaisa.com.pk/easypay-service/rest/v4/initiate-ma-transaction', accountData, {headers: {'Credentials':'TGl0dGxlQm9va0NvbXBhbnk6YjllYmY2NDFiMmRmYTY3YTFiOWNkNDFkMjFjMGI5NzQ='}})
      .then(res => {
        console.log(res)
        return Promise.resolve(res.data)
      })

      .catch(err => {
  
        // if (err.response.data != null && err.response.data.validation) {
        //   console.log(err.response.data);
        //   err = err.response.data
        // } else {
        //   err = { "msg": "Something went wrong" }
        // }
        // dispatch({
        //   type: GET_ERRORS,
        //   payload: err
        // })
        console.log(err)
        return Promise.reject(err)
      });
  };