import React, { Component } from 'react';
import { connect } from 'react-redux';
import banner from '../../assets/images/Artboard.svg'
import Header from '../../component/Header'
import Footer from '../../component/Footer'
import UserHeader from '../../component/UserHeader'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link, withRouter } from 'react-router-dom';
var cx = require('classnames');

class Dashboard extends Component {
   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         bottomNav: {
            publisher: true,
            reader: false,
            author: false,
            publishBook: false,
            bookShop: false,
            storiesWork: false,
            commuinityIdeas: false,
         },
         activeTab: 1
      };
   }
   componentDidMount() {
   }
   onClickBottomBar = (val) => {
      this.setState({
         activeTab: val
      })
   }
   render() {
      // const { t, i18n } = this.props
      const { t, i18n, location,user } = this.props
      const { isLoading } = this.state;
      if (isLoading) {
         return (
            <div className="loader-large"></div>
         )
      }
      return (
         <div class="dashboard-cotainer">

            {/* header */}

            { user.Full_Name && <UserHeader
                history={this.props.history}
                > </UserHeader> }
                 {      !user.Full_Name &&
                     <Header
                     history={this.props.history}
                     
                     ></Header>
                }


            {/* banner */}
            <div className="  ">

               <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  ">
                  <img className="img-w-100" src={banner}/>


               </div>
               <div className="mlmr25">
                  <div className="row">
                     <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  pl-5 pr-5">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                           <p className="poppins_semibold aboutus_tag">Dear Readers, Writers, Publishers and Booksellers,</p>

                           <p className="poppins_semibold  aboutus_heading">Welcome to The Little Book Company!</p>
                           <p className="poppins_light aboutus_tag">The Little Book Company is a digital platform for bookselling and publishing for you.  </p>
                           <p className="poppins_light aboutus_tag">We are a platform for publishers and booksellers; we are a bookshop for readers, and we are a publishing house for new e-books and on demand paperback editions. The Little Book Company creates and curates your e-books in many different formats from e-books, to audio, video to paper-back.</p>
                           <p className="poppins_light aboutus_tag">Our objective is to benefit everyone involved with The Little Book Company. Our goal is to be profitable for writers, book sellers and publishers. </p>
                           <p className="poppins_light aboutus_tag">Our approach is to treat each relationship with an attitude of sharing and support. Our vision is to make book writing and book selling accessible, affordable and profitable for writers, booksellers, bookshops and publishers and affordable for readers </p>
                           <p className="poppins_light aboutus_tag">We believe that the present and future of books is a combination of digital technology and physical books and bookshops. </p>
                           <p className="poppins_light aboutus_tag">We will enhance the experience of books and storytelling by expanding access to books at an affordable price.</p>
                           <p className="poppins_light aboutus_tag">The Little Book Company’s digital platform supplements and complements bookshops by increasing their reach to a larger number of customers.</p>
                           <p className="poppins_light aboutus_tag">We work with:</p>





                        </div>
                     </div>
                  

                  </div>
               </div>
               <div className="mlmr25">

                  <div className="row">
                     <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5  ">
                        <div className="aboutus_btn_slider">
                           <div className="row ml-0 mr-0">

                              <button className={cx({ "aboutus_btn_slider_btn": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 1 })} onClick={() => this.onClickBottomBar(1)}>Publishers</button>
                              <button className={cx({ "aboutus_btn_slider_btn": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 2 })} onClick={() => this.onClickBottomBar(2)}>Readers</button>
                              <button className={cx({ "aboutus_btn_slider_btn": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 3 })} onClick={() => this.onClickBottomBar(3)}>Authors</button>
                              <button className={cx({ "aboutus_btn_slider_btn": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 4 })} onClick={() => this.onClickBottomBar(4)}>Publish Books</button>
                              <button className={cx({ "aboutus_btn_slider_btn": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 5 })} onClick={() => this.onClickBottomBar(5)}>BookShops</button>
                              <button className={cx({ "aboutus_btn_slider_btn aboutus_btn_slider_btn_bigs": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 6 })} onClick={() => this.onClickBottomBar(6)}>Stories Work Collective</button>
                              {/* <button className={cx({ "aboutus_btn_slider_btn aboutus_btn_slider_btn_bigs": true, "aboutus_btn_slider_btn_active": this.state.activeTab === 7 })} onClick={() => this.onClickBottomBar(7)}>community Ideas</button> */}


                           </div>

                        </div>

                     </div>


                  </div>
               </div>


               <div className="row">
                  <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12  mt-5 pl-5 pr-5 mb-5 ">
                   
                     {this.state.activeTab === 1 ? (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Publishers</p>
                           <p className="poppins_light  aboutus_tag">We provide a platform for publishers to showcase their books and sell them as e-books. The delivery of e-books is the responsibility of The Little Book Company. The delivery of publishers’ paperback and hardback copies is the responsibility of each Publisher. All  sales and payments are made on this platform. Publishers are able to sell their books online on our platform and receive direct online payments. Payment transfers from the Little Book Company to the Publisher for paperbacks and hardcopies will be made after delivery of the book (s) has been received by the customer. Publishers on our platform have access to their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.  Payments are triggered on the basis of volume of sales and/or monthly.</p>
                           <div className="text-center">
                              <Link to="/publisher">

                                 <button className="text-center moreDetail_btn">More details</button>
                              </Link>
                           </div>
                        </div>
                     ) : this.state.activeTab === 2 ? (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Reader</p>
                           <p className="poppins_light  aboutus_tag">We match readers to books and booksellers. We connect the readers to the booksellers and publish-ers and writers. We provide readers with a wide range of instantly accessible literature at low cost through e-books and on demand paperback copies of books </p>
                           <div className="text-center">
                              <Link to="/category">

                                 <button className="text-center moreDetail_btn">More details</button>
                              </Link>
                           </div>
                        </div>
                     ) : this.state.activeTab === 3 ? (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Author</p>
                           <p className="poppins_light  aboutus_tag">We match writers to their readers. We provide authors the opportunity to be able to publish their work and receive payments from their book sales through online payments.  Authors are able to sell their e-books and paperbacks on demand online on our platform and receive direct online pay-ments. Authors on our platform have access to their own individual account dashboards which al-lows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.  Payments to authors from the Little Book Company are triggered on the basis of volume of sales and/or monthly</p>
                           <div className="text-center">
                              <Link to="/author">

                                 <button className="text-center moreDetail_btn">More details</button>
                              </Link>
                           </div>
                        </div>
                     ) : this.state.activeTab === 4 ? (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Publish Book</p>
                           <p className="poppins_light  aboutus_tag">We provide a platform for publishers to showcase their books and sell them as e-books. The delivery of e-books is the responsibility of The Little Book Company. The delivery of publishers’ paperback and hardback copies is the responsibility of each Publisher. All  sales and payments are made on this platform. Publishers are able to sell their books online on our platform and receive direct online payments. Payment transfers from the Little Book Company to the Publisher for paperbacks and hardcopies will be made after delivery of the book (s) has been received by the customer. Publishers on our platform have access to their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.  Payments are triggered on the basis of volume of sales and/or monthly.</p>
                           <div className="text-center">
                              <button className="text-center moreDetail_btn">More details</button>
                           </div>
                        </div>
                     ) : this.state.activeTab === 5 ? (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Book Shop</p>
                           <p className="poppins_light  aboutus_tag">We also place paper back copies of books published by us on demand in bookshops.  Booksellers are able to sell their books online on our platform and receive direct online payments. Booksellers on our platform have access to their own individual account dashboard which allows them to moni-tor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.  Payments are triggered on the basis of volume of sales and/or monthly. </p>
                           <div className="text-center">
                              <Link to="/findAStore">

                                 <button className="text-center moreDetail_btn">More details</button>
                              </Link>
                           </div>
                        </div>
                     ) : this.state.activeTab === 6 ? (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Stories Work Collective</p>
                           <p className="poppins_light  aboutus_tag">We believe in self help and collectives. We will make cash transfers to anyone in need of income and livelihood support who is able to send in a story they have written or recorded on audio or vid-eo. We will edit, market and post their stories at The Little Book Company website. We will set a minimal price for our readers to purchase the story to read. Once the total earnings from the story reach Rs.1000 we will make an e-paisa transfer to the storyteller. Each storyteller will be able to monitor the sales and payment transfers to them as a result of their story </p>
                           <div className="text-center">
                              <Link to="/storyworkcollection">

                                 <button className="text-center moreDetail_btn">More details</button>
                              </Link> 
                           </div>
                        </div>
                     ) : this.state.activeTab === 7 && (
                        <div className="center_para">
                           <p className="poppins_semibold  aboutus_heading">Community Ideas</p>
                           <p className="poppins_light  aboutus_tag">We provide a platform for publishers to showcase their books and sell them as e-books. The delivery of e-books is the responsibility of The Little Book Company. The delivery of publishers’ paperback and hardback copies is the responsibility of each Publisher. All  sales and payments are made on this platform. Publishers are able to sell their books online on our platform and receive direct online payments. Payment transfers from the Little Book Company to the Publisher for paperbacks and hardcopies will be made after delivery of the book (s) has been received by the customer. Publishers on our platform have access to their own individual account dashboard which allows them to monitor their book sales and the payment transfers to them from The Little Book Company for their sales earnings.  Payments are triggered on the basis of volume of sales and/or monthly.</p>
                           <div className="text-center">
                              <button className="text-center moreDetail_btn">More details</button>
                           </div>
                        </div>
                     )
                     }

                  </div>


               </div>

            </div>

            <Footer></Footer>
         </div>
      )
   }

}
const mapStatetoProps = ({ auth }) => ({
   user: auth.user
})
const mapDispatchToProps = ({
})
Dashboard.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Dashboard);

