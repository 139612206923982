import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import cross from '../assets/images/x.svg'
import logo from '../assets/images/header/logo.svg'
import hamburger from '../assets/images/hamburger.svg'
import youtube from '../assets/images/socialmedia/youtube.svg'
import facebook from '../assets/images/socialmedia/facebook.svg'
import linkedin from '../assets/images/socialmedia/linkedin.svg'
import instagram from '../assets/images/socialmedia/instagram.svg'
import twitter from '../assets/images/socialmedia/twitter.svg'
import filter from '../assets/images/filter.svg'
import searchicon from '../assets/images/search_icon.svg'
import dropdown from '../assets/images/dropdown.svg'
import { Link, withRouter } from 'react-router-dom';
import { searchBook } from '../store/actions/bookAction'
import { logoutUser } from '../store/actions/authAction'
import LibLogo from '../assets/images/LibLogo.png'
import shoppingCart from '../assets/images/header/cart.png'
import { setAmplitudeUserId, setAmplitudeUserProperties } from '../utils/amplitude';
import filterIcon from '../assets/images/header/filter.png'
import locationImg from '../assets/images/header/location.png'
import { getCountryFromIp } from '../store/actions/bookAction'



var cx = require('classnames');
const tabs = [
    { key: 'Library', loc: '/library' },
    { key: 'Explore', loc: '/' },
    { key: 'Stories Work Collective', loc: '/storyworkcollection' },
    { key: 'Audio Books', loc: '/unavailable' },
    { key: 'Our Publications', loc: '/ourpublication' },
    { key: 'Print on Demand', loc: '/printondemand' },
    { key: 'About Us', loc: '/aboutus' }
]

class UserHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            showModal: false,
            searchName: "",
            adBar: true,
            Country: "",
            hideTabs: false

        };
    }
    componentDidMount() {
        if (this.props.user.User_ID) {
            setAmplitudeUserId(this.props.user.User_ID)
            setAmplitudeUserProperties({ name: this.props.user.Full_Name })
        }
    }

    componentWillMount() {
        this.props.getCountryFromIp().then((res) => {
            console.log(res)
            this.setState({
                isPakistan: true,
                Country: res.country,
            })
        }).catch((err) => {
            console.log(err)

        })
    }
    closeAdBar = () => {
        this.setState({
            adBar: !this.state.adBar
        })
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }
    onClickLibrary = () => {
        console.log(this.props.user)
        this.props.history.push("/library",)
    }
    onKeyDownHandler = e => {
        if (e.keyCode === 13) {
            this.onClickSearch();
        }
    };
    onClickSearch = () => {
        this.props.searchBook(this.state.searchName).then((res) => {
            console.log(res.content)
            if (res.status == true) {
                if (window.location.pathname === '/category') {
                    this.props?.onSearch({ books: res.content, SearchBy: this.state.searchName })
                }
                this.props.history.push('/category', { books: res.content, SearchBy: this.state.searchName })

            }
            else {
                alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }


    render() {

        return (
            <div class="dashboard-cotainer">
                <div className="beforecolpasemain">
                    <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                        <div className="row m-0">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12  pl-5 pr-5  text-center">
                                <Link to="/">
                                    <img className="logo_img" src={logo}></img>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 vertical_center text-center ">
                                <input className="col-xl-12  dashboard_header_input poppins_light" placeholder="Search best book 2022" name="searchName" onChange={this.onChange} onKeyDown={this.onKeyDownHandler}></input>
                                <img className="searchicon" src={searchicon} onClick={() => this.onClickSearch()}></img>
                                <div className='header_input_verticalLine'></div>
                                <img className='filterIcon' src={filterIcon} />
                            </div>
                            <div className='col-xl-3 col-lg-3 col-md-3 col-12 col-12 vertical_center country_div'>
                                <div className='row'>
                                    <div className='col-8 my-auto'>
                                        <p className='mb-0 countryName'><img className='mr-3' src={locationImg} />{this.state.Country}</p>

                                    </div>
                                    <div className='col-3 my-auto'>
                                        <div className="vertical_center ">
                                            <img className="shoppingCartImg" onClick={() => this.props.history.push('/checkout')} src={shoppingCart} />
                                            <p className="shoppingCartImgCount">{this.props.cartCount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2  col-lg-2 col-md-2 col-sm-12 col-12  vertical_center logoutDrop">
                                <div className="row login_div ml-0">
                                    <div class="dropdown">
                                        <button class="dropbtn poppins-medium">{this.props.user.Full_Name}</button>
                                        <div class="dropdown-content">
                                            <p className="poppins_light Hov m-0 p-3" onClick={() => this.onClickLibrary()}> <img className="ml-3 mr-3" src={LibLogo} />My Bookshelf</p>
                                        </div>
                                        <Link to="/">
                                            <button className="headerLoginBtn" onClick={() => this.props.logoutUser()}>Logout</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <img
                                class="navbar-toggle tooglebtnn mr-5"
                                data-toggle="collapse"
                                data-target="#myNavbar" src={hamburger} />

                        </div>
                    </div>
                    {/* tabs */}
                    <div className="HeaderTabsContain" id='myNavbar'>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mb-3">
                            <div className="dashboard_header_tabs ">
                                <div className="dashboard_link">
                                    <div className="row">
                                        {
                                            tabs.map((el, idx) => {
                                                return <div className="header_tabss_small text-center">
                                                    <Link to={el.loc}>
                                                        <button class={cx({ "nav-poppins_light header_tabss_btn ": true, "header_tabss_btn_active": location.pathname === el.loc })}>{el.key}</button>
                                                    </Link>
                                                </div>
                                            })
                                        }
                                        <div className="responsivelogout header_tabss_small text-center">
                                            <Link to="/">
                                                <button class={cx({ "nav-poppins_light header_tabss_btn ": true})} onClick={() => this.props.logoutUser()}>Logout</button>
                                            </Link>
                                        </div>
                                        <div className="responsive nav_hr"></div>
                                        <div className="responsive text-center mt-3 pt-3 social-logos">

                                            <img className="pl-3 pr-3" src={youtube}></img>
                                            <img className="pl-3 pr-3" src={instagram}></img>
                                            <img className="pl-3 pr-3" src={twitter}></img>
                                            <img className="pl-3 pr-3" src={linkedin}></img>
                                            <img className="pl-3 pr-3" src={facebook}></img> <br></br>
                                            <label className="poppins_regular mt-2  navbarcopyright">2020 The Little Book Company  All rights reserved.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}
const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cartCount: cart.cart.length
})
const mapDispatchToProps = ({
    searchBook,
    logoutUser,
    getCountryFromIp
})
UserHeader.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(UserHeader);

