import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../component/Header'
import Noty from 'noty';
import UserHeader from '../../component/UserHeader'

import { Link, withRouter } from 'react-router-dom';
import { sendOtpMbl, registerReader, getAllCountries } from '../../store/actions/authAction'
import { sendAmplitudeData } from '../../utils/amplitude';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Footer from '../../component/Footer'
import OtpInput from 'react-otp-input';
import readingbook from '../../assets/images/footer/readingbook.png'
class Register1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            serverError: {},
            isLoading: false,
            email: "",
            password: "",
            password2: "",
            full_name: "",
            date_of_birth: "",
            phone_num: "",
            otp: "",
            countryList: [],
            country: "",
            verifiactionSend: false,
            isEmaiSend: false,
            value: "",
            otpp: "",
            isCodeSending: false,

        };
    }
    componentDidMount() {
        sendAmplitudeData("VisitedRegisterPage")

        this.props.getAllCountries().then((res) => {
            console.log(res)
            this.setState({
                countryList: res.result,
            })

        }).catch((err) => {
            console.log(err)

        })
    }
    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSendCode = (e) => {
        e.preventDefault()
        this.setState({ isCodeSending: true })
        this.props.sendOtpMbl({
            // "To_Number": this.state.phone_num,
            "Email": this.state.email,
            "Full_Name": this.state.full_name,
            "Date_Of_Birth": "14-10-1996",
            "Password": this.state.password,
            "Password2": this.state.password2,
            "Country": this.state.country,
            "To_Number": this.state.phone_num,



        }).then((res) => {
            this.setState({ isLoading: false, verifiactionSend: true, isEmaiSend: true, isCodeSending: false })
            console.log(res)
            if (res.status) {
                // this.props.history.push('/category')
                return
            } else {
                return
            }
            // this.setState({ isLoading: false, isCodeSending:false })

        }).catch((err) => {
            this.setState({ isLoading: false, isCodeSending: false })
            console.log(err)
            var validationError = {}
            var serverError = []
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    if (obj.hasOwnProperty('param')) {
                        validationError[obj["param"]] = obj["msg"]
                    } else {
                        serverError = [...serverError, obj]
                    }
                });
                this.setState({ errors: validationError });
                this.setState({ serverError: serverError });
            } else {
                this.setState({ serverError: [{ "msg": "server not responding" }] })
            }
        })
    }


    onRegister = (e) => {
        e.preventDefault()
        this.props.registerReader({
            "Email": this.state.email,
            "Full_Name": this.state.full_name,
            "Date_Of_Birth": "14-10-1996",
            "Password": this.state.password,
            "Password2": this.state.password2,
            "Otp": this.state.otpp,
            "Country": this.state.country,
            "Phone_Num": this.state.phone_num,

        }).then((res) => {
            this.setState({ isLoading: false })
            console.log(res)
            if (res.status) {
                this.props.history.push('/category')
                new Noty({
                    text: "Succsessfully Register",

                    type: "success",

                    timeout: 2000
                }).show()
                    .then(() => { }
                    )
            } else {
                new Noty({
                    text: "Something went wrong",

                    type: "error",
                    timeout: 2000
                }).show();
            }
        }).catch((err) => {
            this.setState({ isLoading: false })
            console.log(err)
            var validationError = {}
            var serverError = []
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    if (obj.hasOwnProperty('param')) {
                        validationError[obj["param"]] = obj["msg"]
                    } else {
                        serverError = [...serverError, obj]
                    }
                });
                this.setState({ errors: validationError });
                this.setState({ serverError: serverError });
            } else {
                this.setState({ serverError: [{ "msg": "server not responding" }] })
            }
        })
    }

    _handleKeyDownCountry = (e) => {
        console.log("here country", [e.target.value])
        if (e.target.value == 0) {
            return
        }

    }
    renderServerError() {
        if (this.state.serverError != null && this.state.serverError.length > 0) {
            return (

                <div className=" servererror form-group alert alert-danger" role="alert" >
                    <strong className="pr-2 ohsnaptext">  {"  "}</strong>
                    <label className="servererrorText">
                        {this.state.serverError[0].msg}
                    </label>
                </div>
            )
        }
    }

    selectCountry(val) {
        this.setState({ country: val });
    }

    setMblNum = (e) => {
        this.setState({ phone_num: e });

    }

    setOtpp = (e) => {
        console.log(e)
        this.setState({ otpp: e });

    }
    // handleChange = (otp) => this.setState({ otp });


    render() {
        const { isLoading } = this.state;
        const { errors } = this.state
        const { t, i18n, location, user } = this.props



        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (

            <div class="dashboard-cotainer">
                {/* header */}
                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }
                {/* about book */}
                <div className=" Signup-container ">
                    <div className="row">

                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-5 pr-5 ">

                            {/* <div className="sing-horizontal-row col-xl-8"></div> */}

                        </div>
                        <div className='row'>

                        </div>
                        <div className='col-md-2'></div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="Login_internal_container">


                                    <div className="col-md-12 col-sm-12 col-12  pl-5 pr-5 mb-5">

                                        <p className="poppins_bold sign_heading primary-color mb-0">Sign Up</p>
                                        <p className="poppins_extra_light sign_text primary-color mb-0">Join us now! and start reading your favorite book from today! </p>

                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12  pl-5 pr-5 mt-3">

                                        {this.renderServerError()}
                                    </div>

                                    <div className="col-md-6 col-sm-12 col-12  pl-3 pr-3 mb-5">
                                        <p className="  sign-form-text poppins_regular mt-3">Full Name</p>
                                        <input className="sign-input col-xl-12" name="full_name" onChange={this.onChange}></input>
                                        {errors.Full_Name && <div className="invaliderror">{errors.Full_Name}</div>}

                                        <p className="  sign-form-text poppins_regular mt-3">Country </p>
                                        {/* <select className="sign-input col-xl-12" name="cars" id="cars">
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select> */}
                                        {/* <select class="sign-input col-xl-12" name="country" disabled={this.state.full_name ? false : true} onClick={this._handleKeyDownCountry} onChange={this.onChange} onKeyUp={this._handleKeyDownCountry}  >
                                <option value={-1} disable selected={!this.state.country}  >--Select Country--</option>

                                {this.state.countryList.length > 0 && this.state.countryList.map((item, index) =>
                                    <option value={index} selected={item.name && this.state.country == item.name}>{item.name}</option>

                                )}
                            </select> */}
                                        <CountryDropdown
                                            disabled={this.state.full_name ? false : true}
                                            classes='sign-input col-xl-12'
                                            value={this.state.country}
                                            onChange={(val) => this.selectCountry(val)} />


                                        {/* <input className="sign-input col-xl-12" name="phone_num" onChange={this.onChange}></input> */}
                                        {errors.Country && <div className=" invaliderror">{errors.Country}</div>}

                                        <p className="  sign-form-text poppins_regular mt-3">Password</p>
                                        <input className="sign-input col-xl-12" type="password" name="password" onChange={this.onChange} disabled={this.state.country ? false : true}></input>
                                        {errors.Password && <div className=" invaliderror">{errors.Password}</div>}

                                        <p className="  sign-form-text poppins_regular mt-3">Re-Enter Password</p>
                                        <input className="sign-input col-xl-12" type="password" name="password2" onChange={this.onChange} disabled={this.state.password ? false : true}></input>
                                        {errors.Password2 && <div className=" invaliderror">{errors.Password2}</div>}

                                        {/* <p className="  sign-form-text poppins_regular mt-3">Date of Birth</p>
                            <input className="sign-input col-xl-6" name="date_of_birth" onChange={this.onChange}></input>
                            {errors.Date_Of_Birth && <div className=" invaliderror">{errors.Date_Of_Birth}</div>} */}

                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 pl-3 pr-3  mb-5">
                                        <p className="  sign-form-text poppins_regular mt-3" >Email Address</p>
                                        <input className="sign-input col-xl-12 " name="email" onChange={this.onChange} disabled={this.state.password2 ? false : true}></input>
                                        {errors.Email && <div className="invaliderror">{errors.Email}</div>}

                                        <p className="  sign-form-text poppins_regular mt-3" >Enter Phone Number</p>

                                        <button className="getCodeBtn" onClick={(e) => this.onSendCode(e)}> {this.state.isCodeSending && <i class="fa fa-refresh fa-spin"></i>} Get SMS Code</button>

                                        <PhoneInput
                                            international
                                            defaultCountry="PK"


                                            className="sign-input mt-3"
                                            value={this.state.phone_num}
                                            onChange={e => this.setMblNum(e)} />
                                    
                                        {this.state.isEmaiSend && <p className="emailsenttext">Check email and/or SMS for code.</p>}

                                        <p className=" sign-form-text poppins_regular mt-3">Enter Verification Code</p>

                                        <OtpInput
                                            value={this.state.otpp}
                                            onChange={e => this.setOtpp(e)}
                                            numInputs={4}
                                            inputStyle="otpInput"
                                            containerStyle={false}
                                            separator={<span></span>}
                                        />



                                        {errors.Otp && <div className=" invalidveifcationerror">{errors.Otp}</div>}

                                        <div className="signin_container mt-4">
                                            <div class="form-group mt-2 mb-2">
                                                <input className="mt_4px" type="checkbox" id="html" />
                                                <label className="checkbox_label poppins_light mt_4px" for="html">I accept the <b className="poppins_bold">Terms and Condition</b> by Little Book Publishers</label>
                                            </div>
                                        </div>
                                        {this.state.otpp === "" && <button className="sign_reading_btn poppins_bold  col-xl-12 mt-1" disabled="true" onClick={this.onRegister}> <img className="ml-3 mr-3" src={readingbook} />Start Reading</button>}
                                        {this.state.otpp !== "" && <button className="sign_reading_btn poppins_bold  col-xl-12 mt-1" onClick={this.onRegister}><img className="ml-3 mr-3" src={readingbook} />Start Reading</button>}

                                    </div>
                                </div>
                            </div>


                        </div>

                        {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 pl-5 pr-5  mb-5 text-center">
                            <div className="register-card1 publshercard mt-3 mb-3" >
                                <label className="poppins_semibold signdiscount-img-heading">Are you an Publisher?</label><br></br>
                                <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                            </div>
                            <div className="register-card1 authorcardd mt-3 mb-3" >
                                <label className="poppins_semibold signdiscount-img-heading">Are you an Author?</label><br></br>
                                <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                            </div>
                            <div className="register-card1 bookstorecard mt-3 mb-3" >
                                <label className="poppins_semibold signdiscount-img-heading">Are you an BookStore?</label><br></br>
                                <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                            </div>
                            <div className="register-card1 storycardd mt-3 mb-3" >
                                <label className="poppins_semibold signdiscount-img-heading">Story Work Collective</label><br></br>
                                <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    sendOtpMbl,
    registerReader,
    getAllCountries
})
Register1.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Register1);

