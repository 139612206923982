import React, { useState, useEffect } from 'react'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Header from '../../component/Header';
import UserHeader from '../../component/UserHeader';
import CheckoutForm from './CheckoutForm';
import Footer from '../../component/Footer';

import { getCountryFromIp } from '../../store/actions/bookAction'
import { postOrder, updateOrder, putBookInLibrary, orderConfirmationMailToUser, orderConfirmationMailToAuthor, getCurrentDollarRate } from '../../store/actions/orderAction'


import stripeImage from '../../assets/images/Checkout/stripe.png';
import backBtn from '../../assets/images/back_btn.svg';
import CongratulationImage from '../../assets/images/Modal/congratulations.svg';


import '../../assets/styles/modal.css';

const stripe_Live = 'pk_live_51JZYy3HlUXQRtIaS5VkbuRxk5qaDdxOmFef5Ij6bmkwxFTZxow8ucnWrPOgNVBhuAnOC6Ab4qy2IskBniMUzUXDs00qLP6P4Ld';
const stripe_Test = 'pk_test_51JZYy3HlUXQRtIaSmoUf7WbsQBsSm9ScF17obbcxn7vXYW9VMEMKk8TepMlZsmURqAb3JX6wZol29BdQr7PUb5Ex00KOs2PRwF';

function CheckoutStripe(props) {
    const [user, setUser] = useState({});
    const [cart, setCart] = useState([]);
    const [sum, setSum] = useState(0);
    const [orderID, setOrderID] = useState(0);
    const [cartIsEmpty, setCartIsEmpty] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [description, setDescription] = useState([]);
    const [descriptionString, setDescriptionString] = useState('');
    const [isPakistan, setIsPakistan] = useState(true);


    const history = useHistory();

    useEffect(() => {


        props.getCountryFromIp()
            .then(res => {
                console.log(res)
                if (res.country !== 'Pakistan') {
                    setIsPakistan(false)
                } else {
                    setIsPakistan(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        // console.log(props.user)

        setUser(props.user)
        setCart(props.cart)
        setSum(props.location.state.sum)


        window.scrollTo(0, 0);
    }, [])


    useEffect(() => {
        console.log('Cart updated', cart)
        cart.forEach((obj) => description.push(obj.Name));
        setDescriptionString(description.toString())
    }, [cart])

    return (
        <>
            <div className='dashboard-cotainer'>
                {
                    user.Full_Name &&
                    <UserHeader></UserHeader>
                }
                {
                    !user.Full_Name &&
                    <Header></Header>
                }
                <div className="  ">
                    <div className="col-xl-12">
                        <div className='row'>
                            <div className='col-1 back__btnHolder'>
                                <img
                                    src={backBtn}
                                    width='40%'
                                    className=''
                                    onClick={() => history.push('/checkout', {})}
                                />
                            </div>
                            <div className='col-8 p-0'>
                                <div className="pl-3 pr-3  ml-4 mr-4 ">
                                    <p className="poppins_bold checkout_heading primary-color mt-5 mb-0">Payment Details</p>
                                    <hr className='divider__paragraph' />
                                    <p className="poppins_light ">Enter your card details in order to complete the purchase</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 visible pl-5 pr-5">
                        <div className="row">
                            <div className='col-1'></div>
                            <div className="col-md-7 p-1">
                                <div className="table-responsive mt-4 checkout_container">
                                    <StripeProvider apiKey='pk_live_51JZYy3HlUXQRtIaS5VkbuRxk5qaDdxOmFef5Ij6bmkwxFTZxow8ucnWrPOgNVBhuAnOC6Ab4qy2IskBniMUzUXDs00qLP6P4Ld'>
                                        <Elements>
                                            <CheckoutForm
                                                Amount={sum * 100}
                                                Amount_without_stripe={sum}
                                                User={user}
                                                Description={descriptionString}
                                                Books={cart}
                                                putBookInLibrary={props.putBookInLibrary}
                                                postOrder={props.postOrder}
                                                orderConfirmationMailToUser={props.orderConfirmationMailToUser}
                                                orderConfirmationMailToAuthor={props.orderConfirmationMailToAuthor}
                                                updateOrder={props.updateOrder}
                                                getCountryFromIp={props.getCountryFromIp}
                                                getCurrentDollarRate={props.getCurrentDollarRate}
                                            />
                                        </Elements>
                                    </StripeProvider>
                                </div>
                            </div>
                            <div className="col-md-4 mt-5">
                                <div className="proceedContainer">
                                    <div className="internalproceedContainer">
                                        <div className="col-12 mb-4 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_semibold internalproceedContainerHeading">PRODUCT</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="poppins_semibold internalproceedContainerHeading">SUBTOTAL</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="internalproceedLine"></div>
                                        <div className="col-12 ">
                                            {
                                                cart.map((item, index) =>
                                                    <div className="row mb-4 mt-3">
                                                        <div className="col-6">
                                                            <p className="poppins_semibold mb-0 internalproceedContainerText cut-text">{item.Name}</p>
                                                            <p className="poppins_regular mb-0 internalproceedContainerText cut-text">{item.Author_Name}</p>
                                                            <p className="poppins_regular internalproceedContainerText">{item.publication_category?.Category_Name}</p>


                                                        </div>
                                                        <div className="col-6 vertical_center">
                                                            <p className="poppins_semibold internalproceedContainerText">
                                                                {
                                                                    isPakistan ? (
                                                                        item?.Price + ".00 Rs"
                                                                    ) : (
                                                                        item?.Price_USD + ".00 USD"
                                                                    )
                                                                }
                                                            </p>
                                                        </div>
                                                        <div className="internalproceedLine"></div>

                                                    </div>
                                                )
                                            }
                                            {

                                                cart.length <= 0 &&
                                                <div className="text-center "> <p className="internalproceedContainerText poppins_semibold mt-5 mb-5">No Item in Cart</p>
                                                    <div className="internalproceedLine"></div>

                                                </div>
                                            }



                                        </div>
                                        <div className="col-12 mb-4 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_bold internalproceedContainerText1">Total</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="poppins_bold primary-color internalproceedContainerText1">
                                                        {
                                                            isPakistan ? (
                                                                'Rs' + sum + '.00 PKR'
                                                            ) : (
                                                                sum + '.00 USD'
                                                            )
                                                        }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{}} className='col-12 mt-5'>
                                    <div style={{}} className='row'>
                                        <div style={{ margin: '0 auto' }} className='col-2'>
                                            <img src={stripeImage} width='100%' />
                                        </div>
                                        <div style={{ marginTop: '-10px', textAlign: 'center', color: '#474747' }} className='col-12'>
                                            <p style={{ fontSize: '12px', fontWeight: '600' }}>Payment secure by stripe</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cart: cart.cart,

})


const mapDispatchToProps = ({
    putBookInLibrary,
    postOrder,
    orderConfirmationMailToUser,
    orderConfirmationMailToAuthor,
    updateOrder,
    getCountryFromIp
})
export default connect(mapStatetoProps, mapDispatchToProps)(CheckoutStripe);