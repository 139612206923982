import React, { Component } from 'react';
import { connect } from 'react-redux';


import EasyPaisaLogo from '../../assets/images/easypaisalogo.svg'
import UserHeader from '../../component/UserHeader'
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import moment from 'moment';

import Header from '../../component/Header'
import Footer from '../../component/Footer'
import {orderConfirmationMailToUser,orderConfirmationMailToAuthor, postOrder, updateOrder, getEasyPayMbl, easyPayViaCard, putBookInLibrary } from '../../store/actions/orderAction'

const aesjs = require("aes-js");

// I generate the UID from two parts here 
// to ensure the random number provide enough bits.
var firstPart = (Math.random() * 46656) | 0;
var secondPart = (Math.random() * 46656) | 0;
firstPart = ("000" + firstPart.toString(36)).slice(-3);
secondPart = ("000" + secondPart.toString(36)).slice(-3);
const id = firstPart + secondPart;
const hashKey = "A409I4LJZI51OSW9";
const storeId = "73507";
const tokenExpiry = "20211231 111111"
const orderId = id;
const transactionAmount = ""
//const transactionAmount = localStorage.getItem('Price')

const transactionType = "InitialRequest"
const expiryToken = "20211231%20111111"

const postBackURL = "https://littlebookcompany.net/statuseasypay"
//const postBackURL= "http://localhost:3000/statuseasypay"


const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
const sampleString = `amount=${transactionAmount}&expiryDate=${tokenExpiry}&orderRefNum=${orderId}&paymentMethod=InitialRequest&postBackURL=${postBackURL}&storeId=${storeId}&timeStamp=${timeStamp}`

const getEncryptedData = () => {
    console.log(sampleString)
    const keyBuffer = aesjs.utils.utf8.toBytes(hashKey);
    const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(sampleString));
    const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
    const encryptedBytes = escEcb.encrypt(inputBuffer);
    const encryptedData = Buffer.from(encryptedBytes).toString('base64');
    return encryptedData
}
var Iframe = ({ str }) => {
    console.log(str)
    console.log(encodeURIComponent(str))
    const urlStr = `storeId=${storeId}&orderId=${orderId}&transactionAmount=${transactionAmount}&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=${expiryToken}&bankIdentificationNumber=&merchantPaymentMethod=&postBackURL=${encodeURIComponent(postBackURL)}&signature=&encryptedHashRequest=${encodeURIComponent(str)}`

    console.log(timeStamp)
    console.log(urlStr)

    return (
        <div>

            <iframe id="easypay-iframe" name="easypay-iframe" className="show-iframe" src={`https://easypay.easypaisa.com.pk/tpg/?${urlStr}`} width="100%"
                height="500px"></iframe>
        </div>
    )
}

class ConfirmPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            book: [],
            Order_ID: "",
            account_no: "",
            Order: {},
            isOrderCreated: 0,
            easyPayError: "",
            encryptedString: this.getEncryptedData(),
            isEasyPay: true,
            sum: 0,
            cart: [],
            Order_ID: "",

        };
    }

    getOrderID = () => {
        var firstPart = (Math.random() * 46656) | 0;
        var secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        const id = firstPart + secondPart;
        this.setState({ Order_ID: id })
        //return id
    }

    getEncryptedData = () => {
        // I generate the UID from two parts here 
        // to ensure the random number provide enough bits.
        // var firstPart = (Math.random() * 46656) | 0;
        // var secondPart = (Math.random() * 46656) | 0;
        // firstPart = ("000" + firstPart.toString(36)).slice(-3);
        // secondPart = ("000" + secondPart.toString(36)).slice(-3);
        // const id = firstPart + secondPart;
        const hashKey = "A409I4LJZI51OSW9";
        const storeId = "73507";
        const tokenExpiry = "20211231 111111"
        const orderId = this?.props?.location?.state?.Order_ID;
        const transactionAmount = this?.props?.location?.state?.sum + ".0"
        //const transactionAmount = localStorage.getItem('Price')
        //const transactionAmount = "50.0"
        const transactionType = "InitialRequest"
        const expiryToken = "20211231%20111111"

        const postBackURL = "https://littlebookcompany.net/statuseasypay"
        //const postBackURL= "http://localhost:3000/statuseasypay"


        const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        const sampleString = `amount=${transactionAmount}&expiryDate=${tokenExpiry}&orderRefNum=${orderId}&paymentMethod=InitialRequest&postBackURL=${postBackURL}&storeId=${storeId}&timeStamp=${timeStamp}`

        console.log(sampleString)
        const keyBuffer = aesjs.utils.utf8.toBytes(hashKey);
        const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(sampleString));
        const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
        const encryptedBytes = escEcb.encrypt(inputBuffer);
        const encryptedData = Buffer.from(encryptedBytes).toString('base64');
        return encryptedData
    }

    Iframe = (str) => {
        //const id = firstPart + secondPart;
        const hashKey = "A409I4LJZI51OSW9";
        const storeId = "73507";
        const tokenExpiry = "20211231 111111"
        const orderId = this?.props?.location?.state?.Order_ID;
        const transactionAmount = this?.props?.location?.state?.sum + ".0"
        // const transactionAmount = "50.0"
        //const transactionAmount = localStorage.getItem('Price')

        const transactionType = "InitialRequest"
        const expiryToken = "20211231%20111111"

        const postBackURL = "https://littlebookcompany.net/statuseasypay"
        //const postBackURL= "http://localhost:3000/statuseasypay"


        const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
        //console.log(str)
        //console.log(encodeURIComponent(str))
        const urlStr = `storeId=${storeId}&orderId=${orderId}&transactionAmount=${transactionAmount}&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=${expiryToken}&bankIdentificationNumber=&merchantPaymentMethod=&postBackURL=${encodeURIComponent(postBackURL)}&signature=&encryptedHashRequest=${encodeURIComponent(str)}`

        //console.log(timeStamp)
        //console.log(urlStr)

        return (
            <div>

                <iframe id="easypay-iframe" name="easypay-iframe" className="show-iframe" src={`https://easypay.easypaisa.com.pk/tpg/?${urlStr}`} width="100%"
                    height="500px"></iframe>
            </div>
        )
    }

    componentDidMount() {
        //console.log(orderId)
        const { Order_ID, sum, cart } = this.state
        console.log("cart :", this.props.cart)
        if (!this.props.user?.User_ID)
            this.props.history.push("/login")
        this.props.postOrder({
            'Order_ID': Order_ID,
            'Payment_Method': 'Easy_paisa_mbl',
            'Payment_Status': 'Pending',
            'Amount': sum,
            'books': cart
        }).then((response) => {
            console.log(response)
            if (response.status) {
                // this.setState({
                //     Order: response.content[0],
                // })
                // const data = this?.props?.location?.state?.cart
                // localStorage.setItem('Book_ID', JSON.stringify(data));
                console.log(response)
                return
            }
        }).catch((err) => {
            console.log(err)

        })
    }

    componentWillMount() {
        //setAmount(this?.props?.location?.state?.sum)
        //this.getOrderID()
        // if (this?.props?.location?.state?.book) {
        //     console.log(this.props.location.state.book)
        //     this.setState({
        //         book: this.props.location.state.book
        //     })
        // }
        if (this?.props?.cart) {
            this.setState({
                cart: this.props.cart,
            })
        }
        if (this?.props?.location?.state?.sum) {
            this.setState({
                sum: this.props.location.state.sum
            })
        }
        if (this?.props?.location?.state?.Order_ID) {
            this.setState({
                Order_ID: this.props.location.state.Order_ID
            })
        }

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }

  

    sendMailToUser = () => {
        const { Order_ID } = this.state
        const { user,cart } = this.props

        this.props.orderConfirmationMailToUser({

            'Order_ID': Order_ID,
            'Payment_Method': 'Easy_paisa_mbl',
            'email': user?.Email,
            'name': user?.Full_Name,
            'books': cart,
        })
            .then((res) => {
                console.log(res)
                this.setState({ order: res.Order })

            })
            .catch((err) => {
                console.log(err)

            })
    }

    sendMailToAuthor = () => {
        const { Order_ID } = this.state
        const { cart } = this.props

        this.props.orderConfirmationMailToAuthor({

            'Order_ID': Order_ID,
            'Payment_Method': 'Easy_paisa_mbl',
            'books': cart,                        
        })
            .then((res) => {
                console.log(res)
                this.setState({ order: res.Order })

            })
            .catch((err) => {
                console.log(err)

            })
    }

    onClickEasyPay = () => {
        const { Order_ID, account_no, sum } = this.state
        const { user,cart } = this.props
        this.setState({ isLoading: true })
        this.props.getEasyPayMbl({
            'orderId': Order_ID,
            'storeId': '73507',
            'transactionAmount': sum,
            'transactionType': 'MA',
            'mobileAccountNo': account_no,
            'emailAddress': user?.Email,
        }).then((res) => {
            console.log(res)
            if (res.responseCode == "0000") {
                this.setState({
                    isOrderCreated: 1,
                    isLoading: false
                },() => {
                    this.sendMailToUser()
                    this.sendMailToAuthor()
                })
                this.props.updateOrder({
                    'Payment_Method': 'Easy_paisa_mbl',
                    'Payment_Status': 'Cleared',
                    'Order_ID': Order_ID,
                    'Reference_No': res.transactionId,
                }).then((res) => {

                    this.props.putBookInLibrary({
                         'books': cart,
                        //'books': JSON.parse(localStorage.getItem('Book_ID')),
                    }).then((res) => {
                        console.log(res)
                    })
                        .catch((err) => {
                            console.log(err)
                        })
                    console.log(res)
                    if (res.status) {
                        console.log(res)
                        this.setState({ isLoading: false })
                        this.props.history.push("/library")
                        return
                    }
                }).catch((err) => {
                    console.log(err)
                })
                return
            }
            else {
                this.setState({
                    isOrderCreated: 2,
                    easyPayError: res.responseDesc,
                    isLoading: false
                })
                this.props.updateOrder({
                    'Payment_Method': 'Easy_paisa_mbl',
                    'Payment_Status': 'Failed',
                    'Order_ID': Order_ID,
                }).then((res) => {
                    console.log(res)
                    if (res.status) {
                        this.setState({ isLoading: false })
                        console.log(res)
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    render() {
        const { t, i18n, location, user , } = this.props
        const { isLoading, book ,cart , sum} = this.state;

        // if (this.state.isEasyPay) {
        //     return (
        //         this.Iframe(this.state.encryptedString)
        //         //<Iframe str={this.state.encryptedString} />
        //     )
        // }
        return (
            <div class="dashboard-cotainer">

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }
                {/* checkout */}

                <div className="  ">
                    <div className="pl-3 pr-3  ml-4 mr-4 ">
                        <p className="poppins_bold checkout_heading primary-color mt-5 mb-0">Confirm Payment</p>
                        <p className="poppins_light ">Use you payment details to continue shopping</p>
                    </div>

                </div>



                {/* Payment detail */}
                <div className="  ">
                    <div className="col-xl-12 mb-5  ">
                        <div className=" row ">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 payment_method  vertical_center">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  pt-5 pb-5  ">
                                    <div className="text-center">
                                        {isLoading && <Loader
                                            type="TailSpin"
                                            color="#C86762"
                                            height={100}
                                            width={100}
                                        />}


                                        <img className="mt-3" src={EasyPaisaLogo}></img>
                                    </div>
                                    <div className="col-12 mt-4">
                                    {this.state.isOrderCreated === 1 && <p className="EasyPaisaSuccess">Your Order Succsessfully Created</p>}
                                    {this.state.isOrderCreated === 2 && <p className="EasyPaisaError">{this.state.easyPayError}</p>}
                                        <div className="row">
                                            <div className="col-2">
                                            </div>
                                            <div className="col-8 p-0">
                                                <p className="mb-2 poppins_regular infoText">EasyPaisa Account Information </p>
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <input className="col-8 confirmPaymentInput" name="account_no" onChange={this.onChange}></input>
                                    </div>
                                    <div className="text-center mt-5">
                                        <button className="col-4 paynowbtn poppins_medium" onClick={() => this.onClickEasyPay()}>Pay Now</button>
                                    </div>

                
                                  
                                </div>





                            </div>


                            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6   mt-3">
                                <div className="orderDetail">
                                    <p className="poppins_bold orderDetailhead mb-0">Order Details</p>
                                    <p className="poppins_extralight">Your Oder Includes</p>
                                    <div className="orderDetailInnerCard">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_semibold orderDetailInnerCardTitle">PRODUCT</p>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <p className="poppins_semibold orderDetailInnerCardTitle">Subtotal</p>

                                                </div>

                                            </div>
                                            <hr></hr>



                                        </div>

                                        <div className="col-12  pb-5 mb-5">
                                            {cart.length > 0 && cart.map((item, i) => {
                                                
                                               return (
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p className="poppins_regular orderDetailInnerCardText">{item.Name}</p>

                                                        <p className="poppins_regular orderDetailInnerCardText">{"by " + item.Author_Name}</p>
                                                        <p className="poppins_regular orderDetailInnerCardText">{item.publication_category?.Category_Name}</p>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        <p className="poppins_semibold orderDetailInnerCardText">{"Rs " + item.Price + " PKR"}</p>
                                                    </div>
                                                </div>
                                                )
                                            })}



                                        </div>

                                        <div className="col-12 mt-5 pt-5">
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-6 mt-3">
                                                    <p className="poppins_semibold orderDetailInnerCardBText">Total</p>


                                                </div>
                                                <div className="col-6 mt-3 text-right">
                                                    <p className="poppins_semibold orderDetailInnerCardBText2">{"Rs " + sum + " PKR"}</p>

                                                </div>

                                            </div>



                                        </div>

                                    </div>
                                </div>
                                <div>

                                </div>



                            </div>

                        </div>
                    </div>


                </div>

                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth , cart}) => ({
    user: auth.user,
    cart: cart.cart

})
const mapDispatchToProps = ({
    postOrder,
    updateOrder,
    getEasyPayMbl,
    easyPayViaCard,
    putBookInLibrary,
    orderConfirmationMailToUser,
    orderConfirmationMailToAuthor,
})
ConfirmPayment.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(ConfirmPayment);

