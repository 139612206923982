import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import Book from '../../assets/images/books/sadequainbook.svg'
import star from '../../assets/images/star.svg'
import paypalImg from '../../assets/images/paypal.svg'
import ForeeImg from '../../assets/images/Foree.png'
import JazzCashLogo from '../../assets/images/JaazCashLogo.png'
import PaypalLogo from '../../assets/images/Checkout/paypal.png'
import EasyPaisaLogo from '../../assets/images/Checkout/easypaisa.png'
import HBLLogo from '../../assets/images/Checkout/HBL.png'
import DropdownIcon from '../../assets/images/Checkout/dropdown.png'


import easypaisaImg from '../../assets/images/publisher/easypaisa.svg'

import table_bookimg from '../../assets/images/table_bookimg.svg'
import UserHeader from '../../component/UserHeader'

import HBlLogo from '../../assets/images/HBlLogo.png'



import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { getEasyPay, getCountryFromIp } from '../../store/actions/bookAction'
import { initiateCheckout, getForeeCheckoutURL } from 'foree-checkout';
import PayWithPayPal from './PayWithPayPal'
import { postOrder, updateOrder, putBookInLibrary } from '../../store/actions/orderAction'
import { removeFromCart } from '../../store/actions/cartAction';
import moment from 'moment';
// import Select from 'react-select';


var sum = 0
class Checkoutnew extends Component {



    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            option: "",
            book: {},
            isPaypal: false,
            order: {},
            Order_ID: "",
            isPakistan: true,
            sum: 0,
            dropDownState: 'HBL'

        };
    }
    componentDidMount() {
        console.log(this.props.cart)

        if (!this.props.user?.User_ID)
            this.props.history.push("/login")
        // if (!this.state.isPakistan) {
        //     this.props.cart.map((item, index) => {
        //         if (item.Price !== "0")

        //             sum = parseInt(item.Price, 10) + sum

        //     }
        //     )
        // } else {
        //     this.props.cart.map((item, index) => {
        //         if (item.Price_USD !== "0") {
        //             sum = parseInt(item.Price_USD, 10) + sum
        //         }
        //     }
        //     )
        // }
        //console.log(sum)

        window.addEventListener('message', (e) => {
            console.log("Save ", e)
            let URL = getForeeCheckoutURL();
            try {
                if (e.origin !== URL) {

                } else {
                    console.log("ELSE  ", URL)

                    this.updateOrder(e?.data)
                    this.addbookInLib()

                }
            } catch (err) {
                console.log(err)
            }
            console.log(e)
        })
    }

    componentWillUnmount() {

        // Make sure to remove the DOM listener when the component is unmounted.
        sum = 0
        window.removeEventListener('message', (e) => {
            console.log(e)
            let URL = getForeeCheckoutURL();
            if (e.origin !== URL) return;
            console.log(e)
            // if (e?.data?.status == 1) {
            //     console.log(e?.data?.status)
            //     this.updateOrder(e?.data)
            // } else {
            //     this.updateOrder(e?.data)
            // }
        })

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }

    componentWillMount() {
        this.props.getCountryFromIp().then((res) => {
            console.log(res)
            if (res.country === "Pakistan") {
                console.log(res.country)
                this.setState({
                    isPakistan: true,
                }, () => {
                    this.props.cart.map((item, index) => {
                        if (item.Price !== "0")

                            sum = parseInt(item.Price, 10) + sum

                    }

                    )
                    this.setState({ sum: sum })
                    console.log(sum)
                })

            }
            else {
                console.log(res.country)
                this.setState({
                    isPakistan: false,
                    dropDownState: 'PayPal'
                }, () => {
                    this.props.cart.map((item, index) => {
                        if (item.Price_USD !== "0") {
                            sum = parseInt(item.Price_USD, 10) + sum
                        }
                    }
                    )
                    this.setState({ sum: sum })
                    console.log(sum)
                })
                //alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })
        if (this.props != null && this.props.location.state != null && this.props.location.state.book) {


            console.log(this.props.location.state.book)
            this.setState({
                book: this.props.location.state.book

            })
        }

    }


    updateOrder = (data) => {
        console.log('Update Order', data.status)
        if (!this.state.order?.Order_ID)
            return
        this.props.updateOrder({

            'Order_ID': this.state.order?.Order_ID,
            //'Book_ID': this.state.book?.Book_ID,
            'Payment_Method': 'Foree',
            'Payment_Status': data?.status === 1 ? 'Cleared' : 'Failed',
            'Reference_No': data?.transaction_ref_id,
        })
            .then((res) => {
                console.log(res)
                this.setState({ order: res.Order })

            })
            .catch((err) => {
                console.log(err)

            })
    }


    createOrder = (Order_ID) => {
        console.log('Create Order')
        this.props.postOrder({
            'Order_ID': Order_ID,
            'Payment_Method': 'Foree',
            'Payment_Status': 'Pending',
            'Amount': this.state.sum,
            'books': this.props.cart
        })
            .then((res) => {
                console.log(res)
                this.setState({ order: res.content, }, () => console.log(this.state.order))
            })
            .catch((err) => {
                console.log(err)

            })
    }

    createOrderPaypal = (Order_ID) => {

        console.log('Create Order')
        this.setState({ Order_ID: Order_ID }, () => {
            this.props.postOrder({
                'Order_ID': Order_ID,
                'Amount': this.state.sum * 150,
                'Payment_Method': 'PayPal',
                'Payment_Status': 'Pending',
                'books': this.props.cart
            })
                .then((res) => {
                    console.log('Create Order')
                    const dataCart = this.props.cart
                    console.log("DATA BEFORE PAYPAL", dataCart)
                    localStorage.setItem('Book_ID', JSON.stringify(dataCart));
                    this.setState({ isPaypal: true })
                })
                .catch((err) => {
                    console.log(err)

                })
        })
    }


    onClickPayment = (data) => {
        console.log(data)
        if (data == "Easy_paisa") {
            console.log(sum)
            localStorage.setItem('Price', this.state.sum);
            var firstPart = (Math.random() * 46656) | 0;
            var secondPart = (Math.random() * 46656) | 0;
            firstPart = ("000" + firstPart.toString(36)).slice(-3);
            secondPart = ("000" + secondPart.toString(36)).slice(-3);
            const id = firstPart + secondPart;
            //localStorage.setItem('Price', this.state.book?.Price);
            this.props.history.push("/confirmpayment", { book: this.state.book, sum: this.state.sum, cart: this.props.cart, Order_ID: id })
            return
        }
        if (data == "Paypal") {
            //this.props.history.push("/confirmpayment", { book: this.state.book })
            var Order_ID = Math.floor(Math.random() * 900000) + 100000
            this.createOrderPaypal(Order_ID)
            //this.setState({ isPaypal: true })
            return
        }
        if (data == "MasterCard") {
            var Order_ID = Math.floor(Math.random() * 900000) + 100000
            var data = {
                'key': '49d6dbb2-2ca3-448d-93b1-f30b5bf39b3a',
                //'key': 'e1ed5823-4a31-48c4-a214-f97970159846',
                'amount': this.state.sum,
                //'amount': '1',
                'is_generated': 0,
                'reference_number': Order_ID,
                'customer_email_address': this.props.user?.Email,
                'customer_phone_number': '',
                'consumer_name': this.props.user?.Full_Name
            }
            initiateCheckout(data, false)
            this.createOrder(Order_ID)
        }
        if (data == "JazzCash") {
            var pp_TxnRefNo = Math.floor(Math.random() * 900000) + 100000
            var pp_TxnDateTime = moment(new Date()).format('YYYYMMDDHHmmss');
            var pp_TxnExpiryDateTime = moment().add(1, 'days').format('YYYYMMDDHHmmss');
            var pp_Amount = this.state.sum

            this.props.postOrder({
                'Order_ID': pp_TxnRefNo,
                'Payment_Method': 'JazzCash',
                'Payment_Status': 'Pending',
                'Amount': this.state.sum,
                'books': this.props.cart
            })
                .then((res) => {
                    console.log(res)
                    // this.setState({ order: res.content, }, () => console.log(this.state.order))
                    localStorage.setItem('Book_ID', JSON.stringify(this.props.cart));
                    window.location = "https://littlebookcompany.net/jazzcash?pp_TxnRefNo=" + pp_TxnRefNo + '&pp_Amount=' + pp_Amount + '&pp_TxnDateTime=' + pp_TxnDateTime + '&pp_TxnExpiryDateTime=' + pp_TxnExpiryDateTime;
                })
                .catch((err) => {
                    console.log(err)

                })

        }
        if (data == "HBL") {
            const dataCart = this.props.cart
            console.log("DATA BEFORE HBL", dataCart)
            localStorage.setItem('Book_ID', JSON.stringify(dataCart));
            var pp_TxnRefNo = Math.floor(Math.random() * 900000) + 100000
            var pp_TxnDateTime = moment(new Date()).format('YYYYMMDDHHmmss');
            var pp_TxnExpiryDateTime = moment().add(1, 'days').format('YYYYMMDDHHmmss');
            var pp_Amount = this.state.sum
            var data1 = {
                "Price": this.state.sum,
                "Order_ID": pp_TxnRefNo
            }
            this.props.postOrder({
                'Order_ID': pp_TxnRefNo,
                'Payment_Method': 'HBL',
                'Payment_Status': 'Pending',
                'Amount': this.state.sum,
                'books': this.props.cart
            })
                .then((res) => {
                    console.log(res)
                    // this.setState({ order: res.content, }, () => console.log(this.state.order))
                    // localStorage.setItem('Book_ID', JSON.stringify(this.props.cart));
                    window.location = "https://littlebookcompany.net/hbl?Order_ID=" + pp_TxnRefNo + "&Price=" + pp_Amount + "&Email=" + this.props.user?.Email + "&Name=" + this.props.user?.Full_Name;


                })
                .catch((err) => {
                    console.log(err)

                })

        }
        return
        // var data = {
        //     'amount': '1',
        //     'storeId':'73507',
        //     'orderRefNum':'500',
        //     'expiryDate':'20210320 174000',
        //     'postBackURL':'http://localhost',
        //     'autoRedirect':"0",
        //     'mobileNum':'03313844515'
        // }
        // this.props.getEasyPay({
        //     'amount': '1',
        //     'storeId':'73507',
        //     'orderRefNum':'500',
        //     'expiryDate':'20210320 174000',
        //     'postBackURL':'http://localhost',
        //     'autoRedirect':"0",
        //     'mobileNum':'03313844515'
        // }).then((res) => {
        //     console.log(res)
        //     return
        //     // if (res.status == true) {
        //     //     this.setState({
        //     //         newBookList: res.content,
        //     //     })

        //     // }
        //     // else {
        //     //     alert(res)
        //     // }
        // }).catch((err) => {
        //     console.log(err)

        // })
    }

    addbookInLib = () => {
        this.props.putBookInLibrary({
            'books': this.props.cart,
        }).then((res) => {
            console.log("HERE putBookInLibrary then")
            console.log(res)
            this.props.history.push("/library")

        })
            .catch((err) => {
                console.log("HERE putBookInLibrary err")
                console.log(err)

            })
    }

    removeFromCart = (book) => {
        this.props.removeFromCart(book)
        if (this.state.isPakistan) {
            sum = sum - book.Price
            this.setState({ sum: sum })
        } else {
            sum = sum - book.Price_USD
            this.setState({ sum: sum })
        }
    }

    IconOption = () =>
    (
        <Option >
            <div>
                <img src={PaypalLogo} />
                {'Paypal'}
            </div>
        </Option>
    );
    render() {
        console.log("CART :", this.props.cart)
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading, book, isPaypal, isPakistan } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        if (isPaypal) {

            return (
                <PayWithPayPal
                    total={this.state.sum}
                    //total={1}
                    item={book.Name}
                    updateOrder={this.props.updateOrder}
                    Order_ID={this.state.Order_ID}
                    Book_ID={this.state.book?.Book_ID}
                    putBookInLibrary={this.props.putBookInLibrary}
                    cart={this?.props?.location?.state?.cart}
                    history={this.props.history}
                >

                </PayWithPayPal>
            )
        }
        return (
            <div class="dashboard-cotainer" >

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }
                {/* checkout */}
                <div className="  ">
                    <div className="pl-3 pr-3  ml-4 mr-4 ">
                        <p className="poppins_bold checkout_heading mt-5 mb-0">Checkout Cart</p><br></br>
                    </div>
                    <div className="col-md-12 visible pl-5 pr-5">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="table-responsive mt-4 checkout_container">
                                    <table className="table table-hover thead-primary tableWidth">
                                        <thead>
                                            <tr>
                                                <th scope="col table_header poppins_medium">Book Title  </th>
                                                <th scope="col table_header poppins_medium">Book Details </th>
                                                <th scope="col table_header poppins_medium">Price </th>
                                                <th scope="col table_header poppins_medium"> </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.props.cart.map((item, index) =>

                                                    <tr>
                                                        <td><img src={item.Image} style={{ width: '90px' }}></img></td>
                                                        <td><p className="primary-color">{item.Name}</p>
                                                            <p>{item.Author_Name}</p>
                                                            <p>{item.publication_category?.Category_Name}</p>

                                                        </td>
                                                        <td>{this.state.isPakistan ? item.Price == 0 ? "0 RS" : item.Price + " .00 RS" : item.Price_USD == 0 ? "0 USD" : item.Price_USD + " .00 USD"}</td>
                                                        <td className="Remove-text" onClick={() => this.removeFromCart(item)}>Remove</td>
                                                    </tr>
                                                )
                                            }
                                            {

                                                this.props.cart.length <= 0 && <tr>
                                                    <td colSpan={4} className="text-center">No Item In Cart</td>

                                                </tr>
                                            }


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="proceedContainer">
                                    <div className="internalproceedContainer">
                                        <div className="col-12 mb-4 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_semibold internalproceedContainerHeading">PRODUCT</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="poppins_semibold internalproceedContainerHeading">SUBTOTAL</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="internalproceedLine"></div>
                                        <div className="col-12 ">
                                        {
                                                this.props.cart.map((item, index) =>
                                            <div className="row mb-4 mt-3">
                                                <div className="col-6">
                                                    <p className="poppins_semibold mb-0 internalproceedContainerText cut-text">{item.Name}</p>
                                                    <p className="poppins_regular mb-0 internalproceedContainerText cut-text">{item.Author_Name}</p>
                                                    <p className="poppins_regular internalproceedContainerText">{item.publication_category?.Category_Name}</p>


                                                </div>
                                                <div className="col-6 vertical_center">
                                                    <p className="poppins_semibold internalproceedContainerText">{this.state.isPakistan ? item.Price == 0 ? "0 RS" : item.Price + " .00 RS" : item.Price_USD == 0 ? "0 USD" : item.Price_USD + " .00 USD"}</p>
                                                </div>
                                                <div className="internalproceedLine"></div>

                                            </div>
                                                )
                                                }
                                                  {

this.props.cart.length <= 0 && 
<div className="text-center "> <p className="internalproceedContainerText poppins_semibold mt-5 mb-5">No Item in Cart</p>
<div className="internalproceedLine"></div>

</div>
}
                                         
                                            

                                        </div>
                                        <div className="col-12 mb-4 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_bold internalproceedContainerText1">Total</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="poppins_bold primary-color internalproceedContainerText1">{this.state.isPakistan ? book.Price == 0 ? "Our Gift" : this.state.sum + " .00 RS" : book.Price_USD == 0 ? "Our Gift" : this.state.sum + " .00 USD"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="internalproceedContainerText1 poppins_bold ml-3 mb-5 mt-5">Payment Options</p>
                                    <div className="payment_method">
                                        {isPakistan &&
                                            <div className="col-12">
                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="Easy_paisa" name="option" onChange={this.onChange} value="Easy_paisa" />
                                                    <label className="poppins_regular" for="Easy_paisa" ></label>
                                                    <img className="Payment-methodImg" src={EasyPaisaLogo} />

                                                </p>
                                            </div>
                                        }
                                        {!isPakistan &&
                                            <div className="col-12">

                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="Paypal" name="option" onChange={this.onChange} value="Paypal" />
                                                    <label className="poppins_regular" for="Paypal"></label>
                                                    <img className="Payment-methodImg" src={PaypalLogo} />

                                                </p>

                                            </div>
                                        }
                                        {isPakistan &&
                                            <div className="col-12">

                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="HBL" name="option" onChange={this.onChange} value="HBL" />
                                                    <label className="poppins_regular" for="HBL"></label>
                                                    <img className="Payment-methodImg" src={HBLLogo} />

                                                </p>
                                            </div>
                                        }
                                    </div>

                                    <button className="paymentCheckoutBtn mt-5 poppins_regular col-12 mb-4 " onClick={() => this.onClickPayment(this.state.option)}>PROCEED CHECK OUT</button>

                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                {/* Payment detail */}
                <div className="  ">
                    <div className="col-xl-12  pb-5 mb-5">
                        <div className=" row ">
                           

                        </div>
                    </div>


                </div>

                <Footer></Footer>
            </div>
        )
    }
}
const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cart: cart.cart
})
const mapDispatchToProps = ({
    getEasyPay,
    postOrder,
    updateOrder,
    putBookInLibrary,
    getCountryFromIp,
    removeFromCart
})
Checkoutnew.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Checkoutnew);

