import React, { Component } from 'react';
import { connect } from 'react-redux';


import EasyPaisaLogo from '../../assets/images/easypaisalogo.svg'
import UserHeader from '../../component/UserHeader'
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import JazzCashLogo from '../../assets/images/JaazCashLogo.png'

import moment from 'moment';

import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { postOrder, updateOrder, getEasyPayMbl, easyPayViaCard, putBookInLibrary } from '../../store/actions/orderAction'






class StatusJazzCash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            book: {},
            Order_ID: "",
            account_no: "",
            Order: {},
            isOrderCreated: 0,
            easyPayError: "",

            isEasyPay: true

        };
    }
    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let status = params.get('ResponseCode');
        console.log(status)
        const Book_ID = JSON.parse(localStorage.getItem('Book_ID'))
        console.log(Book_ID)
        if (status == "000") {
            this.setState({
                isOrderCreated: 1,
            })
            this.props.putBookInLibrary({
                'books': Book_ID,
            }).then((res) => {
                console.log(res)
                this.setState({ isLoading: false, isOrderCreated: 1 })
                localStorage.removeItem("Book_ID")
                // this.props.history.push("/library",)
                window.location = "https://littlebookcompany.net/library";
                return
            })
                .catch((err) => {
                    console.log(err)

                })
            console.log(res)


        } else {
            this.setState({ isOrderCreated: 2 })
        }

        // if (!this.props.user?.User_ID)
        //     this.props.history.push("/login")
    }

    componentWillMount() {
        if (this.props != null && this.props.location.state != null && this.props.location.state.book) {


            //console.log(this.props.location.state.book)
            this.setState({
                book: this.props.location.state.book

            })
        }

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }
    onClickMyBookShelf = () => {



        this.props.history.push("/library")
    }

    onClickPayment = () => {
        console.log('here')
        this.setState({ isLoading: true })
        var Order_ID = Math.floor(Math.random() * 900000) + 100000

        console.log(response)
        if (response.status) {
            this.setState({
                Order: response.content[0],
            })
            if (res.responseCode == "0000") {
                this.setState({
                    isOrderCreated: 1,

                })

                this.props.updateOrder({
                    'Book_ID': this.state.book?.Book_ID,
                    'Payment_Method': 'Easy_paisa',
                    'Payment_Status': 'Cleared',
                    'Order_ID': response.content[0]?.Order?.Order_ID,
                    'Reference_No': res.transactionId,
                }).then((res) => {

                    this.props.putBookInLibrary({
                        'Book_ID': this.state.book?.Book_ID,
                    }).then((res) => {

                    })
                        .catch((err) => {
                            console.log(err)

                        })
                    console.log(res)
                    if (res.status) {
                        console.log(res)
                        this.setState({ isLoading: false })
                        //this.props.history.push("/library")
                        return

                    }
                    else {
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })

                return

            }
            else {
                this.setState({
                    isOrderCreated: 2,
                    easyPayError: res.responseDesc
                })
                this.props.updateOrder({
                    'Book_ID': this.state.book?.Book_ID,
                    'Payment_Method': 'Easy_paisa',
                    'Payment_Status': 'Failed',
                    'Order_ID': response.content[0]?.Order?.Order_ID,
                }).then((res) => {
                    console.log(res)
                    if (res.status) {
                        this.setState({ isLoading: false })

                        console.log(res)

                        return

                    }
                    else {
                        return
                    }
                }).catch((err) => {
                    console.log(err)

                })

                return


            }


            return

        }
        else {
            return
        }

    }
    onCardPay = () => {
        const formdata = new FormData()
        formdata.append("storeId", "73507")
        formdata.append("amount", "1")
        formdata.append("postBackURL", "http://localhost:3000/confirmpayment")
        formdata.append("orderRefNum", "1")
        formdata.append("merchantHashedReq", "73507")
        formdata.append("paymentMethod", "CC_PAYMENT_METHOD")




        console.log(formdata)
        this.props.easyPayViaCard(formdata)
    }
    render() {
        const { t, i18n, location, user } = this.props
        const { isLoading, book } = this.state;


        return (
            <div class="dashboard-cotainer">

                {/* header */}

                { user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {      !user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }
                {/* checkout */}

                <div className="  ">
                    <div className="pl-3 pr-3  ml-4 mr-4 ">
                        <p className="poppins_bold checkout_heading primary-color mt-5 mb-0">Confirm Payment</p>
                        <p className="poppins_light ">Use you payment details to continue shopping</p>
                    </div>

                </div>



                {/* Payment detail */}
                <div className="  ">
                    <div className="col-xl-12 mb-5  ">
                        <div className=" row ">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 payment_method  vertical_center">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  pt-5 pb-5  ">
                                    <div className="text-center">
                                        {isLoading && <Loader
                                            type="TailSpin"
                                            color="#00BFFF"
                                            height={100}
                                            width={100}
                                        />}

                                        <img className="mt-5 pb-5 checkout_paymentimgJazzCash1" src={JazzCashLogo}></img><br></br>
                                        <button className="GoToBookShelfBtn" onClick={() => this.onClickMyBookShelf()}>GoTo My BookSelf</button>
                                    </div>

                                    {this.state.isOrderCreated === 1 && <p className="emailsenttext">Your Order Succsessfully Created</p>}
                                    {this.state.isOrderCreated === 2 && <p className="emailsenttext">There is an issue with your transaction. Please try again later.</p>}
                                </div>





                            </div>




                        </div>
                    </div>


                </div>

                <Footer></Footer>
            </div>
        )


    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    postOrder,
    updateOrder,
    getEasyPayMbl,
    easyPayViaCard,
    putBookInLibrary,
})
StatusJazzCash.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(StatusJazzCash);

