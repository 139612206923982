
import React from 'react'
import PropTypes from 'prop-types'
import {
    Helmet
} from 'react-helmet';


const HeadTags = ({ title, description, imageURL, imageAlt }) => {
    return (
        <Helmet>
            <title>{title}</title>
            {/* Injected Meta's Used by Google */}
            <meta name="description" content={description} />
            <meta name='subjet' content={title} />

            {/* Injected Meta's Used by Social Media */}
            <meta property="og:image" itemprop="image" content={imageURL} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={'https://www.littlebookcompany.net' + window.location.pathname + window.location.search} />
            <meta name='twitter:title' content={title} />
            <meta name='twitter:description' content={description} /> 
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image:alt" content={imageAlt} />
        </Helmet>
    )
}

HeadTags.propTypes = {
    title : PropTypes.string.isRequired,
    description : PropTypes.string.isRequired,
    imageURL : PropTypes.string.isRequired,
    imageAlt : PropTypes.string.isRequired,
}


export default HeadTags;