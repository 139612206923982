import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ScrollToTop from 'react-scroll-up';
import { useHistory } from 'react-router-dom'



import shoppingCart from '../assets/images/header/cart.png';

import newCartr from '../assets/images/header/new.png';


function GoCheckOutTop(props) {
    const history = useHistory();
    const [visible, setVisible] = useState(false);

    const visibility = () => {
        if (document.documentElement.scrollTop > 300) {
            setVisible(true)
        } else {
            setVisible(false)
        }
    }

    window.addEventListener('scroll', visibility)

    return (
        <div
            style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: '1',
                display : visible ? 'inline' : 'none'
            }}
        >
            <img className="BottomShoppingCart" src={newCartr} onClick={() => history.push('/checkout')} />
            <p className="shoppingCartImgCountBottom">{props.count}</p>
        </div>
    );
};


export default GoCheckOutTop;