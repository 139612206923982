import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import Noty from 'noty';
import 'babel-polyfill'

import "../node_modules/noty/lib/noty.css";  
import "../node_modules/noty/lib/themes/mint.css";  

import {hydrate, render} from 'react-dom';


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById('root');

if(rootElement.hasChildNodes()){
  hydrate(<App />, rootElement);
}
else{
  render(<App />, rootElement);
}

 
serviceWorker.unregister();
