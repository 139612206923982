import React, { Component } from 'react';
import { connect } from 'react-redux';


import Dashboard from '../Dashboard/Dashboard'
import Category from '../Category/Category'
import AboutBook from '../Book/AboutBook'
import AboutBookUrdu from '../Book/AboutBookUrdu'
import FindAStore from '../FindaStore/FindAStore'
import UserHeader from '../../component/UserHeader';


import Checkout from '../Checkout/Checkout'
import Checkoutnew from '../Checkout/Checkoutnew'

import CheckOutStripe from '../Checkout/CheckoutStripe';
import ConfirmPayment from '../Checkout/ConfirmPayment'
import ConfrimOrder from '../Checkout/ConfrimOrder'
import StatusEasyPay from '../Checkout/StatusEasyPay'
import StatusJazzCash from '../Checkout/StatusJazzCash'
import StatusHBL from '../Checkout/StatusHbl'
import OrderStatus from '../Checkout/OrderStatus'

import Error404 from '../Error_Maintanence/Error404';
import Maintanence from '../Error_Maintanence/Maintanence';
import Dummy from '../Book/Dummy';


import Login from '../Auth/Login'
import Register from '../Auth/Register'
import Register1 from '../Auth/Register1'

import ChangePassword from '../Auth/ChangePassword'

import ForgetPassword from '../Auth/ForgetPassword'
import Libarary from '../Category/Libarary'
import Unavailable from '../Category/Unavailable'
import Printondemand from '../Category/Printondemand'




import Preview from '../Book/preview'
import Preview_Urdu from '../Book/preview_urdu'
import PreviewApp from '../BookApp/PreviewApp'

import Aboutus from '../Aboutus/Aboutus'
import Publisher from '../Publisher/Publisher'
import Author from '../Author/Author'
import StoryWorkCollection from '../StoryWorkCollection/StoryWorkCollection'
import ScrollToTop from '../../utils/ScrollToTop';
import OurPublication from '../OurPublication/OurPublication'
import TermsAndCondtion from '../Footer/TermsAndCondtion'
import PrivacyPolicy from '../Footer/PrivacyPolicy'
import Support from '../Footer/Support'
import Faq from '../Android/Faq'
import AndroidPrivacy from '../Android/AndroidPrivacy'
import AndroidSupport from '../Android/AndroidSupport'
import AndroidTermsandcondition from '../Android/AndroidTermsandcondition'
import Usage from '../Testing/Usage'

import GoCheckOutTop from '../../utils/GoCheckOutTop';
import { getAllBooks } from '../../store/actions/bookAction';


import BookRoutes from './BookRoutes';




import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    Link,

} from "react-router-dom";

import axios from 'axios';



import store from '../../store/store'

import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';


import { logoutUser, setCurrentUser } from '../../store/actions/authAction';

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://api.littlebookcompany.net/v1';
} else {
    //  axios.defaults.baseURL = 'http://localhost:4002/v1';
    axios.defaults.baseURL = 'https://api.littlebookcompany.net/v1';

}
axios.defaults.headers.post['Content-Type'] = 'application/json';

if (localStorage.jwtToken) {

    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);



    const decoded = jwt_decode(localStorage.jwtToken);
    var user = localStorage.getItem('user');
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(JSON.parse(user)));

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());


    }
}

class AppNavigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            AllBooks: [],
            names: []
        };

    }

    componentWillMount() {
        this.props.getAllBooks()
            .then((res) => {
                if (res.status == true) {
                    this.setState({
                        AllBooks: res.content
                    })
                }
                else {
                    alert(res)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }




    render() {
        const { user } = this.props

        return (
            <Router >
                <ScrollToTop>
                    <Switch >
                        <Route exact path="/"
                            component={Category} />
                        <Route exact path="/category"
                            component={Category} />
                        <Route exact path="/aboutbookurdu"
                            component={AboutBookUrdu} />
                        <Route exact path="/aboutbook"
                            component={AboutBook} />

                        <Route exact path="/checkout"
                            component={Checkout} />
                        <Route exact path="/unavailable"
                            component={Unavailable} />
                        <Route exact path="/printondemand"
                            component={Printondemand} />
                        <Route exact path="/checkoutnew"
                            component={Checkoutnew} />

                        <Route exact path="/login"
                            component={Login} />
                        <Route exact path="/forgetpassword"
                            component={ForgetPassword} />
                        <Route exact path="/preview"
                            component={Preview} />
                        <Route exact path="/previewapp"
                            component={PreviewApp} />
                        <Route exact path="/preview_urdu"
                            component={Preview_Urdu} />
                        <Route exact path="/aboutus"
                            component={Aboutus} />
                        <Route exact path="/register"
                            component={Register} />
                        <Route exact path="/registerd"
                            component={Register1} />
                        <Route exact path="/publisher"
                            component={Publisher} />
                        <Route exact path="/storyworkcollection"
                            component={StoryWorkCollection} />
                        <Route exact path="/author"
                            component={Author} />
                        <Route exact path="/findAStore"
                            component={FindAStore
                            } />
                        <Route exact path="/confirmpayment"
                            component={ConfirmPayment
                            } />
                        <Route exact path="/checkoutstripe"
                            component={CheckOutStripe
                            } />
                        <Route exact path="/confirmorder"
                            component={ConfrimOrder
                            } />
                        <Route exact path="/Changepassword"
                            component={ChangePassword
                            } />
                        <Route exact path="/library"
                            component={Libarary
                            } />
                        <Route exact path="/ourpublication"
                            component={OurPublication} />
                        <Route exact path="/termsandconditons"
                            component={TermsAndCondtion} />
                        <Route exact path="/privacypolicy"
                            component={PrivacyPolicy} />
                        <Route exact path="/support"
                            component={Support} />
                        <Route exact path="/statuseasypay"
                            component={StatusEasyPay} />
                        <Route exact path="/statusjazzcash"
                            component={StatusJazzCash} />
                        <Route exact path="/statushbl"
                            component={StatusHBL} />
                        <Route exact path="/faq"
                            component={Faq} />
                        <Route exact path="/privacy"
                            component={AndroidPrivacy} />
                        <Route exact path="/appsupport"
                            component={AndroidSupport} />
                        <Route exact path="/appabout"
                            component={AndroidTermsandcondition} />
                        <Route exact path="/orderstatus"
                            component={OrderStatus} />
                        <Route exact path="/error404"
                            component={Error404} />

                        <Route exact path="/"
                            component={Maintanence} />
                        <BookRoutes
                            books={this.state.AllBooks}
                        />
                    </Switch>
                </ScrollToTop>
            </Router >
        );
    }
}
const mapStateToProp = state => ({
    user: state.auth.user,
    books: state.books
});

const mapDispatchToProps = ({
    getAllBooks,
});

export default connect(mapStateToProp, mapDispatchToProps)(AppNavigation);