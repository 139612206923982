import React, { Component } from "react";
import { createGlobalStyle } from "styled-components";
import FileReaderInput from "react-file-reader-input";
import { ReactReader } from "./modules";
import Footer from '../../component/Footer'

import {
  Container,
  ReaderContainer,
  Bar,
  LogoWrapper,
  Logo,
  GenericButton,
  CloseIcon,
  FontSizeButton,
  ButtonWrapper
} from "./Components";

const storage = global.localStorage || null;

const DEMO_URL =
  "https://mega.nz/folder/JZhS3abC#gXVYldqfSq_SKbAeU_ebFg/file/gZRzGILQ";
const DEMO_NAME = "Alice in wonderland";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullscreen: false,
      location:
        storage && storage.getItem("epub-location")
          ? storage.getItem("epub-location")
          : 2,
      localFile: null,
      localName: null,
      largeText: false,
      flow:'paginated',
      highlighted:false,
    };
    this.rendition = null;

    
  }
  toggleHighlight = () => {
    console.log(this.state.highlighted)
    this.setState({
      highlighted: !this.state.highlighted
    });
  };
  toggleFullscreen = () => {
    this.setState(
      {
        fullscreen: !this.state.fullscreen
      },
      () => {
        setTimeout(() => {
          const evt = document.createEvent("UIEvents");
          evt.initUIEvent("resize", true, false, global, 0);
        }, 1000);
      }
    );
  };

  onLocationChanged = location => {
    this.setState(
      {
        location
      },
      () => {
        storage && storage.setItem("epub-location", location);
      }
    );
  };

  onToggleFontSize = () => {
    const nextState = !this.state.largeText;
    this.setState(
      {
        largeText: nextState
      },
      () => {
        this.rendition.themes.fontSize(nextState ? "140%" : "100%");
      }
    );
  };

  onToggleLayout = () => {
    //const flow = !this.state.flow;
   
        this.rendition.themes.register({ "light" : { "body": { "color": "purple"}}});
    
  };
 

  onHihlight =(cfiRange, contents)=>{
    this.rendition.annotations.highlight(cfiRange, {}, (e) => {
      console.log("highlight clicked", e.target);
    });
    contents.window.getSelection().removeAllRanges();

  }
  getRendition = rendition => {
    // Set inital font-size, and add a pointer to rendition for later updates
    const { largeText } = this.state;
    this.rendition = rendition;
    rendition.themes.fontSize(largeText ? "140%" : "100%");
    if(this.state.highlighted){
    this.rendition.on('selected', this.onHihlight.bind(this)) ;
    this.rendition.on("selected", (cfiRange, contents)=>this.onHihlight(cfiRange, contents));
 
    console.log( 'Text : ',this.rendition )
    this.rendition.on("selected", function(cfiRange) {

      this.book.getRange(cfiRange).then(function (range) {
        var text;
        if (range) {
          text = range.toString();
          console.log(text)
        }
      })

    });
  }

  };
  handleChangeFile = (event, results) => {
    if (results.length > 0) {
      const [e, file] = results[0];
      if (file.type !== "application/epub+zip") {
        return alert("Unsupported type");
      }
      this.setState({
        localFile: e.target.result,
        localName: file.name,
        location: null
      });
    }
  };
  render() {
    const { fullscreen, location, localFile, localName } = this.state;
    return (
        <>
      <Container>
        <ReaderContainer fullscreen={true}>
          <ReactReader
            url={Book || DEMO_URL}
            title={localName || DEMO_NAME}
            location={location}
            locationChanged={this.onLocationChanged}
            getRendition={this.getRendition}
            onToggleFontSize={this.onToggleFontSize}
            onToggleLayout={this.onToggleLayout}
            toggleHighlight={this.toggleHighlight}
            direction = {"rtl"}
          />
          {/* <FontSizeButton onClick={this.onToggleFontSize}>
            Toggle font-size
          </FontSizeButton> */}
        </ReaderContainer>
        <div className="footer_bottom footerbtm vertical_center">
                  <p className="m-0 poppins_light footertextt">
                     2020 The Little Book Company  All rights reserved.                  </p>

               </div>
        
      </Container>
      </>
    );
  }
}

export default App;