import React, { Component } from 'react';
import './App.css';
import AppNavigation from '../pages/navigation/AppNavigation';

import { Provider } from 'react-redux';
import store from '../store/store';

import '../assets/styles/base/_bootstrap.scss'

import '../assets/styles/base/_style.scss'
import '../assets/styles/base/_dashboard.scss'
import '../assets/styles/base/_footer.scss'
import '../assets/styles/base/_category.scss'
import '../assets/styles/base/_aboutbook.scss'
import '../assets/styles/base/_checkout.scss'
import '../assets/styles/base/_login.scss'
import '../assets/styles/base/_epub.scss'
import '../assets/styles/base/_epubApp.scss'

import '../assets/styles/base/_header.scss'
import '../assets/styles/base/_aboutus.scss'
import '../assets/styles/base/_publisher.scss'
import '../assets/styles/base/_Ourpublication.scss'
import '../assets/styles/base/_orderstatus.scss'





import moment from 'moment';
import { initAmplitude, sendAmplitudeData } from '../utils/amplitude';
import { getServerVersion } from '../store/actions/authAction';




const aesjs = require("aes-js");

const hashKey = "A409I4LJZI51OSW9";
const storeId = "73507";
const tokenExpiry = "20211231 111111"
const orderId = "445"
const transactionAmount = "1.0"
const transactionType = "InitialRequest"
const expiryToken = "20211231%20111111"

const timeStamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
const timetStamp = moment(new Date()).format('YYYYMMDDHHmmss');

// console.log(timetStamp)
const sampleString = `amount=${transactionAmount}&expiryDate=${tokenExpiry}&orderRefNum=${orderId}&paymentMethod=InitialRequest&postBackURL=http://localhost:3000&storeId=${storeId}&timeStamp=${timeStamp}`
// console.log(sampleString)
const getEncryptedData = () => {
    const keyBuffer = aesjs.utils.utf8.toBytes(hashKey);
    const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(sampleString));
    const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
    const encryptedBytes = escEcb.encrypt(inputBuffer);
    const encryptedData = Buffer.from(encryptedBytes).toString('base64');
    return encryptedData
}
var Iframe = ({ str }) => {
    console.log(str)
    console.log(encodeURIComponent(str))
    const urlStr = `storeId=${storeId}&orderId=${orderId}&transactionAmount=${transactionAmount}&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=${expiryToken}&bankIdentificationNumber=&merchantPaymentMethod=&postBackURL=${encodeURIComponent('http://localhost:3000')}&signature=&encryptedHashRequest=${encodeURIComponent(str)}`

    // console.log(timeStamp)
    // console.log(urlStr)

    return (
        <div>

            <iframe id="easypay-iframe" name="easypay-iframe" className="show-iframe" src={`https://easypay.easypaisa.com.pk/tpg/?${urlStr}`} width="100%"
                height="500px"></iframe>
        </div>
    )
}






class App extends Component {
    constructor() {
        super()
        this.state = {
            encryptedString: getEncryptedData()
        }
    }
    componentDidMount() {
        initAmplitude()
        sendAmplitudeData("VisitWebsite")
        let localVersion = localStorage.getItem("version")
        // console.log( "Local Version",localVersion)
        getServerVersion().then(res => {
            let serverVersion = parseInt(res?.versionCode) || 0
            if (localVersion && serverVersion > localVersion) {
                window.location.reload(true);
                localStorage.setItem("version", serverVersion)
            } else {
                localStorage.setItem("version", serverVersion)
            }
        }).catch(e => {

        })

    }

    render() {
        return (
            // <Iframe str={this.state.encryptedString} />
            <Provider store={store}>
                <AppNavigation />
            </Provider>
            // <button onClick={this.ff}>Click</button>
        );
    }
}
export default App;