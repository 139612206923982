import React, { Component } from 'react';
import { connect } from 'react-redux';

import Book from '../../assets/images/books/sadequainbook.svg'
import star from '../../assets/images/star.svg'
import aboutbook1 from '../../assets/images/aboutbook1.svg'
import aboutbook2 from '../../assets/images/aboutbook2.svg'
import table_bookimg from '../../assets/images/table_bookimg.svg'
import UserHeader from '../../component/UserHeader'



import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { getEasyPay } from '../../store/actions/bookAction'

class ConfrimOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,

        };
    }
    componentDidMount() {
        console.log(this.props.user)
 
        if( !this.props.user?.User_ID)
        this.props.history.push("/login")
    }

    onClickPayment = () => {
        console.log('here')
        var data = {
            'amount': '1',
            'storeId':'73507',
            'orderRefNum':'500',
            'expiryDate':'20210320 174000',
            'postBackURL':'http://localhost',
            'autoRedirect':"0",
            'mobileNum':'03313844515'
        }
        this.props.getEasyPay({
            'amount': '1',
            'storeId':'73507',
            'orderRefNum':'500',
            'expiryDate':'20210320 174000',
            'postBackURL':'http://localhost',
            'autoRedirect':"0",
            'mobileNum':'03313844515'
        }).then((res) => {
            console.log(res)
            return
            // if (res.status == true) {
            //     this.setState({
            //         newBookList: res.content,
            //     })

            // }
            // else {
            //     alert(res)
            // }
        }).catch((err) => {
            console.log(err)

        })
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader-large"></div>
            )
        }
        return (
            <div class="dashboard-cotainer">

                {/* header */}

                { user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {      !user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }
                {/* checkout */}
                <div className="  mb-4">
                    <div className="pl-3 pr-3  mt-4 mb-4 ml-4 mr-4 ">
                        <div className="text-center"> 
                        <p className="poppins_bold checkout_heading primary-color mt-5 mb-0">Thank you for purchasing!</p>
                        <p className="poppins_light ">Little Book Company thanks to you form purchasing book frim our platform</p>
                        </div>
                    </div>
                    <div className="table-responsive mt-5 checkout_container">
                        <table className="table table-hover thead-primary">
                            <thead>
                                <tr>
                                <th scope="col table_header poppins_medium">Order ID</th>
                                    <th scope="col table_header poppins_medium">Book Title  </th>
                                    <th scope="col table_header poppins_medium">Book Name </th>
                                    <th scope="col table_header poppins_medium">Author Name</th>
                                    <th scope="col table_header poppins_medium">Category </th>
                                    <th scope="col table_header poppins_medium"> </th>

                                </tr>
                            </thead>
                            <tbody>

                            <td>012115155</td>

                                <td><img src={table_bookimg}></img></td>
                                <td>Sadequain</td>
                                <td>by Saiyid Ali Naqvi</td>
                                <td>Novel</td>
                                <td> <button className="confirmorderReadbtn">Read</button> </td>







                            </tbody>
                           
                        </table>

                    </div>

                </div>



                {/* Payment detail */}
               
                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    getEasyPay,
})
ConfrimOrder.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(ConfrimOrder);

