import React, { Component } from 'react';
import { connect } from 'react-redux';
import bookstore_card from '../../assets/images/bookstore_card.svg'
import bookicon from '../../assets/images/undraw_Books_l33t.svg'
import Header from '../../component/Header'
import authorcard_img from '../../assets/images/authorcard_img.svg'
import romantic_novel from '../../assets/images/romantic-novel.svg'
import { loginUser, updateReader } from '../../store/actions/authAction'
import Noty from 'noty';
import { ClipLoader } from 'react-spinners'
import Footer from '../../component/Footer'

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},

            serverError: {},
            is: false,
            email: "",
            password: "",
        };
    }
    componentDidMount() {
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    componentWillMount() {
        if (this.props != null && this.props.location.state != null && this.props.location.state.email) {
            console.log("BHBH",this.props.location.state.book)
            this.setState({
                email: this.props.location.state.email
            })
        }else{
            this.setState({
                email: ""
            })
        }

    }

    onUpdate = (e) => {
        e.preventDefault()
        this.setState({ is: true })
        this.props.updateReader({
            "Email": this.state.email,
            "Password": this.state.password,
            "Password2": this.state.password2,
            "Otp": this.state.otp,
        }).then((res) => {
            this.setState({ is: false })
            console.log(res)
            if (res.status) {
                new Noty({
                    text: "Succsessfully Password changed",
                    type: "success",
                    timeout: 2000
                }).show()
                this.props.history.push('/Login')
            } else {
                new Noty({
                    text: "Something went wrong",
                    type: "error",
                    timeout: 2000
                }).show();
            }
        }).catch((err) => {
            this.setState({ is: false })
            console.log(err)
            var validationError = {}
            var serverError = []
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    if (obj.hasOwnProperty('param')) {
                        validationError[obj["param"]] = obj["msg"]
                    } else {
                        serverError = [...serverError, obj]
                    }
                });
                this.setState({ errors: validationError });
                this.setState({ serverError: serverError });
            } else {
                this.setState({ serverError: [{ "msg": "server not responding" }] })
            }
        })
    }
    renderServerError() {
        if (this.state.serverError != null && this.state.serverError.length > 0) {
            return (

                <div className=" servererror1 form-group alert alert-danger" role="alert" >
                    <strong className="pr-2 ohsnaptext">Oh snap!  {"  "}</strong>
                    <label className="servererrorText">
                        {this.state.serverError[0].msg}
                    </label>
                </div>
            )
        }
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n } = this.props
        const { is } = this.state;
        const { errors } = this.state

        return (
            <div class="dashboard-cotainer">

                <Header
                    history={this.props.history}
                ></Header>
                {/* about book */}
                <div className=" login-container  mt-5">

                    <div className="row">

                        <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12 col-12   mb-5">
                            <div className="logincard ">

                                <p className="poppins_bold sign_heading primary-color mb-0">Change Your Password</p>
                                <p className="poppins_extra_light sign_text primary-color mb-0">Enter Your New Password </p>
                                <div className="sing-horizontal-row col-xl-8"></div>
                                {this.renderServerError()}



                                <p className="  sign-form-text poppins_regular mt-3" >Otp</p>
                                <input className="sign-input col-xl-12" name="otp" onChange={this.onChange}></input>
                                {errors.otp && <div className="invaliderror1">{errors.otp}</div>}
                                <p className="  sign-form-text poppins_regular mt-3" >New Password</p>
                                <input className="sign-input col-xl-12" type="password" name="password" onChange={this.onChange}></input>
                                {errors.password && <div className="invaliderror1">{errors.password}</div>}
                                <p className="  sign-form-text poppins_regular mt-3" >Re-Enter New Password </p>
                                <input className="sign-input col-xl-12" type="password" name="password2" onChange={this.onChange}></input>
                                {errors.password2 && <div className="invaliderror1">{errors.password2}</div>}



                                <div className="text-center mt-4 ">
                                    {
                                        is && <button
                                            className="sign_verification_btn poppins_bold  col-xl-4 mt-4">
                                            <ClipLoader color='white' />
                                        </button>   
                                    }
                                    {
                                        !is && <button className="sign_verification_btn poppins_bold  col-xl-4 mt-4" onClick={this.onUpdate}>Send</button>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12   mb-5">

                            <div className="row">


                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  mb-5 text-center">
                                    <div className="register-card1 publshercard" >
                                        <img className="sign-discount-img mt-4" src={bookicon} /> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Are you a Publisher?</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                                        <a href="https://admin.littlebookcompany.net">
                                            <button className="sign_register_button poppins_semibold publshercardbtn mt-3">Register Now</button>
                                        </a>
                                    </div>
                                    <div className="register-card1 bookstorecard" >
                                        <img className="sign-discount-img mt-4" src={bookstore_card}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Are you a Bookstore?</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your store location, customer and sales</label><br></br>
                                        <a href="https://admin.littlebookcompany.net">
                                            <button className="sign_register_button poppins_semibold bookstorecardbtn mt-3">Register Now</button>
                                        </a>
                                    </div>

                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  mb-5 text-center">
                                    <div className="register-card1 authorcardd" >
                                        <img className="sign-discount-img mt-4" src={authorcard_img}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Are you an Author?</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your sales via our digitalize book platform.</label><br></br>
                                        <a href="https://admin.littlebookcompany.net">
                                            <button className="sign_register_button poppins_semibold authorcarddbtn mt-3">Register Now</button>
                                        </a>
                                    </div>
                                    <div className="register-card1 storycardd" >
                                        <img className="sign-discount-img mt-4" src={romantic_novel}></img> <br></br>
                                        <label className="poppins_semibold signdiscount-img-heading">Story Work Collective</label><br></br>
                                        <label className="poppins_regular signdiscount_img_text pl-4 pr-4">Little Book Company Increase your store location, customer and sales</label><br></br>
                                        <a href="https://admin.littlebookcompany.net">
                                            <button className="sign_register_button poppins_semibold storycarddbtn mt-3">Register Now</button>
                                        </a>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    loginUser,
    updateReader,
})
ChangePassword.propTypes = {

};
export default connect(mapStatetoProps, mapDispatchToProps)(ChangePassword);

