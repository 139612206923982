import React, { Component } from 'react';
import { connect } from 'react-redux';
import Book from '../../assets/images/books/sadequainbook.svg'
import star from '../../assets/images/star.svg'
import Loader from "react-loader-spinner"
import aboutbook1 from '../../assets/images/aboutbook1.svg'
import aboutbook2 from '../../assets/images/aboutbook2.svg'
import Header from '../../component/Header'
import { Link, withRouter, useHistory } from 'react-router-dom';
import Footer from '../../component/Footer'
var cx = require('classnames');
import HeadTags from '../../component/HeadTags';
import metaImage from '../../assets/images/barrier.png'

import UserHeader from '../../component/UserHeader'
import { getAllBooks, getSpecificBooks, getCountryFromIp, getSpecificBookByID } from '../../store/actions/bookAction'
import { addToCart, removeFromCart } from '../../store/actions/cartAction';
import { putBookInLibrary } from '../../store/actions/orderAction';
import axios from 'axios';


class AboutBook extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            serverError: {},
            isLoading: false,
            book: {},
            activeTab: 1,
            isPakistan: true,
            book_Id: null,
            book_Name: ''

        };

    }
    onClickBottomBar = (val) => {
        this.setState({
            activeTab: val
        })
    }
    
    componentDidMount() {
        console.log('I am in Component Did Mount');
        let search = window.location.href;
        console.log(search);
        const string = 'https://www.littlebookcompany.net/TheInn/'
        console.log('LAST BOOK NAME: ', { url: string, bookName: string.match("littlebookcompany.net/(.*)/")[1] });
        // let lastSegment = search.substring(search.lastIndexOf("/") + 1) || search.substring(search.indexOf("/")+1, search.lastIndexOf("/"))
        // let lastSegment = search.substring(search.lastIndexOf("/") + 1) || search.match("localhost:3000/(.*)/")[1] || search.match("littlebookcompany.net/(.*)/")[1];
        let lastSegment = search.substring(search.lastIndexOf("/") + 1) || search.match("littlebookcompany.net/(.*)/")[1];
        //lastSegment = decodeURI(lastSegment);
        console.log('SEARCH BOOK NAME FROM URL: ', lastSegment);

        this.setState({
            isLoading: true
        })
        this.props.getAllBooks()
            .then((res) => {
                console.log('About Book Response', res.content.length)

                if (res.status == true) {

                    function findBook(item) {
                        return item.Name.replace(/[ &\/\\#,+()$~%.'":*?<>{}-’]/g, '') == lastSegment;
                    }


                    let book = res.content.find((item) => findBook(item))


                    console.log(book);
                    axios.post('https://api.littlebookcompany.net/v1/api/specific/book', {
                        Book_ID: book.Book_ID
                    }).then((res) => {
                        console.log(res.data)

                        this.setState((state) => {
                            return { ...state, book: res.data.content }
                        })
                        this.setState({
                            isLoading: false
                        })
                    }).catch((err) => {

                        console.log('ERROR !! ', err)
                    })
                } else {
                    alert(res)
                }
            })
            .catch((error) => {
                console.log(error)
            })


        this.props.getCountryFromIp().then((res) => {
            console.log(res)
            if (res.country === "Pakistan") {
                //console.log(res.country)
                this.setState({
                    isPakistan: true,
                })

            }
            else {
                //console.log(res.country)
                this.setState({
                    isPakistan: false,
                })
                //alert(res)
            }
        }).catch((err) => {
            console.log(err)

        })

    }
    onClickPreview = (book) => {

        if (book.Price == 0 || book.Price_USD == 0) {
            if (!this.props?.user.User_ID) {
                console.log("Here", this.props?.user)
                this.props.history.push("/login")
            } else {
                if (book?.isPurchased === book.Book_ID) {
                    this.props.history.push("/library")
                } else {
                    var books = []
                    books.push(book)
                    this.props.putBookInLibrary({
                        'books': books,
                    }).then((res) => {
                        this.props.history.push("/library")
                        //console.log("Here",res)
                    })
                        .catch((err) => {
                            console.log(err)

                        })
                }
            }

        } else {

            this.props.history.push("/preview", { book: book })
        }
    }

    onClickRedeem = (book) => {
        if (this.props.user === {}) {



            this.props.history.push("/library")

        } else {
            console.log(this.props.user)
            this.props.history.push("/login")
        }
    }

    onClickCart = (book) => {
        // if(this.props.user === {}){

        let item = this.props.cart.find(item => {
            return item.Book_ID === book.Book_ID
        })
        if (!item)
            this.props.addToCart(book)
        else {
            console.log(" Already ADDed in CART")
        }


        this.props.history.push("/checkout", { book: book })
        // }else{
        //     console.log(this.props.user)
        //     this.props.history.push("/login")
        // }
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n, location, user } = this.props
        const { isLoading, book } = this.state;
        // console.log('Book', book)
        if (isLoading) {
            return (
                <div style={{
                    width: '25%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                }}>
                    <Loader
                        type="TailSpin"
                        color="#BB423B"
                        height={100}
                        width={100}
                    />
                </div>
            )
        }
        return (
            <div class="dashboard-cotainer">
                <HeadTags
                    title={book.Name}
                    description={book.Author_Name}
                    imageURL={book.Image}
                    imageAlt={book.Name}
                />

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={this.props.history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={this.props.history}

                    ></Header>
                }


                {/* about book */}
                <div className="  ">

                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  ">

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-5 ">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text_center ">
                                        <img className="book_img img-w-100  img-br-22" src={book.Image}></img>
                                    </div>
                                    <div className="col-xl-7 col-lg-7 col-md-6 col-sm-7 col-12 vertical_center  ">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                            <p className="poppins_regular aboutbook_title mt-3">{book.Name}</p><br></br>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                            <p className="poppins_light aboutbook_author">{"by " + book.Author_Name}</p><br></br>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">

                                            <p className="poppins_light aboutbook_publisher"><b>Publisher</b>: {book.Publisher_Name}</p><br></br>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
                                            <img src={star}></img> <label className="poppins_light aboutbook_rating">4.2</label>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
                                            {/* <p className="poppins_light aboutbook_price_text">Price: <del className="aboutbook_price_delte">200 PKR</del></p> */}

                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
                                            <p className="poppins_semibold aboutbook_price_actual"><label className='aboutbook_title mr-2'>Price: </label>{this.state.isPakistan ? book.Price == 0 ? "Our Gift" : book.Price + " .00 RS" : book.Price_USD == 0 ? "Our Gift" : book.Price_USD + " .00 USD"}</p>
                                        </div>
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12   ">
                                            {book?.isPurchased === book.Book_ID ? (
                                                <button className="AlreadyBoughtBtn poppins_semibold " disabled={true} >Already Bought</button>
                                            ) : (
                                                <button className="AddBookBtn1 poppins_semibold " onClick={() => this.onClickCart(book)} disabled={this.state.isPakistan ? book.Price == 0 ? true : false : book.Price_USD == 0 ? true : false}>Add to Cart</button>

                                            )}

                                            {book?.isPurchased === book.Book_ID ?
                                                <button className="poppins_semibold BookPreviewBtn" onClick={() => this.onClickPreview(book)}>{this.state.isPakistan ? book.Price == 0 ? "Go to BookShelf" : "Preview Book" : book.Price_USD == 0 ? "Go to BookShelf" : "Preview Book"}</button>
                                                :
                                                <button className="poppins_semibold BookPreviewBtn" onClick={() => this.onClickPreview(book)}>{this.state.isPakistan ? book.Price == 0 ? "Redeem Book" : "Preview Book" : book.Price_USD == 0 ? "Redeem Book" : "Preview Book"}</button>

                                            }

                                            {/* <button className="poppins_semibold aboutbook_preview" onClick={() => this.onClickPreview(book)}>{this.state.isPakistan? book.Price == 0 ? "Redeem Book" : "Preview Book":book.Price_USD == 0 ? "Redeem Book" : "Preview Book"}</button> */}

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-xl-12 col-lg-12 col-md-12 col-12 mt-5 mb-5'>
                        <div className='row'>
                            <div className='col-xl-4 col-lg-4 col-md-4 col-12 containerimage'>
                                <img className="AutorImg" src={book.Author_Image}></img>
                                <br></br>
                                <p className="poppins_regular about_book_autor mt-3 text-center"> {book.Author_Name}  </p>
                            </div>
                            <div class='col-xl-8 col-lg-8 col-md-8 col-12 containertext'>
                                <p className='poppins_medium AboutBook-Heading'>About Book</p>
                                <p className='poppins_light  AboutBook-Text mb-5'>{book.Description}</p>

                                <p className='poppins_medium AboutBook-Heading'>About Author</p>
                                <p className='poppins_light  AboutBook-Text'>{book.Author_Description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mlmr25">
                        <div className="mt-5 mb-5">

                            <div className="aboutbook_btn_slider">
                                <div className="row ml-0 mr-0">

                                    <button className={cx({ "aboutbook_btn_slider_btn": true, "aboutbook_btn_slider_btn_active": this.state.activeTab === 2 })} onClick={() => this.onClickBottomBar(2)}>Author Details</button>
                                    <button className={cx({ "aboutbook_btn_slider_btn": true, "aboutbook_btn_slider_btn_active": this.state.activeTab === 3 })} onClick={() => this.onClickBottomBar(3)}>Terms & Conditions</button>


                                </div>

                            </div>




                        </div>

                    </div>

                </div>



                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cart: cart.cart
})
const mapDispatchToProps = ({
    getAllBooks,
    getSpecificBooks,
    getCountryFromIp,
    addToCart, removeFromCart,
    putBookInLibrary,
    getSpecificBookByID

})
AboutBook.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(AboutBook);

