import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners'

function Login(props) {
    const { show, onClose, closeLoginModal, isLoading, renderError, errors, onChange, handleLogin, toogleRegisterModal } = props;
    const history = useHistory();

    return (
        <div className='sideCart'>
            <Modal
                onHide={onClose}
                dialogClassName="col-xl-12  sideCart   "
                show={show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                animation={false}
            >
                <div className="  modal-body p-0">
                    <div className='col-12 LoginModal text-center'>
                        <div className='text-left'>
                            <button className='arrowBtn' onClick={closeLoginModal}><i class="fa fa-angle-left Arrowfa" aria-hidden="true"></i></button>
                        </div>
                        {
                            renderError != null && renderError.length > 0 && <div className=" servererror1 form-group alert alert-danger" role="alert" >
                                <strong className="pr-2 ohsnaptext">  {"  "}</strong>
                                <label className="servererrorText">
                                    {renderError[0].msg}
                                </label>
                            </div>
                        }
                        <p className='poppins_medium heading mt-3'>Hello, Welcome back!</p>
                        <p className='poppins_regular Text'>Start reading from where you stopped.</p>
                        <input className='Login-input'
                            placeholder='Email'
                            name="email"
                            onChange={onChange}
                        >
                        </input>
                        {errors.email && <div className="invaliderror1">{errors.email}</div>}

                        <input className='Login-input'
                            type='password'
                            placeholder='Password'
                            name="password"
                            onChange={onChange}
                        >

                        </input>
                        {errors.password && <div className="invaliderror1">{errors.password}</div>}

                        <div className='text-right'>
                            <p
                                className='poppins_light ForgetText'>
                                    Unable to login?
                                    <label
                                        className='poppins_regular ForgetTextPrimary'
                                        onClick={ () => history.push('/forgetpassword')}>
                                            Forgot Password?
                                    </label>
                            </p>
                        </div>
                        {
                            isLoading && <button
                                className='LoginBtn'>
                                <ClipLoader color="white" />
                            </button>
                        }
                        {
                            !isLoading && <button
                                className='LoginBtn'
                                onClick={handleLogin}
                            >Start Reading</button>
                        }

                        <p className='poppins_light ForgetText'>I already don’t have an account?
                            <label className='poppins_regular ForgetTextPrimary' onClick={() => toogleRegisterModal()}> Register Now!</label>
                        </p>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Login

