import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import axios from 'axios';

import Book from '../../assets/images/books/sadequainbook.svg'
import star from '../../assets/images/star.svg'
import paypalImg from '../../assets/images/paypal.svg'
import ForeeImg from '../../assets/images/Foree.png'
import JazzCashLogo from '../../assets/images/JaazCashLogo.png'
import PaypalLogo from '../../assets/images/Checkout/paypal.png'
import EasyPaisaLogo from '../../assets/images/Checkout/easypaisa.png'
import HBLLogo from '../../assets/images/Checkout/HBL.png'
import StripeLogo from '../../assets/images/Checkout/StripeIcon.svg'
import DropdownIcon from '../../assets/images/Checkout/dropdown.png'


import easypaisaImg from '../../assets/images/publisher/easypaisa.svg'

import table_bookimg from '../../assets/images/table_bookimg.svg'
import UserHeader from '../../component/UserHeader'

import HBlLogo from '../../assets/images/HBlLogo.png'
import backBtn from '../../assets/images/back_btn.svg';
import deleteIcon from '../../assets/images/toolip/deleteIcon.png';

import cartEmptyIcon from '../../assets/images/header/cartEmpty.svg';




import Header from '../../component/Header'
import Footer from '../../component/Footer'
import { getEasyPay, getCountryFromIp } from '../../store/actions/bookAction'
import { initiateCheckout, getForeeCheckoutURL } from 'foree-checkout';
import PayWithPayPal from './PayWithPayPal'
import { postOrder, updateOrder, putBookInLibrary, orderConfirmationMailToUser, orderConfirmationMailToAuthor } from '../../store/actions/orderAction'
import { removeFromCart } from '../../store/actions/cartAction';
import moment from 'moment';
// import Select from 'react-select';


var sum = 0
class Checkoutnew extends Component {



    constructor(props) {
        super(props);
        this.state = {
            serverError: {},
            isLoading: false,
            option: "Paypal",
            book: {},
            isPaypal: false,
            order: {},
            Order_ID: "",
            isPakistan: true,
            sum: 0,
            dropDownState: 'HBL',
            isLoginModal: false,
            showModal: false,
            currentExchangeRate: 0

        };
    }
    componentDidMount() {
        console.log(this.props.cart)

        if (!this.props.user?.User_ID)
            this.setState({ isLoginModal: true })


        // Api call to get current dollar exchange rate
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch("https://api.getgeoapi.com/v2/currency/convert?api_key=7fff062f53b27b6373ded160e89f0d3726c11d57&from=USD&to=PKR&amount=1&format=json", requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                let response = JSON.parse(result)
                console.log(response)
                this.setState({
                    currentExchangeRate : response.rates.PKR.rate
                },()=>{
                    console.log('Current Exchange Rate' , this.state.currentExchangeRate)
                })
            })
            .catch(error => {
                console.log('error', error)
                this.setState({
                    currentExchangeRate : null
                },()=>{
                    console.log('Current Exchange Rate' , this.state.currentExchangeRate)
                })
            });

    }

    componentWillUnmount() {

        sum = 0
        window.removeEventListener('message', (e) => {
            console.log(e)
            let URL = getForeeCheckoutURL();
            if (e.origin !== URL) return;
            console.log(e)
        })

    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })

    }

    componentWillMount() {
        this.props.getCountryFromIp().then((res) => {
            console.log('IP selection', res)
            if (res.country === "Pakistan") {
                console.log(res.country)
                this.setState({
                    isPakistan: true,
                }, () => {
                    this.props.cart.map((item, index) => {
                        if (item.Price !== "0")
                            sum = parseInt(item.Price, 10) + sum
                    }
                    )
                    this.setState({ sum: sum })
                    console.log(sum)
                })
            }
            else {
                console.log(res.country)
                this.setState({
                    isPakistan: false,
                    dropDownState: 'PayPal'
                }, () => {
                    this.props.cart.map((item, index) => {
                        if (item.Price_USD !== "0") {
                            sum = parseInt(item.Price_USD, 10) + sum
                        }
                    }
                    )
                    this.setState({ sum: sum })
                    console.log(sum)
                })
            }
        }).catch((err) => {
            console.log(err)

        })
        if (this.props != null && this.props.location.state != null && this.props.location.state.book) {
            console.log(this.props.location.state.book)
            this.setState({
                book: this.props.location.state.book

            })
        }

    }


    updateOrder = (data) => {
        if (!this.state.order?.Order_ID)
            return
        this.props.updateOrder({

            'Order_ID': this.state.order?.Order_ID,
            'Payment_Method': 'HBL',
            'Payment_Status': data?.status === 1 ? 'Cleared' : 'Failed',
            'Reference_No': data?.transaction_ref_id,
        })
            .then((res) => {
                console.log(res)
                this.setState({ order: res.Order })
            })
            .catch((err) => {
                console.log(err)
            })
    }


    createOrder = (Order_ID) => {
        console.log('Create Order')
        var data = {
            'Order_ID': Order_ID,
            'Payment_Method': 'Test',
            'Payment_Status': 'Pending',
            'Amount': this.state.sum,
            'books': this.props.cart,
            'dollarRate' : this.state.currentExchangeRate,
        }
        var data1 = {
            'Order_ID': Order_ID,
            'Payment_Method': 'Test',
            'Payment_Status': 'Cleared',
            'Amount': this.state.sum,
            'books': this.props.cart,
            'dollarRate' : this.state.currentExchangeRate,
        }
        this.props.postOrder(data)
            .then((res) => {
                console.log(res)
                this.setState({ order: res.content, }, () => {
                    console.log(this.state.order)
                    this.props.orderConfirmationMailToUser({

                        'Order_ID': this.state.order?.Order_ID,
                        //'Book_ID': this.state.book?.Book_ID,
                        'Payment_Method': 'Test',
                        'email': this.props.user?.Email,
                        'name': this.props.user?.Full_Name,
                        'books': this.props.cart,
                    })
                        .then((res) => {
                            console.log(res)
                            this.setState({ order: res.Order })

                        })
                        .catch((err) => {
                            console.log(err)

                        })
                    this.props.orderConfirmationMailToAuthor({

                        'Order_ID': this.state.order?.Order_ID,
                        //'Book_ID': this.state.book?.Book_ID,
                        'Payment_Method': 'Test',
                        'books': this.props.cart,
                    })
                        .then((res) => {
                            console.log(res)
                            this.setState({ order: res.Order })

                        })
                        .catch((err) => {
                            console.log(err)

                        })
                    this.updateOrder(data1)
                    this.addbookInLib(this.state.order?.Order_ID)
                })
            })
            .catch((err) => {
                console.log(err)

            })
    }

    createOrderPaypal = (Order_ID) => {

        var data = {
            'Order_ID': Order_ID,
            // 'Amount': this.state.sum * 150,
            'Amount': this.state.sum,
            'Payment_Method': 'PayPal',
            'Payment_Status': 'Pending',
            'books': this.props.cart,
            'currency' : this.state.isPakistan? 'PKR' : 'USD',
            'dollarRate' : this.state.isPakistan ? null : this.state.currentExchangeRate,
        }

        this.props.postOrder(data)
            .then((res) => {
                this.setState({ order: res.content, }, () => {
                    console.log(this.state.order)
                    this.props.orderConfirmationMailToUser({
                        'Order_ID': this.state.order?.Order_ID,
                        'Payment_Method': 'PayPal',
                        'email': this.props.user?.Email,
                        'name': this.props.user?.Full_Name,
                        'books': this.props.cart,
                    })
                        .then((res) => {
                            console.log(res)
                            this.setState({ order: res.Order })

                        })
                        .catch((err) => {
                            console.log(err)

                        })
                    this.props.orderConfirmationMailToAuthor({

                        'Order_ID': this.state.order?.Order_ID,
                        'Payment_Method': 'PayPal',
                        'books': this.props.cart,
                    })
                        .then((res) => {
                            console.log(res)
                            this.setState({ order: res.Order })

                        })
                        .catch((err) => {
                            console.log(err)

                        })
                })
                console.log('Create Order')
                const dataCart = this.props.cart
                console.log("DATA BEFORE PAYPAL", dataCart)
                localStorage.setItem('Book_ID', JSON.stringify(dataCart));
                this.setState({ isPaypal: true })
            })
            .catch((err) => {
                console.log(err)

            })
    }


    onClickPayment = (data) => {
        console.log(data)
        let order_id = 'xxx-xxx-xxx';
        console.log("UNIQUE ORDER ID : ", order_id);
        if (data == "Easy_paisa") {
            console.log(sum)
            localStorage.setItem('Price', this.state.sum);
            var firstPart = (Math.random() * 46656) | 0;
            var secondPart = (Math.random() * 46656) | 0;
            firstPart = ("000" + firstPart.toString(36)).slice(-3);
            secondPart = ("000" + secondPart.toString(36)).slice(-3);
            const id = firstPart + secondPart;
            localStorage.setItem('Order_ID', JSON.stringify(id));
            //localStorage.setItem('Price', this.state.book?.Price);
            const dataCart = this.props.cart
            console.log("DATA BEFORE PAYPAL", dataCart)
            localStorage.setItem('Book_ID', JSON.stringify(dataCart));
            this.props.history.push("/confirmpayment", { book: this.state.book, sum: this.state.sum, cart: this.props.cart, Order_ID: id })
            return
        }
        if (data == "Paypal") {
            //this.props.history.push("/confirmpayment", { book: this.state.book })
            // var Order_ID = Math.floor(Math.random() * 900000) + 100000
            var Order_ID = 'xxx-xxx-xxx-xxx'.replace(/[x]/g, (c) => {
                let dataTimeNow = new Date().getTime()
                var r = (dataTimeNow + Math.random() * 16) % 16 | 0;
                dataTimeNow = Math.floor(dataTimeNow / 16)
                return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16)
            })
            console.log('Paypal Order', Order_ID)
            this.createOrderPaypal(Order_ID)
            this.setState({ Order_ID: Order_ID })
            return
        }
        if (data == "MasterCard") {
            var Order_ID = Math.floor(Math.random() * 900000) + 100000
            // var data = {
            //     //'key': '49d6dbb2-2ca3-448d-93b1-f30b5bf39b3a',
            //     'key': 'e1ed5823-4a31-48c4-a214-f97970159846',
            //     'amount': this.state.sum,
            //     //'amount': '1',
            //     'is_generated': 0,
            //     'reference_number': Order_ID,
            //     'customer_email_address': this.props.user?.Email,
            //     'customer_phone_number': '',
            //     'consumer_name': this.props.user?.Full_Name
            // }
            // initiateCheckout(data, false)
            this.createOrder(Order_ID)
        }
        if (data == "JazzCash") {
            var pp_TxnRefNo = Math.floor(Math.random() * 900000) + 100000
            var pp_TxnDateTime = moment(new Date()).format('YYYYMMDDHHmmss');
            var pp_TxnExpiryDateTime = moment().add(1, 'days').format('YYYYMMDDHHmmss');
            var pp_Amount = this.state.sum

            this.props.postOrder({
                'Order_ID': pp_TxnRefNo,
                'Payment_Method': 'JazzCash',
                'Payment_Status': 'Pending',
                'Amount': this.state.sum,
                'books': this.props.cart
            })
                .then((res) => {
                    console.log(res)
                    // this.setState({ order: res.content, }, () => console.log(this.state.order))
                    localStorage.setItem('Book_ID', JSON.stringify(this.props.cart));
                    window.location = "https://littlebookcompany.net/jazzcash?pp_TxnRefNo=" + pp_TxnRefNo + '&pp_Amount=' + pp_Amount + '&pp_TxnDateTime=' + pp_TxnDateTime + '&pp_TxnExpiryDateTime=' + pp_TxnExpiryDateTime;
                })
                .catch((err) => {
                    console.log(err)

                })

        }
        if (data == "HBL") {
            const dataCart = this.props.cart
            console.log("DATA BEFORE HBL", dataCart)
            localStorage.setItem('Book_ID', JSON.stringify(dataCart));
            var pp_TxnRefNo = Math.floor(Math.random() * 900000) + 100000
            localStorage.setItem('Order_ID', JSON.stringify(pp_TxnRefNo));
            var pp_TxnDateTime = moment(new Date()).format('YYYYMMDDHHmmss');
            var pp_TxnExpiryDateTime = moment().add(1, 'days').format('YYYYMMDDHHmmss');
            var pp_Amount = this.state.sum
            var data1 = {
                "Price": this.state.sum,
                "Order_ID": pp_TxnRefNo
            }
            this.props.postOrder({
                'Order_ID': pp_TxnRefNo,
                'Payment_Method': 'HBL',
                'Payment_Status': 'Pending',
                'Amount': this.state.sum,
                'books': this.props.cart
            })
                .then((res) => {
                    console.log(res)
                    // this.setState({ order: res.content, }, () => console.log(this.state.order))
                    // localStorage.setItem('Book_ID', JSON.stringify(this.props.cart));
                    window.location = "https://littlebookcompany.net/hbl?Order_ID=" + pp_TxnRefNo + "&Price=" + pp_Amount + "&Email=" + this.props.user?.Email + "&Name=" + this.props.user?.Full_Name;


                })
                .catch((err) => {
                    console.log(err)

                })

        }
        if (data == 'stripe') {
            console.log("DATA", data)
            this.props.history.push("/checkoutstripe", { book: this.state.book, sum: this.state.sum, cart: this.props.cart })
        }
        return
        // var data = {
        //     'amount': '1',
        //     'storeId':'73507',
        //     'orderRefNum':'500',
        //     'expiryDate':'20210320 174000',
        //     'postBackURL':'http://localhost',
        //     'autoRedirect':"0",
        //     'mobileNum':'03313844515'
        // }
        // this.props.getEasyPay({
        //     'amount': '1',
        //     'storeId':'73507',
        //     'orderRefNum':'500',
        //     'expiryDate':'20210320 174000',
        //     'postBackURL':'http://localhost',
        //     'autoRedirect':"0",
        //     'mobileNum':'03313844515'
        // }).then((res) => {
        //     console.log(res)
        //     return
        //     // if (res.status == true) {
        //     //     this.setState({
        //     //         newBookList: res.content,
        //     //     })

        //     // }
        //     // else {
        //     //     alert(res)
        //     // }
        // }).catch((err) => {
        //     console.log(err)

        // })
    }

    generateOrderID = () => {
        let orderID = 'xxx-xxx-xxx-xxx'.replace(/[x]/g, (c) => {
            let dataTimeNow = new Date().getTime()
            var r = (dataTimeNow + Math.random() * 16) % 16 | 0;
            dataTimeNow = Math.floor(dataTimeNow / 16)
            return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16)
        })

        return orderID;
    }

    onClickPaymentInPakistan = () => {
        let isCartEmpty = this.props.cart.length == 0 ? true : false;


        if (isCartEmpty == true) {
            Swal.fire({
                customClass: 'sweet-alert',
                title: `<h1>Your cart is empty</h1>`,
                html: `<p>Please add book on your cart first and the click to Process to Checkout!</p>`,
                // html : true,
                iconHtml: `<img src=${cartEmptyIcon} width='70px' />`,
                // position: 'top-end',
                showCloseButton: true,
                confirmButtonText: "Explore Books",
                buttonsStyling: false,
                customClass: {
                    container: 'container-class',
                    htmlContainer: 'my-htmlText',
                    html: 'my-htmlText',
                    popup: 'popup-class',
                    icon: 'no-border',
                    title: 'my-title',
                    confirmButton: 'my-confirm-button-class',
                    closeButton: 'your-close-button-class',
                }
            })
                .then(() => {
                    this.props.history.push("/category")
                })
        }
        else if (isCartEmpty == false) {
            this.props.history.push("/checkoutstripe", { book: this.state.book, sum: this.state.sum, cart: this.props.cart })
        }
    }

    addbookInLib = (Order_ID) => {
        console.log('Cart', this.props.cart)
        this.props.putBookInLibrary({
            'books': this.props.cart,
        }).then((res) => {
            console.log("HERE putBookInLibrary then")
            console.log(res)
            this.props.history.push("/orderstatus", { Order_ID: Order_ID })

        })
            .catch((err) => {
                console.log("HERE putBookInLibrary err")
                console.log(err)

            })
    }

    removeFromCart = (book) => {
        this.props.removeFromCart(book)
        if (this.state.isPakistan) {
            sum = sum - book.Price
            this.setState({ sum: sum })
        } else {
            sum = sum - book.Price_USD
            this.setState({ sum: sum })
        }
    }

    IconOption = () =>
    (
        <Option >
            <div>
                <img src={PaypalLogo} />
                {'Paypal'}
            </div>
        </Option>
    );

    render() {
        console.log("CART :", this.props.cart)
        // const { t, i18n } = this.props
        const { t, i18n, location, user, history, cart } = this.props
        const { isLoading, book, isPaypal, isPakistan, sum, isLoginModal, Order_ID } = this.state;
        if (isLoginModal) {
            return (
                <Header
                    history={history}
                    isLoginModal={isLoginModal}
                ></Header>
            )
        }
        if (isPaypal) {

            return (
                <PayWithPayPal
                    total={sum}
                    //total={1}
                    updateOrder={this.props.updateOrder}
                    Order_ID={Order_ID}
                    putBookInLibrary={this.props.putBookInLibrary}
                    cart={cart}
                    user={user}
                    history={this.props.history}
                    orderConfirmationMailToUser={this.props.orderConfirmationMailToUser}
                    orderConfirmationMailToAuthor={this.props.orderConfirmationMailToAuthor}

                >

                </PayWithPayPal>
            )
        }
        return (
            <div class="dashboard-cotainer" >

                {/* header */}

                {user.Full_Name && <UserHeader
                    history={history}
                > </UserHeader>}
                {!user.Full_Name &&
                    <Header
                        history={history}
                    ></Header>
                }
                {/* checkout */}
                <div className="  ">
                    {/* <div className="pl-3 pr-3  ml-4 mr-4 ">
                        <p className="poppins_bold checkout_heading mt-5 mb-0">Checkout Cart</p><br></br>
                        <span>Your favourite book on your back! Few step away</span>
                    </div> */}
                    <div className="col-xl-12">
                        <div className='row'>
                            <div className='col-1 back__btnHolder'>
                                <img src={backBtn} width='40%' className='' />
                            </div>
                            <div className='col-8 p-0'>
                                <div className="pl-3 pr-3  ml-4 mr-4 ">
                                    <p className="poppins_bold checkout_heading primary-color mt-5 mb-0">Checkout Cart</p>
                                    <hr className='divider__paragraph' />
                                    <p className="poppins_light ">Your favourite book on your back! Few steps away</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 visible pl-5 pr-5">
                        <div className="row">
                            <div className="col-md-8 p-1">
                                <div className="table-responsive mt-4 checkout_container">
                                    <table className="table table-hover thead-primary tableWidth">
                                        <thead>
                                            <tr>
                                                <th scope="col table_header poppins_medium">Book Title  </th>
                                                <th scope="col table_header poppins_medium">Book Details </th>
                                                <th scope="col table_header poppins_medium">Price </th>
                                                <th scope="col table_header poppins_medium">Action </th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                cart.map((item, index) =>

                                                    <tr>
                                                        <td><img src={item.Image} style={{ width: '90px' }}></img></td>
                                                        <td><p className="primary-color">{item.Name}</p>
                                                            <p>{item.Author_Name}</p>
                                                            <p>{item.publication_category?.Category_Name}</p>

                                                        </td>
                                                        <td>{isPakistan ? item.Price == 0 ? "0 RS" : item.Price + " .00 RS" : item.Price_USD == 0 ? "0 USD" : item.Price_USD + " .00 USD"}</td>
                                                        <td className="Remove-text" onClick={() => this.removeFromCart(item)}><img className='mr-2' src={deleteIcon} width='10px' />Remove</td>
                                                    </tr>
                                                )
                                            }
                                            {

                                                cart.length <= 0 && <tr>
                                                    <td colSpan={4} className="text-center">No Item In Cart</td>

                                                </tr>
                                            }


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="proceedContainer">
                                    <div className="internalproceedContainer">
                                        <div className="col-12 mb-4 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_semibold internalproceedContainerHeading">PRODUCT</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="poppins_semibold internalproceedContainerHeading">SUBTOTAL</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="internalproceedLine"></div>
                                        <div className="col-12 ">
                                            {
                                                cart.map((item, index) =>
                                                    <div className="row mb-4 mt-3">
                                                        <div className="col-6">
                                                            <p className="poppins_semibold mb-0 internalproceedContainerText cut-text">{item.Name}</p>
                                                            <p className="poppins_regular mb-0 internalproceedContainerText cut-text">{item.Author_Name}</p>
                                                            <p className="poppins_regular internalproceedContainerText">{item.publication_category?.Category_Name}</p>


                                                        </div>
                                                        <div className="col-6 vertical_center">
                                                            <p className="poppins_semibold internalproceedContainerText">{isPakistan ? item.Price == 0 ? "0 RS" : item.Price + " .00 RS" : item.Price_USD == 0 ? "0 USD" : item.Price_USD + " .00 USD"}</p>
                                                        </div>
                                                        <div className="internalproceedLine"></div>

                                                    </div>
                                                )
                                            }
                                            {

                                                cart.length <= 0 &&
                                                <div className="text-center "> <p className="internalproceedContainerText poppins_semibold mt-5 mb-5">No Item in Cart</p>
                                                    <div className="internalproceedLine"></div>

                                                </div>
                                            }



                                        </div>
                                        <div className="col-12 mb-4 mt-3">
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="poppins_bold internalproceedContainerText1">Total</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="poppins_bold primary-color internalproceedContainerText1">{isPakistan ? book.Price == 0 ? "Our Gift" : sum + " .00 RS" : book.Price_USD == 0 ? "Our Gift" : sum + " .00 USD"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !isPakistan &&
                                        <p className="internalproceedContainerText1 poppins_bold ml-3 mb-5 mt-5">Payment Options</p>
                                    }
                                    <div className="payment_method">
                                        {/* {isPakistan &&
                                            <div className="col-12">
                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="Easy_paisa" name="option" onChange={this.onChange} value="Easy_paisa" />
                                                    <label className="poppins_regular" for="Easy_paisa" ></label>
                                                    <img className="Payment-methodImg" src={EasyPaisaLogo} />

                                                </p>
                                            </div>
                                        } */}
                                        {!isPakistan &&
                                            <div className="col-12">

                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="Paypal" name="option" onChange={this.onChange} value="Paypal" checked />
                                                    <label className="poppins_regular" for="Paypal"></label>
                                                    <img className="Payment-methodImg" src={PaypalLogo} />

                                                </p>

                                            </div>
                                        }
                                        {/* {isPakistan &&
                                            <div className="col-12">

                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="HBL" name="option" onChange={this.onChange} value="HBL" />
                                                    <label className="poppins_regular" for="HBL"></label>
                                                    <img className="Payment-methodImg" src={HBLLogo} />

                                                </p>
                                            </div>
                                        } */}
                                        {!isPakistan &&
                                            <div className="col-12">

                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="stripe" name="option" onChange={this.onChange} value="stripe" />
                                                    <label className="poppins_regular" for="stripe"></label>
                                                    <img className="Payment-methodImg" src={StripeLogo} />

                                                </p>
                                            </div>
                                        }

                                        {/* <div className="col-12">

                                                <p className="payment_methodImgHeight">
                                                    <input type="radio" id="MasterCard" name="option" onChange={this.onChange} value="MasterCard" />
                                                    <label className="poppins_regular" for="MasterCard"></label>
                                                    <img className="Payment-methodImg" src={JazzCashLogo} />

                                                </p>
                                            </div> */}

                                    </div>
                                    {
                                        !isPakistan &&
                                        <button className="paymentCheckoutBtn mt-5 poppins_regular col-12 mb-4" onClick={() => this.onClickPayment(this.state.option)}>PROCEED CHECK OUT</button>
                                    }
                                    {
                                        isPakistan &&
                                        <button className='paymentCheckoutBtn mt-5 poppins_regular col-12 mb-4' onClick={() => this.onClickPaymentInPakistan()}>CHECKOUT</button>
                                    }
                                </div>


                            </div>


                        </div>
                    </div>


                </div>


                {/* Payment detail */}
                <div className="  ">
                    <div className="col-xl-12  pb-5 mb-5">
                        <div className=" row ">


                        </div>
                    </div>


                </div>

                <Footer></Footer>
            </div>
        )
    }
}
const mapStatetoProps = ({ auth, cart }) => ({
    user: auth.user,
    cart: cart.cart
})
const mapDispatchToProps = ({
    getEasyPay,
    postOrder,
    updateOrder,
    putBookInLibrary,
    getCountryFromIp,
    removeFromCart,
    orderConfirmationMailToUser,
    orderConfirmationMailToAuthor,
})
Checkoutnew.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Checkoutnew);

