import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { CircleLoader } from 'react-spinners'
import Login from './auth/Login'
import Registration from './auth/Registration';
import cross from '../assets/images/x.svg'
import logo from '../assets/images/header/logo.svg'
import hamburger from '../assets/images/hamburger.svg'
import youtube from '../assets/images/socialmedia/youtube.svg'
import facebook from '../assets/images/socialmedia/facebook.svg'
import linkedin from '../assets/images/socialmedia/linkedin.svg'
import instagram from '../assets/images/socialmedia/instagram.svg'
import twitter from '../assets/images/socialmedia/twitter.svg'
import filter from '../assets/images/filter.svg'
import searchicon from '../assets/images/header/search_icon.png'
import filterIcon from '../assets/images/header/filter.png'
import locationimg from '../assets/images/header/location.png'

import starclr from '../assets/images/starclr.png'
import stars from '../assets/images/stars.png'
import close1 from '../assets/images/close (1).png'
import shoppingCart from '../assets/images/header/cart.png'

import { sendOtpMbl, registerReader, getAllCountries } from '../store/actions/authAction'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

import dropdown from '../assets/images/dropdown.svg'
import { Link, withRouter } from 'react-router-dom';
import { searchBook, getAllBooks } from '../store/actions/bookAction'

import { getCountryFromIp } from '../store/actions/bookAction'
import { loginUser } from '../store/actions/authAction'
import PhoneInput from 'react-phone-number-input'
import OtpInput from 'react-otp-input';
import readingbook from '../assets/images/footer/readingbook.png'


var cx = require('classnames');
const tabs = [
   { key: 'Books', loc: '/' },
   { key: 'Stories Work Collective', loc: '/storyworkcollection' },
   { key: 'Audio Books', loc: '/unavailable' },
   { key: 'Print on Demand', loc: '/printondemand' },
   { key: 'Services', loc: '/publisher' },
   { key: 'About Us', loc: '/aboutus' },
]



class Header extends Component {
   constructor(props) {
      super(props);
      this.state = {
         serverError: {},
         isLoading: false,
         showModal: false,
         searchName: "",
         adBar: true,
         Country: "",
         LoginModal: false,
         RegisterModal: false,

         errors: {},
         email: "",
         password: "",
         errors: {},
         serverError: {},
         email: "",
         password: "",
         password2: "",
         full_name: "",
         date_of_birth: "",
         phone_num: "",
         otp: "",
         countryList: [],
         country: "",
         verifiactionSend: false,
         isEmaiSend: false,
         value: "",
         otpp: "",
         isCodeSending: false,
         book_links: []

      };
      this.handleChange = this.handleChange.bind(this);

   }
   componentDidMount() {
      if (this.props?.isLoginModal) {
         if (!this.props.user?.User_ID) {
            this.toggleLoginModal()
         }

      }

      this.props.getAllBooks().then((res) => {
         this.setState((state) => {
            return { ...state, book_links: res.content }
         })
      }).catch((error) => {
         console.log(error)
      })



   }
   toggleLoginModal = () => {
      this.setState({
         LoginModal: !this.state.LoginModal
      })
   }
   componentWillMount() {
      this.props.getCountryFromIp().then((res) => {
         // console.log(res)
         this.setState({
            isPakistan: true,
            Country: res.country,
         })
      }).catch((err) => {
         console.log(err)

      })
   }


   closeLoginModal = () => {
      console.log("CLICKED")
      this.setState({ LoginModal: false }, () => {
         this.props.history.push('/category');
      })
   }
   closeAdBar = () => {
      this.setState({
         adBar: !this.state.adBar
      })
   }

   onChange = (e) => {
      this.setState({ [e.target.name]: e.target.value })
   }
   toggleModal = () => {
      this.setState({
         showModal: !this.state.showModal
      })
   }
   togglecongratulationModal = () => {
      this.setState({ CongratulationModal: !this.state.CongratulationModal })
   }
   handlecongratulationClosemodal = (e) => {
      this.setState({ CongratulationModal: !this.state.CongratulationModal })

   }
   handleChange(event) {
      if (event.target.value == 1) {
         this.setState({ CongratulationModal: !this.state.CongratulationModal })
      }


   }
   onKeyDownHandler = e => {
      if (e.keyCode === 13) {
         this.onClickSearch();
      }
   };
   onClickSearch = () => {
      this.props.searchBook(this.state.searchName).then((res) => {
         console.log(res.content)
         if (res.status == true) {
            // this.setState({
            //    newBookList: res.content,
            // })
            if (window.location.pathname === '/category') {
               this.props?.onSearch({ books: res.content, SearchBy: this.state.searchName })
            }
            this.props.history.push('/category', { books: res.content, SearchBy: this.state.searchName })

         }
         else {
            alert(res)
         }
      }).catch((err) => {
         console.log(err)

      })

   }
   handleClose = () => {
      this.setState({ showModal: false })
   }

   onForgetPass = () => {
      this.props.history.push('/forgetpassword')
   }

   onSubmit = (e) => {
      e.preventDefault()
      this.setState({ isLoading: true })
      this.props.loginUser({
         "email": this.state.email,
         "password": this.state.password
      }).then((res) => {
         this.setState({ isLoading: false })
         if (res.status) {
            this.props.history.push('/library')
         } else {
            this.setState({ serverError: [{ "msg": "an error occured" }] })
         }
      }).catch((err) => {
         this.setState({ isLoading: false })
         console.log(err)
         var validationError = {}
         var serverError = []
         if (err.hasOwnProperty('validation')) {
            err.validation.map(obj => {
               if (obj.hasOwnProperty('param')) {
                  validationError[obj["param"]] = obj["msg"]
               } else {
                  serverError = [...serverError, obj]
               }
            });
            this.setState({ errors: validationError });
            this.setState({ serverError: serverError });
         } else {
            this.setState({ serverError: [{ "msg": "server not responding" }] })
         }
      })
   }
   renderServerError() {
      if (this.state.serverError != null && this.state.serverError.length > 0) {
         return (
            <div className=" servererror1 form-group alert alert-danger" role="alert" >
               <strong className="pr-2 ohsnaptext">  {"  "}</strong>
               <label className="servererrorText">
                  {this.state.serverError[0].msg}
               </label>
            </div>
         )
      }
   }
   onSendCode = (e) => {
      e.preventDefault()
      this.setState({ isCodeSending: true })
      this.props.sendOtpMbl({
         // "To_Number": this.state.phone_num,
         "Email": this.state.email,
         "Full_Name": this.state.full_name,
         "Date_Of_Birth": "14-10-1996",
         "Password": this.state.password,
         "Password2": this.state.password2,
         "Country": this.state.country,
         "To_Number": this.state.phone_num,



      }).then((res) => {
         this.setState({ isLoading: false, verifiactionSend: true, isEmaiSend: true, isCodeSending: false })
         console.log(res)
         if (res.status) {
            // this.props.history.push('/category')
            return
         } else {
            return
         }
         // this.setState({ isLoading: false, isCodeSending:false })

      }).catch((err) => {
         this.setState({ isLoading: false, isCodeSending: false })
         console.log(err)
         var validationError = {}
         var serverError = []
         if (err.hasOwnProperty('validation')) {
            err.validation.map(obj => {
               if (obj.hasOwnProperty('param')) {
                  validationError[obj["param"]] = obj["msg"]
               } else {
                  serverError = [...serverError, obj]
               }
            });
            this.setState({ errors: validationError });
            this.setState({ serverError: serverError });
         } else {
            this.setState({ serverError: [{ "msg": "server not responding" }] })
         }
      })
   }


   onRegister = (e) => {
      e.preventDefault()
      this.props.registerReader({
         "Email": this.state.email,
         "Full_Name": this.state.full_name,
         "Date_Of_Birth": "14-10-1996",
         "Password": this.state.password,
         "Password2": this.state.password2,
         "Otp": this.state.otpp,
         "Country": this.state.country,
         "Phone_Num": this.state.phone_num,

      }).then((res) => {
         this.setState({ isLoading: false })
         console.log('RESPONSE IN SUCCESS REGISTER', res)
         if (res.status) {
            console.log('THEN')
            this.props.history.push('/library')
            console.log('THEN_2')
            // new Noty({
            //    text: "Succsessfully Register",

            //    type: "success",

            //    timeout: 2000
            // }).show()
            //    .then(() => { }
            //    )
         } else {
            // new Noty({
            //    text: "Something went wrong",

            //    type: "error",
            //    timeout: 2000
            // }).show();
            alert('WWWWW')
         }
      }).catch((err) => {
         this.setState({ isLoading: false })
         console.log('RESPONSE IN ERROR', err)
         var validationError = {}
         var serverError = []
         if (err.hasOwnProperty('validation')) {
            err.validation.map(obj => {
               if (obj.hasOwnProperty('param')) {
                  validationError[obj["param"]] = obj["msg"]
               } else {
                  serverError = [...serverError, obj]
               }
            });
            this.setState({ errors: validationError });
            this.setState({ serverError: serverError });
         } else {
            this.setState({ serverError: [{ "msg": "server not responding" }] })
         }
      })
   }

   _handleKeyDownCountry = (e) => {
      console.log("here country", [e.target.value])
      if (e.target.value == 0) {
         return
      }

   }


   selectCountry(val) {
      this.setState({ country: val });
   }

   setMblNum = (e) => {
      this.setState({ phone_num: e });

   }

   setOtpp = (e) => {
      console.log(e)
      this.setState({ otpp: e });

   }

   toogleRegisterModal = () => {
      this.setState({
         RegisterModal: true,
         LoginModal: false,
      })
   }


   render() {
      const { isLoading, errors } = this.state;

      return (
         <div class="dashboard-cotainer">
            {
               this.state.LoginModal && <Login
                  isLoading={this.state.isLoading}
                  show={this.state.LoginModal}
                  onClose={this.handleClose}
                  closeLoginModal={this.closeLoginModal}
                  renderError={this.state.serverError}
                  errors={errors}
                  onChange={this.onChange}
                  handleLogin={this.onSubmit}
                  toogleRegisterModal={this.toogleRegisterModal}
               />
            }
            <div className='ResgisterSideCart'>
               <Modal
                  onHide={this.handleClose}
                  dialogClassName="col-xl-12  ResgisterSideCart   "
                  show={this.state.RegisterModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  animation={false}
               >
                  <div className="  modal-body p-0">
                     <div className='col-md-12 LoginModal RegisterModal'>
                        <div className='text-center'>
                           {this.renderServerError()}

                        </div>
                        <div className='row'>
                           <div className='col-md-12 text-right'>
                              <button className='arrowBtn ' onClick={() => this.setState({ RegisterModal: false })}>X</button>

                           </div>
                           <div className='col-md-12 '>
                              <p className='poppins_medium heading'>Create Account</p>
                              <p className='poppins_regular Text'>Lets start reading books.</p>
                           </div>
                           <div className='col-md-6'>
                              <input
                                 className='Login-input'
                                 placeholder='Full Name'
                                 name="full_name"
                                 onChange={this.onChange}
                              />
                              {errors.Full_Name && <div className="invaliderror">{errors.Full_Name}</div>}

                              <input
                                 className='Login-input'
                                 placeholder='Email'
                                 name="email"
                                 onChange={this.onChange}
                              />
                              {errors.Email && <div className="invaliderror">{errors.Email}</div>}


                              <input
                                 className='Login-input'
                                 placeholder='Password'
                                 type='password'
                                 name="password"
                                 onChange={this.onChange}
                              />
                              {errors.Password && <div className=" invaliderror">{errors.Password}</div>}

                              <input
                                 className='Login-input'
                                 placeholder='Re Password'
                                 type='password'
                                 name="password2"
                                 onChange={this.onChange}
                              />
                              {errors.Password2 && <div className=" invaliderror">{errors.Password2}</div>}

                           </div>
                           <div className='col-md-6'>
                              <CountryDropdown
                                 // disabled={this.state.full_name ? false : true}
                                 classes='Login-input'
                                 value={this.state.country}
                                 onChange={(val) => this.selectCountry(val)} />
                              {errors.Country && <div className=" invaliderror">{errors.Country}</div>}

                              <div className='col-md-12 p-0'>
                                 <button className="verifybtn" onClick={(e) => this.onSendCode(e)}> {this.state.isCodeSending && <i class="fa fa-refresh fa-spin"></i>} Get SMS Code</button>

                                 <PhoneInput
                                    international
                                    placeholder="Phone no"
                                    defaultCountry="PK"
                                    className="Login-input "
                                    value={this.state.phone_num}
                                    onChange={e => this.setMblNum(e)} />

                                 {this.state.isEmaiSend && <p className="emailsenttext">Check email and/or SMS for code. </p>}

                              </div>
                              <div className='col-md-12 p-0'>

                                 <OtpInput
                                    value={this.state.otpp}
                                    onChange={e => this.setOtpp(e)}
                                    numInputs={4}
                                    inputStyle="Login-input otpp"
                                    containerStyle={false}
                                    separator={<span></span>}
                                 />
                              </div>
                              <div className='col-md-12 p-0'>

                                 <div className="checkboxContainer ">
                                    <input className='checkBox' type="checkbox" id="terms" />
                                    <label className='poppins_light checkBoxLabel ' for="terms"> I agree to the <label className='poppins_medium primary-color'>Terms of Services</label></label><br></br>
                                 </div>
                              </div>

                           </div>
                           <div className='col-md-6'></div>
                           <div className='col-md-6 text-center'>
                              {
                                 this.state.otpp === "" && <button
                                    className="LoginBtn poppins_bold  " disabled="true" onClick={this.onRegister}> <img className="ml-3 mr-3" src={readingbook} />Start Reading</button>}
                              {
                                 this.state.otpp !== "" && <button
                                    className="LoginBtn poppins_bold"
                                    onClick={this.onRegister}
                                 >
                                    <img className="ml-3 mr-3" src={readingbook} />Start Reading
                                 </button>
                              }
                              <p className='poppins_light ForgetText'>I already have an account?
                                 <label className='poppins_regular ForgetTextPrimary' onClick={() => this.setState({ LoginModal: true, RegisterModal: false })}>  Login Now!</label>
                              </p>
                           </div>

                        </div>
                     </div>
                  </div>


               </Modal>

            </div>

            {/* header */}
            <div className="beforecolpasemain">
               <div className="col-xl-12  col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                  <div className="row m-0">
                     <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12  pl-5 pr-5  text-center">
                        <Link to="/">
                           <img className="logo_img" src={logo}></img>
                        </Link>
                     </div>
                     <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 vertical_center text-center ">
                        <input className="col-xl-12  dashboard_header_input poppins_light" placeholder="Search best book 2022" name="searchName" onChange={this.onChange} onKeyDown={this.onKeyDownHandler}></input>
                        <img className="searchicon" src={searchicon} onClick={() => this.onClickSearch()}></img>
                        <div className='header_input_verticalLine'></div>
                        <img className='filterIcon' src={filterIcon} />
                     </div>
                     <div className='col-xl-2 col-lg-2 col-md-2 col-12 col-12 vertical_center country_div'>
                        <div className='row'>
                           <div className='col-9 my-auto'>
                              <p className='mb-0 countryName'><img className='mr-3' src={locationimg} />{this.state.Country}</p>

                           </div>
                           <div className='col-3 my-auto'>
                              <div className="vertical_center ">
                                 <img className="shoppingCartImg" onClick={() => this.props.history.push('/checkout')} src={shoppingCart} />
                                 <p className="shoppingCartImgCount">{this.props.cartCount}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3  col-lg-3 col-md-3 col-sm-12 col-12  vertical_center text-center login-drop">
                        <button className="headerLoginBtn" onClick={() => this.setState({ LoginModal: true })}>Login</button>
                        <button className="headerRegisterBtn" onClick={() => this.setState({ RegisterModal: true })}>Register</button>
                     </div>
                     <img
                        class="navbar-toggle tooglebtnn mr-5"
                        data-toggle="collapse"
                        data-target="#myNavbar" src={hamburger} />
                  </div>
               </div>
               {/* tabs */}
               <div className="HeaderTabsContain" id='myNavbar'>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mb-3">
                     <div className='dashboard_header_tabs'>
                        <div className="dashboard_header_tabs ">
                           <div className="dashboard_link">
                              <div className="row">
                                 {
                                    tabs.map(el => {
                                       return <div className="header_tabssNEw text-center">
                                          <Link to={el.loc}>
                                             <button class={cx({ "nav-poppins_light header_tabss_btn ": true, "header_tabss_btn_active": location.pathname === el.loc })}>{el.key}</button>
                                          </Link>
                                       </div>
                                    })
                                 }
                                 <div className="responsive-login-Register header_tabss_small text-center">
                                    <button className="headerLoginBtn" onClick={() => this.setState({ LoginModal: true })}>Login</button>
                                 </div>
                                 <div className="responsive-login-Register header_tabss_small text-center">
                                    <button className="headerRegisterBtn" onClick={() => this.setState({ RegisterModal: true })}>Register</button>
                                 </div>
                                 <div className="responsive nav_hr"></div>
                                 <div className="responsive text-center mt-3 pt-3 social-logos">

                                    <img className="pl-3 pr-3" src={youtube}></img>
                                    <img className="pl-3 pr-3" src={instagram}></img>
                                    <img className="pl-3 pr-3" src={twitter}></img>
                                    <img className="pl-3 pr-3" src={linkedin}></img>
                                    <img className="pl-3 pr-3" src={facebook}></img> <br></br>
                                    <label className="poppins_regular mt-2  navbarcopyright">2020 The Little Book Company  All rights reserved.</label>
                                 </div>
                                 <div className="header_tabss_small text-center">
                                    {
                                       this.state.book_links?.map((item) => {
                                          return <Link to={'/' + item.Name.replace(/[ &\/\\#,+()$~%.'":*?<>{}’]/g, '')}>
                                          </Link>
                                       })
                                    }
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div >
      )
   }

}
const mapStatetoProps = ({ auth, cart }) => ({
   user: auth.user,
   cartCount: cart.cart.length

})
const mapDispatchToProps = ({
   searchBook,
   getCountryFromIp,
   loginUser,
   sendOtpMbl,
   registerReader,
   getAllCountries,
   getAllBooks

})
Header.propTypes = {
};
export default connect(mapStatetoProps, mapDispatchToProps)(Header);

