import React from 'react';

import rightMostImage from '../../assets/images/Error_maintanence/rightMost.png';
import LogoBook from '../../assets/images/logoBook.png';
import SocialMedia from '../../assets/images/Error_maintanence/socialMedia.png';

import '../../assets/styles/error.css';

const Maintanence = () => {
    return (
        <div className='error__main'>
            <div className='error__leftMost'>
                <div className='error__inner'>
                <div className='row'>
                    <div className='col-12 mt-5'>
                        <img src={LogoBook} />
                    </div>
                    <div className='col-12 mt-5'>
                        <div className='stayConnected'>
                            Stay connected
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mt-5 error__text'>
                        <h1>
                            Under
                            <br />
                            <span>Maintanence</span>
                        </h1>
                        <hr className='mt-3 error__divider' />
                        <p className='mt-5'><b>We have got something special for you!</b></p>
                        <p className='mt-5 errorpara__des'><b>
                            We have got something special for you! Unfortunately The Little Book Company is down for a bit of maintenance right now. But soon we’ll be  up and the sun will shine again.
                        </b></p>
                        <img className='mt-5' src={SocialMedia} />
                    </div>
                </div>
                </div>
            </div>
            <div className='error__rightMost'>
                <img src={rightMostImage} width='100%' height='100%' />
            </div>
        </div>
    )
}

export default Maintanence;