import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

import { useSwipeable } from "react-swipeable";
import { EpubView } from "..";
import defaultStyles from "./style";
import logo from '../../../../assets/images/Logo-01.svg'
import back_btn from '../../../../assets/images/back_btn.svg'
import Nav_Book from '../../../../assets/images/Nav_Book.svg'
import Dropdown_icon from '../../../../assets/images/dropdown_icon.svg'
import search from '../../../../assets/images/search.svg'
import font from '../../../../assets/images/font.svg'
import highlighter from '../../../../assets/images/highlighter.svg'
import translation from '../../../../assets/images/translation.svg'
import shared from '../../../../assets/images/shared.svg'
import bookmark from '../../../../assets/images/bookmark.svg'
import brightness from '../../../../assets/images/brightness.svg'
import pages from '../../../../assets/images/pages.svg'
import Audio from '../../../../assets/images/Audio.svg'
import printed from '../../../../assets/images/printed.svg'
import loupe from '../../../../assets/images/loupe.svg'
import exit from '../../../../assets/images/exit.svg'
import right from '../../../../assets/images/right.svg'
import left from '../../../../assets/images/left.svg'
import playbtn from '../../../../assets/images/play-button (2).png'
import WhiteLogo from '../../../../assets/images/WhiteLogo.png'
import hamburger from '../../../../assets/images/hamburger.svg'

import close1 from '../../../../assets/images/close (1).png'

import bookmark1 from '../../../../assets/images/bookmark (2).svg'
import ReactStars from "react-rating-stars-component";

import { postRatting } from '../../../../store/actions/bookMarkAction'


import { Link, withRouter } from 'react-router-dom';
var cx = require('classnames');










const Swipeable = ({ children, ...props }) => {
  const handlers = useSwipeable(props);
  return (<div {...handlers}>{children}</div>);
}

class TocItem extends PureComponent {
  setLocation = () => {
    this.props.setLocation(this.props.href);
    console.log(this.props);
    console.log('dddd')
  };

  render() {
    const { label, styles, href, subitems, index } = this.props;
    const newHref = href.replace(".xhtml", "");
    if (this.props.direction === 'rtl') {
      return (
        // <div className="nav_divBarWhite ">
        <div class={cx({ "nav_divBarWhite  ": true, "nav_divBarBlack": !this.props.isModeLight })} tabindex={index} onClick={this.setLocation}>

          {/* <label className=" chapter_headingWhite">{newHref}</label> */}
          <label class={cx({ "chapter_titleWhiteUrdu  ": true, "  chapter_titleBlackUrdu": !this.props.isModeLight })} onClick={this.setLocation} >
            {label} <br></br>

          </label> <br></br>
          {subitems.length !== 0 && (<label class={cx({ "chapter_headingWhite Poppins_light ": true, " Poppins_light chapter_headingBlack": !this.props.isModeLight })} >{subitems && subitems[0].label}</label>
          )}
          {!subitems.length === 0 && (<label class={cx({ "chapter_headingWhite Poppins_light ": true, " Poppins_light chapter_headingBlack": !this.props.isModeLight })} >{newHref}</label>
          )}
          <div className="nav_hrr"></div>

          {/* <button className="" onClick={this.setLocation} style={styles}>
          {label} <br></br>

        </button> */}



        </div>
      );
    } else if (this.props.direction === 'ltr') {
      return (
        // <div className="nav_divBarWhite ">
        <div class={cx({ "nav_divBarWhite  ": true, "nav_divBarBlack": !this.props.isModeLight })} tabindex={index} onClick={this.setLocation}>

          {/* <label className=" chapter_headingWhite">{newHref}</label> */}
          <label class={cx({ "chapter_titleWhite  ": true, "  chapter_titleBlack": !this.props.isModeLight })} onClick={this.setLocation} >
            {label} <br></br>

          </label> <br></br>
          {subitems.length !== 0 && (<label class={cx({ "chapter_headingWhite Poppins_light ": true, " Poppins_light chapter_headingBlack": !this.props.isModeLight })} >{subitems && subitems[0].label}</label>
          )}
          {!subitems.length === 0 && (<label class={cx({ "chapter_headingWhite Poppins_light ": true, " Poppins_light chapter_headingBlack": !this.props.isModeLight })} >{newHref}</label>
          )}
          <div className="nav_hrr"></div>

          {/* <button className="" onClick={this.setLocation} style={styles}>
              {label} <br></br>
    
            </button> */}



        </div>
      );
    }
  }
}

TocItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  setLocation: PropTypes.func,
  styles: PropTypes.object
};

class ReactReader extends PureComponent {
  constructor(props) {
    super(props);
    this.readerRef = React.createRef();
    this.state = {
      expandedToc: true,
      tocDrawer: true,
      toc: false,
      isSercehPress: false,
      isBookMarkPress: false,
      isTranlatePress: false,
      isPageScroll: false,
      highlighted: false,
      isModeLight: true,
      sideNav: false,
      showModal: false,
      RatingModal: false,
      rattingValue: 0,


      bookList: this.props.bookList
    };
  }

  toggleRattingmodal = () => {
    this.setState({
      RatingModal: !this.state.RatingModal
    })
  }

  toggleSideNav = () => {
    this.setState({
      sideNav: !this.state.sideNav
    })
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.largeText !== nextProps.largeText) {
      console.log(nextProps.largeText)
      this.toggleFont()
    }
  }
  toggleFont = () => {
    console.log(this.state.isFontPress)
    this.setState({
      isFontPress: !this.state.isFontPress
    });
  };
  toggleHighlight = () => {
    console.log(this.state.highlighted)
    this.setState({
      highlighted: !this.state.highlighted
    });
  };
  togglePageDisplay = () => {

    var temp = !this.state.isPageScroll
    this.setState({
      isPageScroll: temp
    });
  };
  togglecongratulationModal = () => {
    this.setState({ CongratulationModal: !this.state.CongratulationModal })
  }
  toggleMode = () => {

    this.setState({
      isModeLight: !this.state.isModeLight
    });
  };

  toggleSearch = () => {
    this.setState({
      isSercehPress: !this.state.isSercehPress
    });
  };
  toggleBookmark = () => {
    const { onBookMark, bookMark } = this.props
    console.log(bookMark)
    this.props.bookMark.map((item, i) => {


    })
    // this.setState({
    //   isBookMarkPress: !this.state.isBookMarkPress
    // });
    onBookMark()
  };

  toggleTranslate = () => {
    this.setState({
      isTranlatePress: !this.state.isTranlatePress
    });
  };

  toggleToc = () => {
    this.setState({
      expandedToc: !this.state.expandedToc
    });
  };

  next = () => {
    const node = this.readerRef.current;
    console.log(node)

    node.nextPage();
  };

  prev = () => {
    const node = this.readerRef.current;
    console.log(node)
    node.prevPage();
  };

  onTocChange = toc => {
    const { tocChanged } = this.props;
    this.setState(
      {
        toc: toc
      },
      () => tocChanged && tocChanged(toc)
    );
  };

  onBookChange = item => {
    const { updateBook } = this.props;
    this.setState(
      {
        toc: false
      },
      () => updateBook(item)
    );
    //updateBook(item)
  };

  onclickBack = () => {
    //console.log(this.props.location)

    // this.props.history.push('/aboutbook', { book: book })
    //this.props.history.push('/library')
    this.props.history.goBack();
  }


  renderToc() {
    const { toc, expandedToc, } = this.state;
    const { styles, book, user, bookList } = this.props;
    return (
      <div  >
        {this.props.direction === 'rtl' && (
          <div className="ebubviewcontainer">


            {/* <div className="renderNavUrdu " */}
            <div className={cx({ "renderNavUrdu renderNavDisplayUrdu  ": this.state.sideNav, "  renderNavUrdu renderNavNone": !this.state.sideNav })}

            //  style={styles.tocArea}
            >
              {/* <div className="" style={styles.toc}> */}
              <div class={cx({ "whitebackground  ": true, "  blackbackground": !this.state.isModeLight })} style={styles.toc}>

                <div className="nav_logo_div" >
                  <img className="back_btnUrdu" src={back_btn} onClick={() => this.onclickBack()}></img><br></br>
                  <Link to="/">

                    <img className="navlogo" src={this.state.isModeLight ? logo : WhiteLogo}></img><br></br>
                  </Link>

                  <div className="nav_book_div " data-toggle="collapse" data-target="#myNavbar">
                    <img className="nav_book_img" src={book.Image}></img>
                    <div className="vertical-center">
                      <p class={cx({ "poppins_regular nav_book_title cut-text1 clrBlack m-0  ": true, "  poppins_regular nav_book_title cut-text1 clrWhite m-0": !this.state.isModeLight })}>{book.Name}</p>
                      <p class={cx({ "poppins_light nav_book_author cut-text1  clrBlack m-0  ": true, "  poppins_light nav_book_author cut-text1 clrWhite ": !this.state.isModeLight })}>{"by " + book.Author_Name}</p>
                    </div>

                    {/* <div className="vertical-center">
                      <img className="dropdown_icon" src={Dropdown_icon} ></img>
                    </div> */}

                  </div>
                  <div class="collapse img-w-100Urdu pt-4 pb-4  " id="myNavbar">
                    <ul class="nav navbar-nav img-w-100Urdu mb-4" >
                      {bookList.map((item, i) =>
                        <>
                          {/* <li class=""> <img src={Nav_Book}></img> <label className=" epub_navbar_li_text " onClick={()=>this.props.updateBook(item)}>{item.Name}</label></li>
                        <div className="nav_hr"></div> */}
                          <li class="epub_listUrdu" onClick={() => this.onBookChange(item)}>
                            <img className="nav_book_imgUrdu" src={item.Image}></img>
                            <label class={cx({ "poppins_regular nav_book_titleUrdu cut-text1 clrBlack m-0  ": true, "  poppins_regular cut-text1 nav_book_titleUrdu clrWhite m-0": !this.state.isModeLight })} >{item.Name}</label>
                            <br></br>
                            <label class={cx({ "poppins_light epub_nav_book_author  cut-text1 clrBlack m-0  ": true, "  poppins_light cut-text1 epub_nav_book_author clrWhite ": !this.state.isModeLight })}>{item.Author_Name}</label>

                          </li>
                        </>
                      )}
                    </ul>
                  </div>

                </div>

                {toc.map((item, i) => (
                  <TocItem
                    isModeLight={this.state.isModeLight}
                    {...item}
                    key={i}
                    index={i}
                    setLocation={this.setLocation}
                    styles={styles.tocAreaButton}
                    direction={this.props.direction}
                  />
                ))}

              </div>
            </div>
          </div>

        )}
        {this.props.direction === 'ltr' && (
          <div className="ebubviewcontainer">
            <div className={cx({ "renderNav renderNavDisplay  ": this.state.sideNav, "  renderNav renderNavNone": !this.state.sideNav })}
            //  style={styles.tocArea}
            >
              {/* <div className="" style={styles.toc}> */}
              <div class={cx({ "whitebackground  ": true, "  blackbackground": !this.state.isModeLight })} style={styles.toc}>

                <div className="nav_logo_div" >


                  <img className="back_btn" src={back_btn} onClick={() => this.onclickBack()}></img><br></br>
                  <Link to="/">

                    <img className="navlogo" src={this.state.isModeLight ? logo : WhiteLogo}></img><br></br>
                  </Link>
                  <div className="nav_book_div d-ltr " data-toggle="collapse" data-target="#myNavbar1">
                    <img className="nav_book_img" src={book.Image}></img>
                    <div className="vertical-center">
                      <p class={cx({ "poppins_regular nav_book_title cut-text1 clrBlack m-0  ": true, "  poppins_regular cut-text1  nav_book_title clrWhite m-0": !this.state.isModeLight })}>{book.Name}</p>
                      <p class={cx({ "poppins_light nav_book_author cut-text1   clrBlack m-0  ": true, " cut-text1  poppins_light nav_book_author clrWhite ": !this.state.isModeLight })}>{"by " + book.Author_Name}</p>

                    </div>
                    {/* <div className="vertical-center">
                      <img className="dropdown_icon"  src={Dropdown_icon}></img>
                    </div> */}

                  </div>

                </div>


                <div class="collapse img-w-100 pt-4 pb-4  " id="myNavbar1">

                  <ul class="nav navbar-nav img-w-100 d-ltr mb-4">
                    {bookList.map((item, i) =>
                      <>
                        {/* <li class=""> <img src={Nav_Book}></img> <label className=" epub_navbar_li_text " onClick={()=>this.props.updateBook(item)}>{item.Name}</label></li>
                        <div className="nav_hr"></div> */}
                        <li class="epub_list" onClick={() => this.onBookChange(item)}>
                          <img className="nav_book_img" src={item.Image}></img>
                          <label class={cx({ "poppins_regular nav_book_title clrBlack m-0 cut-text1   ": true, " cut-text1   poppins_regular nav_book_title clrWhite m-0": !this.state.isModeLight })} >{item.Name}</label>
                          <br></br>
                          <label class={cx({ "poppins_light epub_nav_book_author  cut-text1  clrBlack m-0  ": true, " cut-text1  poppins_light epub_nav_book_author clrWhite ": !this.state.isModeLight })}>{item.Author_Name}</label>

                        </li>
                      </>
                    )}
                  </ul>
                </div>

                {toc.map((item, i) =>
                (
                  <TocItem
                    isModeLight={this.state.isModeLight}
                    {...item}
                    key={i}
                    index={i}
                    setLocation={this.setLocation}
                    styles={styles.tocAreaButton}
                    direction={this.props.direction}

                  />
                )
                )}

              </div>
            </div>
          </div>

        )}
        {expandedToc && (
          <>


          </>

        )}
        {/* <Link to="/library"> */}
        {/* <button className="exit-btn whitebackground poppins_light "> */}
        {this.props.direction === 'ltr' && (

          <button onClick={() => this.onclickBack()} class={cx({ "exit-btn whitebackground poppins_light  ": true, "  exit-btn blackbackground clrWhite poppins_light": !this.state.isModeLight })}>
            <img className="exit_icon" src={exit}></img>
            Exit Reader Mode
          </button>
        )}
        {this.props.direction === 'rtl' && (

          <button onClick={() => this.onclickBack()} class={cx({ "exit-btnUrdu whitebackground poppins_light  ": true, "  exit-btnUrdu blackbackground clrWhite poppins_light": !this.state.isModeLight })}>
            <img className="exit_icon" src={exit}></img>
            Exit Reader Mode
          </button>
        )}
        {/* </Link> */}

      </div>
    );
  }

  setLocation = loc => {
    const { locationChanged } = this.props;

    this.setState(
      {
        expandedToc: true
      },
      () => locationChanged && locationChanged(loc)
    );
  };


  renderTocToggle() {
    const { expandedToc } = this.state;
    const { styles, direction, book } = this.props;

    return (
      <>
        <div class="nav_background"

        >
          {direction === 'ltr' && (

            <img class="hamburgersidebar" src={hamburger} onClick={this.toggleSideNav}></img>

          )}
          {direction === 'rtl' && (

            <img class="hamburgersidebarUrdu" src={hamburger} onClick={this.toggleSideNav}></img>

          )}
          {/* <button
          style={Object.assign(
            {},
            styles.tocButton,
            expandedToc ? styles.tocButtonExpanded : {}
          )}
        // onClick={this.toggleToc}
        >
          <span
            style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBarTop)}
          />
          <span
            style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBottom)}
          />
        </button> */}
          {direction === 'rtl' && (

            <div className="cross_roundUrdu" onClick={() => this.onclickBack()} >
              <div className="poppins_semibold cross-text">X</div>
            </div>
          )}
          {direction === 'ltr' && (

            <div className="cross_round" onClick={() => this.onclickBack()}>
              <div className="poppins_semibold cross-text">X</div>
            </div>
          )}
          {direction === 'rtl' && (
            <div className="toolttipUrdu">
              <div className="text-center">
                {/* <div className="pt-0 pb-2 Icon_div" onClick={this.toggleSearch}>
                <img className="search_icon mt-3" src={search}></img><br></br>
                <p className="poppins_light toolttip_text mb-0">Search</p>
              </div>
            */}
                <div className="pt-0 pb-2 Icon_div" onClick={this.props.onToggleFontSize}>
                  <img className="search_icon" src={font}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Font Size</p>
                </div>

                {/* <div className="pt-0 pb-2 Icon_div" onClick={this.toggleHighlight}>
                <img className="search_icon" src={highlighter}></img><br></br>
                <p className="poppins_light toolttip_text mb-0">Highlight</p>
              </div> */}

                {/* <div className="pt-0 pb-2 Icon_div" onClick={this.toggleTranslate}>
                <img className="search_icon" src={translation} onClick={this.toggleTranslate}></img><br></br>
                <p className="poppins_light toolttip_text mb-0">Translate</p>
              </div> */}
                {/* <div className="pt-0 pb-2 Icon_div">
                  <img className="search_icon" src={shared}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Shear it</p>
                </div> */}
                <div className={cx({ "pt-0 pb-2 Icon_div": this.state.isBookMarkPress === false }, { "pt-0 pb-2 Icon_div_active": this.state.isBookMarkPress === true })} onClick={this.toggleBookmark}>
                  <img className="search_icon" src={bookmark}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0" onClick={this.toggleBookmark}>Bookmark</p>
                </div>
                <div className="pt-0 pb-2 Icon_div" className={cx({ "pt-0 pb-2 Icon_div": this.state.isModeLight === true }, { "pt-0 pb-2 Icon_div_active": this.state.isModeLight === false })} onClick={this.toggleMode}>
                  <img className="search_icon" src={brightness} onClick={this.toggleMode}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0" onClick={this.toggleMode}>Modes</p>
                </div>
                <div className="pt-0 pb-2 Icon_div" className={cx({ "pt-0 pb-2 Icon_div": this.state.isPageScroll === false }, { "pt-0 pb-2 Icon_div_active": this.state.isPageScroll === true })} onClick={this.togglePageDisplay}>
                  <img className="search_icon" src={pages}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Pages</p>
                </div>
                <div className="pt-0 pb-2  Icon_div" onClick={() => this.toggleRattingmodal()} >
                  {/* <img className="dissearch_icon" src={Audio}></img><br></br> */}
                  <p className="poppins_light distoolttip_text mb-0">Rating</p>
                </div>

                {/* <div className="pt-0 pb-2 Icon_div_disable " >
                  <img className="dissearch_icon" src={Audio}></img><br></br>
                  <p className="poppins_light distoolttip_text mb-0">Audio</p>
                </div>
                <div className="pt-0 pb-2 Icon_div_disable">
                  <img className="dissearch_icon" src={printed}></img><br></br>
                  <p className="poppins_light distoolttip_text mb-0">Printed</p>
                </div> */}
              </div>
            </div>
          )}
          {direction === 'ltr' && (
            <div className="toolttip">
              <div className="text-center">
                {/* <div className="pt-0 pb-2 Icon_div" onClick={this.toggleSearch}>
                <img className="search_icon mt-3" src={search}></img><br></br>
                <p className="poppins_light toolttip_text mb-0">Search</p>
              </div>
            */}
                <div className="pt-0 pb-2 Icon_div" onClick={this.props.onToggleFontSize}>
                  <img className="search_icon" src={font}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Font Size</p>
                </div>

                {/* <div className="pt-0 pb-2 Icon_div" onClick={this.toggleHighlight}>
                <img className="search_icon" src={highlighter}></img><br></br>
                <p className="poppins_light toolttip_text mb-0">Highlight</p>
              </div> */}

                {/* <div className="pt-0 pb-2 Icon_div" onClick={this.toggleTranslate}>
                <img className="search_icon" src={translation} onClick={this.toggleTranslate}></img><br></br>
                <p className="poppins_light toolttip_text mb-0">Translate</p>
              </div> */}
                {/* <div className="pt-0 pb-2 Icon_div">
                  <img className="search_icon" src={shared}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Shear it</p>
                </div> */}
                <div className={cx({ "pt-0 pb-2 Icon_div": this.state.isBookMarkPress === false }, { "pt-0 pb-2 Icon_div_active": this.state.isBookMarkPress === true })} onClick={this.toggleBookmark}>
                  <img className="search_icon" src={bookmark}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0" onClick={this.toggleBookmark}>Bookmark</p>
                </div>
                <div className={cx({ "pt-0 pb-2 Icon_div": this.state.isModeLight === true }, { "pt-0 pb-2 Icon_div_active": this.state.isModeLight === false })} onClick={this.toggleMode}>
                  <img className="search_icon" src={brightness} ></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Modes</p>
                </div>
                <div className={cx({ "pt-0 pb-2 Icon_div": this.state.isPageScroll === false }, { "pt-0 pb-2 Icon_div_active": this.state.isPageScroll === true })} onClick={this.togglePageDisplay}>
                  <img className="search_icon" src={pages}></img><br></br>
                  <p className="poppins_light toolttip_text mb-0">Pages</p>
                </div>
                <div className="pt-0 pb-2  Icon_div " onClick={() => this.toggleRattingmodal()}>
                  <img className="dissearch_icon" src={Audio}></img><br></br>
                  <p className="poppins_light distoolttip_text mb-0">Rating</p>
                </div>
                {/* <div className="pt-0 pb-2  Icon_div_disable">
                  <img className="dissearch_icon" src={Audio}></img><br></br>
                  <p className="poppins_light distoolttip_text mb-0">Audio</p>
                </div>
                <div className="pt-0 pb-2  Icon_div_disable">
                  <img className="dissearch_icon " src={printed}></img><br></br>
                  <p className="poppins_light distoolttip_text mb-0">Printed</p>
                </div> */}
              </div>
            </div>
          )}
          <div className="bottomDiv">
            <button className="left-btn"
              style={Object.assign({}, styles.prev)}
              onClick={this.prev}
            >
              <img src={left} ></img>
            </button>
            {
              this.props.direction === "rtl" ? (
                <label class={cx({ "ml-3 mr-3 poppins_light pervious_text  ": true, " clrWhite ml-3 mr-3 poppins_light pervious_text": !this.state.isModeLight })}>
                  Next Page
                </label>
              ) : (
                <label class={cx({ "ml-3 mr-3 poppins_light pervious_text  ": true, " clrWhite ml-3 mr-3 poppins_light pervious_text": !this.state.isModeLight })}>
                  Previous Page
                </label>
              )
            }


          </div >
          {/* <p className="signupPreview">This is the Preview Please 
          <Link to="/register">

          <label className="forget-form-text ml-2 primary-color"> Signup </label> 
          </Link>
          </p> */}
          {!this.props.user.Full_Name &&
            <div className="signupPreviewDiv">
              <p className="signupPreview mb-0">This is the Free Version of ebook! to get full access
                <Link to="/register">

                  <label className=" buysignup poppins_bold"> Buy Now! </label>
                </Link>
                or
                <Link to="/register">

                  <label className=" buysignup poppins_bold"> Sign up </label>
                </Link>
              </p>

            </div>

          }
          {!this.props.user.Full_Name &&
            <div className="signupPreviewDivMobile">
              <p className="signupPreview mb-0">
                <Link to="/register">

                  <label className=" buysignup poppins_bold"> Buy Now! </label>
                </Link>
                or
                <Link to="/register">

                  <label className=" buysignup poppins_bold"> Sign up </label>
                </Link>
              </p>

            </div>

          }
          <div className="bottomDivRight">


            {this.props.direction === "rtl" ? (<label class={cx({ "ml-3 mr-3 poppins_light pervious_text  ": true, " clrWhite ml-3 mr-3 poppins_light pervious_text": !this.state.isModeLight })}>Previous Page
            </label>) : (<label class={cx({ "ml-3 mr-3 poppins_light pervious_text  ": true, " clrWhite ml-3 mr-3 poppins_light pervious_text": !this.state.isModeLight })}>Next Page
            </label>)}
            <button className="left-btn"
              style={Object.assign({}, styles.prev)}
              onClick={
                this.next
              }

            >
              <img src={right} ></img>
            </button>

          </div >

          {this.state.isSercehPress && (
            <>
              <input className="searchbar poppins_light" placeholder="Search by author, title, name"></input>
              <img className="innerSearchIcon " src={loupe}></img>
            </>

          )}
          {this.state.isTranlatePress && (
            <div className="translateDiv pt-4 " >
              <div className="row " >
                <div className="col-md-3  vertical_center text-center" >
                  <p class="poppins_light translateSmallText "><i>Word</i></p>
                  <p class="poppins_medium translateMediumText ">Significance</p>
                  <p class="poppins_regular translateSmallText ">/sɪɡˈnɪfɪk(ə)ns/</p>

                </div>
                <div className="col-md-6   " >
                  <p class="poppins_light translateSmallText "><i>noun</i></p>
                  <p class="poppins_regular translateSmallText ">1.   the quality of being worthy of attention; importance.</p>
                  <p class="poppins_regular translateSmallText ml-3">"adolescent education was felt to be a social issue of some significance"</p>
                  <p class="poppins_regular translateSmallText mt-2 ml-3 ">Similar: meaning, sense, signification, important, trust etc.</p>

                </div>
                <div className="col-md-3   text-center " >
                  <img class="play" src={playbtn}></img><br></br>
                  <p class="mt-2 poppins_regular translateSmallText">Listen</p>
                </div>



              </div>


            </div>


          )}



          {this.state.isBookMarkPress && direction === 'ltr' && (


            <div class="bookmark-img">
              <img src={bookmark1}></img>
            </div>
          )}
          {this.state.isBookMarkPress && direction === 'rtl' && (


            <div class="bookmark-imgUrdu">
              <img src={bookmark1}></img>
            </div>
          )}

        </div>

      </>
    );
  }

  ratingChanged = (newRating) => {
    console.log(newRating);
    this.setState({
      rattingValue: newRating
    }, () => {
      this.props.createRatting(newRating)
    })
  };

  render() {
    const {
      title,
      showToc,
      loadingView,
      styles,
      locationChanged,
      swipeable,
      ...props
    } = this.props;
    const { toc, expandedToc, isPageScroll, highlighted, isModeLight } = this.state;

    return (
      <div style={styles.container}>
        <Modal


          dialogClassName="col-xl-12 "
          show={this.state.showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >


          <div className="  modal-body">



            <div className="container text-center">
              <img className="crossbtn_modal mr-4 mt-3 pointer" src={close1} ></img>
              <Link to="/">


                <img className="mb-3 mt-3 epubmodalimg" src={logo} />
              </Link>
              <p className="mt-3 mb-3 poppins_medium epubmodalheading" > Want to read more?</p>
              <p className="mt-3 mb-3 epubmodaltext poppins_regular "> purchase the full version of book now!</p>
              <button className="mt-3 mb-3 epubmodalbtn">Buy Now</button> <br></br>
              <button className=" mb-3 epubmodalbtn">Signup</button>





            </div>



          </div>


        </Modal>

        <Modal


          dialogClassName="col-xl-12 "
          show={this.state.RatingModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >


          <div className="  modal-body">



            <div className="container text-center">
              <img className="crossbtn_modal mr-4 mt-3 pointer" src={close1} onClick={() => this.toggleRattingmodal()}></img>
              <Link to="/">


                <img className="mb-3 mt-3 epubmodalimg" src={logo} />

              </Link>
              <div className="col-md-12 text-center">
                <div className="row">
                  <div className="col-md-5 text-center"></div>
                  <div className="col-md-4 text-center">
                    <ReactStars
                      count={5}
                      onChange={this.ratingChanged}
                      size={24}
                      value={this.state.rattingValue}
                      activeColor="#ffd700"
                    />,
                  </div>

                </div>


              </div>






            </div>



          </div>


        </Modal>

        {/* class={cx({ "nav-poppins_light header_tabss_btn ": true, "header_tabss_btn_active": location.pathname === "/" })} */}
        {/* <div className="renderDiv whitebackground " */}
        {this.props.direction === 'rtl' && (
          <div class={cx({ "renderDivUrdu whitebackground  ": true, "renderDivUrdu blackbackground": !this.state.isModeLight })}


            style={Object.assign(
              {},
              styles.readerArea,
              expandedToc ? styles.containerExpanded : {}
            )}
          >


            {/* {showToc && this.renderTocToggle()} */}
            {this.renderTocToggle()}

            {/* <img src={logo}></img> */}

            {/* <Swipeable
            onSwipedRight={this.prev}
            onSwipedLeft={this.next}
            trackMouse
          > */}
            <div className="ebubviewcontainer" style={styles.reader}>
              <EpubView
                ref={this.readerRef}
                loadingView={loadingView}
                {...props}
                tocChanged={this.onTocChange}
                locationChanged={locationChanged}
                isPageScroll={isPageScroll}
                highlighted={highlighted}
                isModeLight={isModeLight}

              />
              {swipeable && <div style={styles.swipeWrapper} />}
            </div>
            {/* </Swipeable> */}
            {/* <button
            style={Object.assign({}, styles.arrow, styles.prev)}
            onClick={this.prev}
          >
            ‹
          </button>
          <button
            style={Object.assign({}, styles.arrow, styles.next)}
            onClick={this.next}
          >
            ›
          </button>  */}

          </div>

        )}
        {this.props.direction === 'ltr' && (
          <div class={cx({ "renderDiv whitebackground  ": true, "renderDiv blackbackground": !this.state.isModeLight })}


            style={Object.assign(
              {},
              styles.readerArea,
              expandedToc ? styles.containerExpanded : {}
            )}
          >

            {/* {showToc && this.renderTocToggle()} */}
            {this.renderTocToggle()}

            {/* <img src={logo}></img> */}

            {/* <Swipeable
            onSwipedRight={this.prev}
            onSwipedLeft={this.next}
            trackMouse
          > */}
            <div className="ebubviewcontainer" style={styles.reader}>
              <EpubView
                ref={this.readerRef}
                loadingView={loadingView}
                {...props}
                tocChanged={this.onTocChange}
                locationChanged={locationChanged}
                isPageScroll={isPageScroll}
                highlighted={highlighted}
                isModeLight={isModeLight}

              />
              {swipeable && <div style={styles.swipeWrapper} />}
            </div>
            {/* </Swipeable> */}
            {/* <button
            style={Object.assign({}, styles.arrow, styles.prev)}
            onClick={this.prev}
          >
            ‹
          </button>
          <button
            style={Object.assign({}, styles.arrow, styles.next)}
            onClick={this.next}
          >
            ›
          </button>  */}

          </div>

        )}
        {showToc && toc && this.renderToc()}


      </div>
    );
  }
}

ReactReader.defaultProps = {
  loadingView:
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
      <p className="loaderP">Loading Please Wait</p>
    </div>

  ,
  locationChanged: null,
  tocChanged: null,
  showToc: true,
  styles: defaultStyles
};

ReactReader.propTypes = {
  title: PropTypes.string,
  loadingView: PropTypes.element,
  showToc: PropTypes.bool,
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  styles: PropTypes.object,
  swipeable: PropTypes.bool
};

const mapStatetoProps = ({ auth, cart }) => ({
  user: auth.user,
  cart: cart.cart
})
const mapDispatchToProps = ({
  postRatting
})

export default connect(mapStatetoProps, mapDispatchToProps)(ReactReader);


