import React, { Component } from 'react';
import { connect } from 'react-redux';
import bookstore_card from '../../assets/images/bookstore_card.svg'
import bookicon from '../../assets/images/undraw_Books_l33t.svg'
import Header from '../../component/Header'
import authorcard_img from '../../assets/images/authorcard_img.svg'
import romantic_novel from '../../assets/images/romantic-novel.svg'
import { loginUser } from '../../store/actions/authAction'
import Noty from 'noty';
import UserHeader from '../../component/UserHeader'
import { ClipLoader } from 'react-spinners'
import { Link, withRouter } from 'react-router-dom';
import Footer from '../../component/Footer'
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},

            serverError: {},
            isLoading: false,
            email: "",
            password: "",
        };
    }
    componentDidMount() {
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onForgetPass = () => {
        this.props.history.push('/forgetpassword')
    }

    onSubmit = (e) => {


        e.preventDefault()
        this.setState({ isLoading: true })
        this.props.loginUser({
            "email": this.state.email,
            "password": this.state.password
        }).then((res) => {
            this.setState({ isLoading: false })
            console.log(res)
            if (res.status) {
                new Noty({
                    text: "Succsessfully Login",

                    type: "success",

                    timeout: 1000
                }).show()
                this.props.history.push('/library')

            } else {
                new Noty({
                    text: "Something went wrong",

                    type: "error",
                    timeout: 1000
                }).show();
            }
        }).catch((err) => {
            this.setState({ isLoading: false })
            console.log(err)
            var validationError = {}
            var serverError = []
            if (err.hasOwnProperty('validation')) {
                err.validation.map(obj => {
                    if (obj.hasOwnProperty('param')) {
                        validationError[obj["param"]] = obj["msg"]
                    } else {
                        serverError = [...serverError, obj]
                    }
                });
                this.setState({ errors: validationError });
                this.setState({ serverError: serverError });
            } else {
                this.setState({ serverError: [{ "msg": "server not responding" }] })
            }
        })
    }
    renderServerError() {
        if (this.state.serverError != null && this.state.serverError.length > 0) {
            return (

                <div className=" servererror1 form-group alert alert-danger" role="alert" >
                    <strong className="pr-2 ohsnaptext">  {"  "}</strong>
                    <label className="servererrorText">
                        {this.state.serverError[0].msg}
                    </label>
                </div>
            )
        }
    }
    render() {
        // const { t, i18n } = this.props
        const { t, i18n } = this.props
        const { isLoading } = this.state;
        const { errors } = this.state


        return (
            <div class="dashboard-cotainer">
                {/* header */}
                <Header
                    history={this.props.history}
                ></Header>
                {/* about book */}
                <div className=" login-container  mt-5">

                    <div className="row">

                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12   mb-5">

                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12'>
                            <div className="logincard ">

                                <p className="poppins_bold sign_heading primary-color mb-0">Login</p>
                                <p className="poppins_extra_light sign_text primary-color mb-0">Join us now! and start reading your favorite book from today! </p>
                                <div className="sing-horizontal-row col-xl-8"></div>
                                {this.renderServerError()}



                                <p className="  sign-form-text poppins_regular mt-3" >Email</p>
                                <input className="sign-input col-xl-12" name="email" onChange={this.onChange}></input>
                                {errors.email && <div className="invaliderror1">{errors.email}</div>}

                                <p className="  sign-form-text poppins_regular mt-3">Password</p>
                                <input className="sign-input col-xl-12" type="password" name="password" onChange={this.onChange}></input>
                                {errors.password && <div className="invaliderror1">{errors.password}</div>}

                                <div className="text-right">
                                    <p className=" forget-form-text poppins_regular mt-4" onClick={() => this.onForgetPass()}>Forget Password?</p>
                                </div>
                                <div className="text-center mt-4 ">
                                    {
                                        isLoading && <button
                                            className="sign_verification_btn poppins_bold  col-xl-4 mt-4"
                                        >
                                            <ClipLoader color='white' />
                                        </button>
                                    }
                                    {
                                        !isLoading && <button className="sign_verification_btn poppins_bold  col-xl-4 mt-4" onClick={this.onSubmit}>Start Reading</button>
                                    }

                                    <p className=" sign-form-text poppins_regular mt-5">Don't have an account?
                                        <Link to="/register">
                                            <label className="forget-form-text ml-3 primary-color">Create Account</label>
                                        </Link>


                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12   mb-5">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12  mb-5 text-center">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        )
    }

}
const mapStatetoProps = ({ auth }) => ({
    user: auth.user
})
const mapDispatchToProps = ({
    loginUser,
})
Login.propTypes = {

};
export default connect(mapStatetoProps, mapDispatchToProps)(Login);

